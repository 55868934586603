import React from "react";
import './globals.css';
import './style.css';
import './styleguide.css';
import {  Link } from "react-router-dom";
  
 const Creditnote=()=>{
  return(
    <div class="credit-note">
   
      <div class="main-container">
        <div class="top-nav">
          <div class="nav-container-wrapper">
            <div class="nav-container">
              <div class="text-wrapper">Credit Note</div>
              <div class="profile">
                <svg
                  class="iconly-bulk"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M16.4746 9.70445C15.8658 8.99358 15.5892 8.37755 15.5892 7.33097V6.97511C15.5892 5.61129 15.2753 4.73256 14.5928 3.85383C13.541 2.48917 11.7703 1.66667 10.0368 1.66667H9.9631C8.26609 1.66667 6.55085 2.4514 5.48079 3.76067C4.76107 4.65702 4.41073 5.57352 4.41073 6.97511V7.33097C4.41073 8.37755 4.15233 8.99358 3.52537 9.70445C3.06405 10.2282 2.91663 10.9013 2.91663 11.6298C2.91663 12.3591 3.15598 13.0498 3.63635 13.6113C4.26331 14.2844 5.14867 14.7141 6.05309 14.7888C7.3625 14.9382 8.67191 14.9944 10.0004 14.9944C11.328 14.9944 12.6374 14.9004 13.9477 14.7888C14.8512 14.7141 15.7366 14.2844 16.3636 13.6113C16.8431 13.0498 17.0833 12.3591 17.0833 11.6298C17.0833 10.9013 16.9359 10.2282 16.4746 9.70445Z"
                    fill="#DDDDDD"
                  />
                  <path
                    opacity="0.4"
                    d="M11.6738 16.0236C11.2572 15.9347 8.71876 15.9347 8.30217 16.0236C7.94603 16.1059 7.56091 16.2972 7.56091 16.7169C7.58162 17.1172 7.816 17.4706 8.14067 17.6946L8.13984 17.6955C8.55974 18.0228 9.05253 18.2309 9.56851 18.3056C9.84348 18.3434 10.1234 18.3417 10.4083 18.3056C10.9235 18.2309 11.4163 18.0228 11.8362 17.6955L11.8353 17.6946C12.16 17.4706 12.3944 17.1172 12.4151 16.7169C12.4151 16.2972 12.03 16.1059 11.6738 16.0236Z"
                    fill="#DDDDDD"
                  />
                </svg>
                <div class="profile-2"></div>
              </div>
            </div>
          </div>
          <div class="breadcrumbs">
            <div class="div-2">
              <a href="../dashboard/index.html">

                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path d="M6.09578 13.8547V11.8102C6.09577 11.2921 6.51707 10.8711 7.03892 10.8679H8.95503C9.47921 10.8679 9.90414 11.2898 9.90414 11.8102V13.8488C9.90413 14.2982 10.2693 14.6634 10.7219 14.6666H12.0292C12.6397 14.6682 13.2258 14.4285 13.6581 14.0005C14.0903 13.5724 14.3333 12.9912 14.3333 12.385V6.57723C14.3333 6.08759 14.1147 5.62314 13.7364 5.309L9.29528 1.78285C8.51897 1.16608 7.41021 1.18601 6.65689 1.83026L2.3113 5.309C1.91512 5.61388 1.67833 6.07971 1.66663 6.57723V12.3791C1.66663 13.6425 2.69821 14.6666 3.97074 14.6666H5.24815C5.46607 14.6682 5.67562 14.5834 5.83027 14.431C5.98493 14.2785 6.07191 14.0711 6.0719 13.8547H6.09578Z" fill="#434343"/>
                  </svg>     
              </a>
              <div class="div-2">
                <div class="text-wrapper-2">/</div>
                <div class="text-wrapper-2">Credit Note</div>
              </div>
              <div class="item">
                <div class="text-wrapper-2">/</div>
                <div class="text-wrapper-2">Page</div>
              </div>
              <div class="item">
                <div class="text-wrapper-2">/</div>
                <div class="text-wrapper-2">Page</div>
              </div>
              <div class="item">
                <div class="text-wrapper-2">/</div>
                <div class="text-wrapper-2">Page</div>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <header class="header">
            
            <Link to="/creditnote/addcreditnote">
            <div class="buttons">
              <div class="label-container">
                <svg
                  class="fi-plus"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 5V19"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5 12H19"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"  
                    stroke-linejoin="round"
                  />
                </svg>
                <p class="add-a-new-credit">

                    <span class="span">Add a New Credit note</span>
                  </p>
                </div>
              </div>
            </Link>
          </header>
          <div class="table">
            <div class="overlap">
              <div class="main-container-2">
                <div class="frame">
                  <div class="frame-2">
                    <div class="left">
                      <div class="input-content">
                       <a href="../dashboard/index.html">

                         <svg
                           class="iconly-light-search"
                           xmlns="http://www.w3.org/2000/svg"
                           width="20"
                           height="21"
                           viewBox="0 0 20 21"
                           fill="none"
                         >
                           <circle
                             cx="9.80549"
                             cy="10.3055"
                             r="7.49047"
                             stroke="#130F26"
                             stroke-width="1.5"
                             stroke-linecap="round"
                             stroke-linejoin="round"
                           />
                           <path
                             d="M15.0153 15.9043L17.9519 18.8333"
                             stroke="#130F26"
                             stroke-width="1.5"
                             stroke-linecap="round"
                             stroke-linejoin="round"
                           />
                         </svg>
                        </a>
                         <div class="div-wrapper">
                          <input type="text" class="text-wrappers" placeholder="Search"/>
                        </div>
                      </div>
                    </div>
                    <div class="right">
                      <button class="table-buttons">
                        <div class="label-container-2">
                          <svg
                            class="img"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="17"
                            viewBox="0 0 16 17"
                            fill="none"
                          >
                            <path
                              d="M14.6667 2.5H1.33333L6.66666 8.80667V13.1667L9.33333 14.5V8.80667L14.6667 2.5Z"
                              stroke="#53545C"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          <div class="text-wrapper-4">Filter</div>
                        </div>
                      </button>
                      <button class="table-buttons">
                        <div class="label-container-3">
                          <input type="date" id="calendar" name="calendar" />
                          <div class="text-wrapper-4">Filter</div>
                        </div>
                      </button>
                      <button class="table-buttons">
                        <div class="label-container-2">
                          <svg
                            class="img"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="17"
                            viewBox="0 0 16 17"
                            fill="none"
                          >
                            <path
                              d="M10.555 5.94976L6.73936 9.80612L2.39962 7.09178C1.77783 6.70276 1.90718 5.75829 2.61048 5.55262L12.9142 2.53518C13.5582 2.34642 14.155 2.94855 13.9637 3.59466L10.9154 13.8912C10.7066 14.5955 9.76747 14.7213 9.38214 14.0968L6.73734 9.8068"
                              stroke="#53545C"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          <div class="text-wrapper-4">Share</div>
                        </div>
                      </button>
                      <div class="label-container-wrapper">
                        <div class="label-container">
                          <div class="text-wrapper-4">Bulk Action</div>
                          <svg
                            class="img"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="17"
                            viewBox="0 0 16 17"
                            fill="none"
                          >
                            <path
                              d="M4 6.5L8 10.5L12 6.5"
                              stroke="#53545C"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                        <div class="dropdown-1">
                          <ul>
                            <li>
                              <input type="checkbox" id="selectAll" />
                              <label for="selectAll">Select all Credit-Notes</label>
                            </li>
                          {/* <!-- <li>  
                              <input type="checkbox" id="selectActive" />
                              <label for="selectActive">Select active customers</label>
                            </li> -->*/}  
                            {/* 
                             <!-- <li>
                              <input type="checkbox" id="selectInactive" />
                              <label for="selectInactive"
                                >Select inactive customers</label
                              >
                            </li> -->
                            */}
                           
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="table-2">
                  <div class="table-header"><div class="group">

                    <label class="custom-checkbox2">
                      <input class="group" type="checkbox" />
                      </label>
                      <span class="checkmark2"></span>

                  </div></div>
                  <div class="table-row-container">
                    <div class="table-row">
                      <div class="col-contents"> <label class="custom-checkbox2">
                      <input class="group" type="checkbox" />
                      </label>
                      <span class="checkmark2"></span></div></div>
                    <div class="col-contents-wrapper"><div class="col-contents"> <label class="custom-checkbox2">
                      <input class="group" type="checkbox" />
                      </label>
                      <span class="checkmark2"></span></div></div>
                    <div class="table-row-2"><div class="col-contents"> <label class="custom-checkbox2">
                      <input class="group" type="checkbox" />
                      </label>
                      <span class="checkmark2"></span></div></div>
                    <div class="table-row-3"><div class="col-contents"> <label class="custom-checkbox2">
                      <input class="group" type="checkbox" />
                      </label>
                      <span class="checkmark2"></span></div></div>
                    <div class="table-row-4"><div class="col-contents"> <label class="custom-checkbox2">
                      <input class="group" type="checkbox" />
                      </label>
                      <span class="checkmark2"></span></div></div>
                    <div class="table-row-5"><div class="col-contents"> <label class="custom-checkbox2">
                      <input class="group" type="checkbox" />
                      </label>
                      <span class="checkmark2"></span></div></div>
                    <div class="table-row-6"><div class="col-contents"> <label class="custom-checkbox2">
                      <input class="group" type="checkbox" />
                      </label>
                      <span class="checkmark2"></span></div></div>
                    <div class="table-row-7"><div class="col-contents"> <label class="custom-checkbox2">
                      <input class="group" type="checkbox" />
                      </label>
                      <span class="checkmark2"></span></div></div>
                    <div class="table-row-8"><div class="col-contents"> <label class="custom-checkbox2">
                      <input class="group" type="checkbox" />
                      </label>
                      <span class="checkmark2"></span></div></div>
                    <div class="table-row-9"><div class="col-contents"> <label class="custom-checkbox2">
                      <input class="group" type="checkbox" />
                      </label>
                      <span class="checkmark2"></span></div></div>
                  </div>
                  <div class="table-header-2">
                    <div class="text-wrapper-5">Items per page</div>
                    <div class="text-wrapper-6">1-10 of 70 items</div>
                    <div class="frame-3">
                      <div class="icon">
                        <div class="frame-4">
                          <div class="text-wrapper-7">1</div>
                          <svg
                            class="img"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="17"
                            viewBox="0 0 16 17"
                            fill="none"
                          >
                            <path
                              d="M4 6.5L8 10.5L12 6.5"
                              stroke="#8B8D97"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                      <div class="text-wrapper-8">of 7 pages</div>
                    </div>
                    <div class="frame-5">
                      <svg
                        class="img"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M10.4713 11.5286C10.7317 11.7889 10.7317 12.211 10.4713 12.4714C10.211 12.7317 9.78886 12.7317 9.52851 12.4714L5.52852 8.47136C5.27613 8.21898 5.2673 7.81259 5.50848 7.54948L9.17515 3.54948C9.42395 3.27806 9.84566 3.25973 10.1171 3.50852C10.3885 3.75732 10.4068 4.17903 10.158 4.45044L6.92268 7.97991L10.4713 11.5286Z"
                          fill="#666666"
                        />
                      </svg>
                      <svg
                        class="img"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M5.52868 4.47144C5.26833 4.2111 5.26833 3.78899 5.52868 3.52864C5.78903 3.26829 6.21114 3.26829 6.47149 3.52864L10.4715 7.52864C10.7239 7.78102 10.7327 8.18741 10.4915 8.45052L6.82485 12.4505C6.57605 12.7219 6.15434 12.7403 5.88293 12.4915C5.61152 12.2427 5.59318 11.821 5.84198 11.5496L9.07732 8.02009L5.52868 4.47144Z"
                          fill="#666666"
                        />
                      </svg>
                    </div>
                    <div class="frame-wrapper">
                      <div class="frame-4">
                        <div class="text-wrapper-7">10</div>
                        <svg
                          class="img"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="17"
                          viewBox="0 0 16 17"
                          fill="none"
                        >
                          <path
                            d="M4 6.5L8 10.5L12 6.5"
                            stroke="#8B8D97"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="frame-6">
                <div class="customer">
                  <div class="frame-7">
                    <div class="text-wrapper-9">Company Name</div>
                    <svg
                      class="img"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M14 5.16667H2C1.72667 5.16667 1.5 4.94 1.5 4.66667C1.5 4.39333 1.72667 4.16667 2 4.16667H14C14.2733 4.16667 14.5 4.39333 14.5 4.66667C14.5 4.94 14.2733 5.16667 14 5.16667Z"
                        fill="#00092E"
                      />
                      <path
                        d="M12 8.5H4C3.72667 8.5 3.5 8.27333 3.5 8C3.5 7.72667 3.72667 7.5 4 7.5H12C12.2733 7.5 12.5 7.72667 12.5 8C12.5 8.27333 12.2733 8.5 12 8.5Z"
                        fill="#00092E"
                      />
                      <path
                        d="M9.33334 11.8333H6.66667C6.39334 11.8333 6.16667 11.6067 6.16667 11.3333C6.16667 11.06 6.39334 10.8333 6.66667 10.8333H9.33334C9.60667 10.8333 9.83334 11.06 9.83334 11.3333C9.83334 11.6067 9.60667 11.8333 9.33334 11.8333Z"
                        fill="#00092E"
                      />
                    </svg>
                  </div>
                  <div class="customers">
                    <div class="text-wrapper-10">Albert Pitt</div>
                    <div class="text-wrapper-11">Emma Timberland</div>
                    <div class="text-wrapper-12">Jannet Ali</div>
                    <div class="text-wrapper-13">Tim Washington</div>
                    <div class="text-wrapper-14">Dwayne Dornan</div>
                    <div class="text-wrapper-15">Lilly Jackson</div>
                    <div class="text-wrapper-16">John Stone</div>
                    <div class="text-wrapper-17">Roman D jr.</div>
                    <div class="text-wrapper-18">Tony Montano</div>
                    <div class="text-wrapper-19">Charlie DiCaprio</div>
                  </div>
                </div>
                <div class="phone">
                  <div class="frame-8">
                    <div class="text-wrapper-9">Credit Amount</div>
                    <svg
                      class="img"
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                    >
                      <path
                        d="M14.205 5.16667H2.20499C1.93165 5.16667 1.70499 4.94 1.70499 4.66667C1.70499 4.39333 1.93165 4.16667 2.20499 4.16667H14.205C14.4783 4.16667 14.705 4.39333 14.705 4.66667C14.705 4.94 14.4783 5.16667 14.205 5.16667Z"
                        fill="#00092E"
                      />
                      <path
                        d="M12.205 8.5H4.20499C3.93165 8.5 3.70499 8.27333 3.70499 8C3.70499 7.72667 3.93165 7.5 4.20499 7.5H12.205C12.4783 7.5 12.705 7.72667 12.705 8C12.705 8.27333 12.4783 8.5 12.205 8.5Z"
                        fill="#00092E"
                      />
                      <path
                        d="M9.53831 11.8333H6.87164C6.59831 11.8333 6.37164 11.6067 6.37164 11.3333C6.37164 11.06 6.59831 10.8333 6.87164 10.8333H9.53831C9.81164 10.8333 10.0383 11.06 10.0383 11.3333C10.0383 11.6067 9.81164 11.8333 9.53831 11.8333Z"
                        fill="#00092E"
                      />
                    </svg>
                  </div>
                  <div class="text-wrapper-20">43564</div>
                  <div class="text-wrapper-21">35644</div>
                  <div class="text-wrapper-22">3454</div>
                  <div class="text-wrapper-23">43665</div>
                  <div class="text-wrapper-24">546</div>
                  <div class="text-wrapper-25">4567</div>
                  <div class="text-wrapper-26">43646</div>
                  <div class="text-wrapper-27">3636</div>
                  <div class="text-wrapper-28">3774</div>
                  <div class="text-wrapper-29">45645</div>
                </div>
                <div class="phone-2">
                  <div class="frame-9">
                    <div class="text-wrapper-9">Credit Date</div>
                    <svg
                      class="img"
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                    >
                      <path
                        d="M14.3552 5.16667H2.35522C2.08189 5.16667 1.85522 4.94 1.85522 4.66667C1.85522 4.39333 2.08189 4.16667 2.35522 4.16667H14.3552C14.6286 4.16667 14.8552 4.39333 14.8552 4.66667C14.8552 4.94 14.6286 5.16667 14.3552 5.16667Z"
                        fill="#00092E"
                      />
                      <path
                        d="M12.3552 8.5H4.35522C4.08189 8.5 3.85522 8.27333 3.85522 8C3.85522 7.72667 4.08189 7.5 4.35522 7.5H12.3552C12.6286 7.5 12.8552 7.72667 12.8552 8C12.8552 8.27333 12.6286 8.5 12.3552 8.5Z"
                        fill="#00092E"
                      />
                      <path
                        d="M9.68858 11.8333H7.02191C6.74858 11.8333 6.52191 11.6067 6.52191 11.3333C6.52191 11.06 6.74858 10.8333 7.02191 10.8333H9.68858C9.96191 10.8333 10.1886 11.06 10.1886 11.3333C10.1886 11.6067 9.96191 11.8333 9.68858 11.8333Z"
                        fill="#00092E"
                      />
                    </svg>
                  </div>
                  <div class="text-wrapper-20">01/12/23</div>
                  <div class="text-wrapper-30">02/12/23</div>
                  <div class="text-wrapper-31">03/12/23</div>
                  <div class="text-wrapper-32">04/12/23</div>
                  <div class="text-wrapper-33">05/12/23</div>
                  <div class="text-wrapper-34">0612/23</div>
                  <div class="text-wrapper-35">07/12/23</div>
                  <div class="text-wrapper-36">08/12/23</div>
                  <div class="text-wrapper-37">09/12/23</div>
                  <div class="text-wrapper-38">10/12/23</div>
                </div>
                <div class="overlap-group-wrapper">
                  <div class="overlap-group">
                    <div class="company">
                      <div class="frame-10">
                        <div class="text-wrapper-9">Invoice Reference</div>
                        <svg
                          class="img"
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                        >
                          <path
                            d="M14.5054 5.16667H2.50537C2.23204 5.16667 2.00537 4.94 2.00537 4.66667C2.00537 4.39333 2.23204 4.16667 2.50537 4.16667H14.5054C14.7787 4.16667 15.0054 4.39333 15.0054 4.66667C15.0054 4.94 14.7787 5.16667 14.5054 5.16667Z"
                            fill="#00092E"
                          />
                          <path
                            d="M12.5054 8.5H4.50537C4.23204 8.5 4.00537 8.27333 4.00537 8C4.00537 7.72667 4.23204 7.5 4.50537 7.5H12.5054C12.7787 7.5 13.0054 7.72667 13.0054 8C13.0054 8.27333 12.7787 8.5 12.5054 8.5Z"
                            fill="#00092E"
                          />
                          <path
                            d="M9.83872 11.8333H7.17206C6.89872 11.8333 6.67206 11.6067 6.67206 11.3333C6.67206 11.06 6.89872 10.8333 7.17206 10.8333H9.83872C10.1121 10.8333 10.3387 11.06 10.3387 11.3333C10.3387 11.6067 10.1121 11.8333 9.83872 11.8333Z"
                            fill="#00092E"
                          />
                        </svg>
                      </div>
                      <div class="group-wrapper">
                        <div class="group-2">
                          <div class="text-wrapper-39">6464</div>
                          <div class="text-wrapper-40">7466</div>
                          <div class="text-wrapper-41">4365</div>
                          <div class="text-wrapper-42">9765</div>
                          <div class="text-wrapper-43">2567</div>
                          <div class="text-wrapper-44">9646</div>
                          <div class="text-wrapper-45">1586</div>
                          <div class="text-wrapper-46">5685</div>
                          <div class="text-wrapper-47">3745</div>
                          <div class="text-wrapper-48">8796</div>
                        </div>
                      </div>
                    </div>
                    <div class="more">
                      <div class="text-wrapper-49">More</div>
                      <div class="text-wrapper-50">More</div>
                      <div class="text-wrapper-51">More</div>
                      <div class="text-wrapper-52">More</div>
                      <div class="text-wrapper-53">More</div>
                      <div class="text-wrapper-54">More</div>
                      <div class="text-wrapper-55">More</div>
                      <div class="text-wrapper-56">More</div>
                      <div class="text-wrapper-57">More</div>
                      <div class="text-wrapper-58">More</div>
                    </div>
                  </div>
                </div>
                <div class="overlap-wrapper">
                  <div class="overlap-group">
                    <div class="company-2">
                      <div class="frame-10">
                        <div class="text-wrapper-9">
                          Payment receipt Reference
                        </div>
                        <svg
                          class="img"
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                        >
                          <path
                            d="M14.5054 5.16667H2.50537C2.23204 5.16667 2.00537 4.94 2.00537 4.66667C2.00537 4.39333 2.23204 4.16667 2.50537 4.16667H14.5054C14.7787 4.16667 15.0054 4.39333 15.0054 4.66667C15.0054 4.94 14.7787 5.16667 14.5054 5.16667Z"
                            fill="#00092E"
                          />
                          <path
                            d="M12.5054 8.5H4.50537C4.23204 8.5 4.00537 8.27333 4.00537 8C4.00537 7.72667 4.23204 7.5 4.50537 7.5H12.5054C12.7787 7.5 13.0054 7.72667 13.0054 8C13.0054 8.27333 12.7787 8.5 12.5054 8.5Z"
                            fill="#00092E"
                          />
                          <path
                            d="M9.83866 11.8333H7.172C6.89866 11.8333 6.672 11.6067 6.672 11.3333C6.672 11.06 6.89866 10.8333 7.172 10.8333H9.83866C10.112 10.8333 10.3387 11.06 10.3387 11.3333C10.3387 11.6067 10.112 11.8333 9.83866 11.8333Z"
                            fill="#00092E"
                          />
                        </svg>
                      </div>
                      <div class="group-3">
                        <div class="group-4">
                          <div class="text-wrapper-59">34523</div>
                          <div class="text-wrapper-60">75 23</div>
                          <div class="text-wrapper-61">67 26</div>
                          <div class="text-wrapper-62">44 36</div>
                          <div class="text-wrapper-63">36 32</div>
                          <div class="text-wrapper-64">262</div>
                          <div class="text-wrapper-65">2632</div>
                          <div class="text-wrapper-66">2643</div>
                          <div class="text-wrapper-67">2643</div>
                          <div class="text-wrapper-68">2646</div>
                        </div>
                      </div>
                    </div>
                    <div class="more-2">
                      <div class="text-wrapper-69">More</div>
                      <div class="text-wrapper-70">More</div>
                      <div class="text-wrapper-71">More</div>
                      <div class="text-wrapper-72">More</div>
                      <div class="text-wrapper-73">More</div>
                      <div class="text-wrapper-74">More</div>
                      <div class="text-wrapper-75">More</div>
                      <div class="text-wrapper-76">More</div>
                      <div class="text-wrapper-77">More</div>
                      <div class="text-wrapper-78">More</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    
  </div>
  )
 }

 export default Creditnote;
  
  

