import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  companyData: {
    company_addresses: [],
  },
  contactData: [],
};

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompanyData: (state, action) => {
      state.companyData = action.payload;
    },
    clearCompanyData: (state, action) => {
      state.companyData = {
        company_addresses: [],
      };
    },
    setCompanyNameAndMemberCount: (state, action) => {
      state.companyData.company_employee_count =
        action.payload.company_employee_count;
      state.companyData.company_name = action.payload.company_name;
    },

    pushAddressToList: (state, action) => {
      state.companyData.company_addresses.push(action.payload);
    },

    createEmptyAddressList: (state, action) => {
      state.companyData.company_addresses = [];
    },

    setCompanyAddress: (state, action) => {
      state.companyData.addresses = action.payload;
    },
    pushToContactList: (state, action) => {
      state.contactData.push(action.payload);
    },
    clearContactList: (state, action) => {
      state.contactData = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCompanyAddress,
  pushAddressToList,
  setCompanyData,
  createEmptyAddressList,
  pushToContactList,
  clearContactList,
  setCompanyNameAndMemberCount,
  clearCompanyData,
} = companySlice.actions;

export default companySlice.reducer;
