import React, { useState } from 'react';
import {Routes, Route,NavLink } from 'react-router-dom';
//import './App.css';
//import './global.css';
import './style.css';
//import './styleguide.css';
import Customer from './pages/customers/customer';
import Service from './pages/services/service';
import Invoice from './pages/invoice/invoice';
import AddCreditnote from './pages/addcreditnote/addcreditnote';
import Creditnote from './pages/creditnote/creditnote';
import Permissions from './pages/permissions/permissions';
import AddCustomer from './pages/addcustomer/addcustomer';
import AddCustomerContacts from './pages/add_customer_contacts/index';
//import CustomerV2 from './customerv2/customerv2';

function App() {
  const [selected, setSelected] = useState(0);
  return (
    <>
    <div className="dashboard-empty">
      <div className="div">
        <div className="side-bar">
              <div className="menu-items">
               
              <div className="iconly-bulk-user-wrapper">
                  <NavLink to ="/dddd">
                  <svg
                    class="iconly-light"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3 6.5C3 3.87479 3.02811 3 6.5 3C9.97189 3 10 3.87479 10 6.5C10 9.12521 10.0111 10 6.5 10C2.98893 10 3 9.12521 3 6.5Z"
                      stroke="#53545C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M14 6.5C14 3.87479 14.0281 3 17.5 3C20.9719 3 21 3.87479 21 6.5C21 9.12521 21.0111 10 17.5 10C13.9889 10 14 9.12521 14 6.5Z"
                      stroke="#53545C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3 17.5C3 14.8748 3.02811 14 6.5 14C9.97189 14 10 14.8748 10 17.5C10 20.1252 10.0111 21 6.5 21C2.98893 21 3 20.1252 3 17.5Z"
                      stroke="#53545C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M14 17.5C14 14.8748 14.0281 14 17.5 14C20.9719 14 21 14.8748 21 17.5C21 20.1252 21.0111 21 17.5 21C13.9889 21 14 20.1252 14 17.5Z"
                      stroke="#53545C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </NavLink>
              </div>
                
        
                <div className="iconly-bulk-user-wrapper">
                  <NavLink exact to="/customer">
                    <svg
                      className="iconly-bulk-user"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M9.34933 14.8577C5.38553 14.8577 2 15.47 2 17.9174C2 20.3666 5.364 21 9.34933 21C13.3131 21 16.6987 20.3877 16.6987 17.9404C16.6987 15.4911 13.3347 14.8577 9.34933 14.8577Z"
                        fill="#383838"
                      />
                      <path
                        opacity="0.4"
                        d="M9.34935 12.5248C12.049 12.5248 14.2124 10.4062 14.2124 7.76241C14.2124 5.11865 12.049 3 9.34935 3C6.65072 3 4.48633 5.11865 4.48633 7.76241C4.48633 10.4062 6.65072 12.5248 9.34935 12.5248Z"
                        fill="#383838"
                      />
                      <path
                        opacity="0.4"
                        d="M16.1734 7.84875C16.1734 9.19506 15.7605 10.4513 15.0364 11.4948C14.9611 11.6021 15.0276 11.7468 15.1587 11.7698C15.3407 11.7995 15.5276 11.8177 15.7184 11.8216C17.6167 11.8704 19.3202 10.6736 19.7908 8.87118C20.4885 6.19675 18.4415 3.79543 15.8339 3.79543C15.5511 3.79543 15.2801 3.82417 15.0159 3.87688C14.9797 3.88454 14.9405 3.90179 14.921 3.93245C14.8955 3.97174 14.9141 4.02253 14.9395 4.05606C15.7233 5.13216 16.1734 6.44206 16.1734 7.84875Z"
                        fill="#383838"
                      />
                      <path
                        d="M21.7791 15.1694C21.4317 14.444 20.5932 13.9467 19.3172 13.7023C18.7155 13.5586 17.0853 13.3545 15.5697 13.3832C15.5472 13.3861 15.5344 13.4014 15.5325 13.411C15.5295 13.4263 15.5364 13.4493 15.5658 13.4656C16.2663 13.8048 18.9738 15.2805 18.6333 18.3928C18.6186 18.5289 18.7292 18.6439 18.8671 18.6247C19.5335 18.5318 21.2478 18.1705 21.7791 17.0475C22.0736 16.4534 22.0736 15.7635 21.7791 15.1694Z"
                        fill="#383838"
                      />
                    </svg>
                  </NavLink>
                </div>
                <NavLink exact to="/service">
                  <svg
                    className="img"
                    width="56"
                    height="56"
                    viewBox="0 0 56 56"
                    fill="none"
                  >
                    <path
                      d="M34 26.2143V28M34 26.2143C32.8432 26.2143 31.8241 25.6461 31.2263 24.7833M34 26.2143C35.1568 26.2143 36.1759 25.6461 36.7737 24.7833M31.2263 24.7833L29.5 26M31.2263 24.7833C30.8728 24.273 30.6667 23.6597 30.6667 23C30.6667 22.3403 30.8727 21.7271 31.2262 21.2169M36.7737 24.7833L38.5 26M36.7737 24.7833C37.1272 24.273 37.3333 23.6597 37.3333 23C37.3333 22.3403 37.1273 21.7271 36.7738 21.2169M34 19.7857C35.1569 19.7857 36.1761 20.354 36.7738 21.2169M34 19.7857C32.8431 19.7857 31.8239 20.354 31.2262 21.2169M34 19.7857V18M36.7738 21.2169L38.5 20M31.2262 21.2169L29.5 20"
                      stroke="#383838"
                      stroke-width="1.5"
                    />
                    <path
                      d="M26 18.5H18V26.5H26V18.5Z"
                      stroke="#383838"
                      stroke-width="1.5"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M26 30.5H18V38.5H26V30.5Z"
                      stroke="#383838"
                      stroke-width="1.5"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M38 30.5H30V38.5H38V30.5Z"
                      stroke="#383838"
                      stroke-width="1.5"
                      stroke-linejoin="round"
                    />
                  </svg>
                </NavLink>
                <NavLink exact to="/invoice">
                  <svg
                    className="img"
                    width="56"
                    height="56"
                    viewBox="0 0 56 56"
                    fill="none"
                  >
                    <path
                      d="M34 29.25V21.9985C34 21.8394 33.9368 21.6868 33.8243 21.5743L30.6757 18.4257C30.5632 18.3132 30.4106 18.25 30.2515 18.25H18.6C18.2686 18.25 18 18.5186 18 18.85V37.65C18 37.9814 18.2686 38.25 18.6 38.25H28"
                      stroke="#434343"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M30 18.25V21.65C30 21.9814 30.2686 22.25 30.6 22.25H34"
                      stroke="#434343"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M30 35.25H36M36 35.25L33 32.25M36 35.25L33 38.25"
                      stroke="#434343"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </NavLink>
                <NavLink exact to="/creditnote">
                  <svg
                    className="img"
                    width="56"
                    height="56"
                    viewBox="0 0 56 56"
                    fill="none"
                  >
                    <path
                      d="M22.4999 30.625H31.4999"
                      stroke="#434343"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M22.4999 26.125H24.7499"
                      stroke="#434343"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M22.4999 35.125H26.9999"
                      stroke="#434343"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M24.75 18.25H20.25C19.0074 18.25 18 19.2574 18 20.5V37.375C18 38.6177 19.0074 39.625 20.25 39.625H33.75C34.9927 39.625 36 38.6177 36 37.375V20.5C36 19.2574 34.9927 18.25 33.75 18.25H29.8125M24.75 18.25V16M24.75 18.25V20.5"
                      stroke="#434343"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </NavLink>
                <NavLink exact to="/permissions">
                  <svg
                    className="img"
                    width="56"
                    height="56"
                    viewBox="0 0 56 56"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M28.0493 16C30.7564 16 33.1731 17.7195 34.0599 20.2803C34.2231 20.7494 33.9735 21.2618 33.5031 21.425C33.0339 21.5894 32.5215 21.3386 32.3583 20.8682C31.7236 19.0335 29.9896 17.7999 28.0457 17.7999H28.0289C25.5186 17.7999 23.4738 19.8327 23.463 22.3406L23.4622 23.9509L32.6203 23.9518C35.625 23.9518 38.0705 26.3973 38.0705 29.4032V34.5486C38.0705 37.5545 35.625 40 32.6203 40H23.4502C20.4443 40 18 37.5545 18 34.5486V29.4032C18 27.0232 19.5324 24.9945 21.6625 24.2528L21.6631 22.361C21.6787 18.8355 24.5298 16 28.0253 16H28.0493ZM32.6203 25.7517H23.4502C21.4367 25.7517 19.7999 27.3896 19.7999 29.4032V34.5486C19.7999 36.5621 21.4367 38.2001 23.4502 38.2001H32.6203C34.6326 38.2001 36.2706 36.5621 36.2706 34.5486V29.4032C36.2706 27.3896 34.6326 25.7517 32.6203 25.7517ZM28.0356 29.7425C28.5324 29.7425 28.9356 30.1457 28.9356 30.6425V33.3088C28.9356 33.8056 28.5324 34.2088 28.0356 34.2088C27.5388 34.2088 27.1356 33.8056 27.1356 33.3088V30.6425C27.1356 30.1457 27.5388 29.7425 28.0356 29.7425Z"
                      fill="#383838"
                    />
                  </svg>
                </NavLink>
                <NavLink exact to="/ddddd"
                 className={({ isActive, isPending }) =>
                isActive? setSelected(6):setSelected(-1)
  
                 
               }
                >
             {selected===6?  <svg class="activee-2" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="56" height="56" viewBox="0 0 56 56" fill="none">
              <rect width="56" height="56" rx="12" fill="#383838"/>
              <mask id="mask0_211_36552" style={{maskType:'alpha'}} maskUnits="userSpaceOnUse" x="16" y="16" width="24" height="24">
              <rect x="16" y="16" width="24" height="24" fill="url(#pattern0)"/>
              </mask>
              <g mask="url(#mask0_211_36552)">
              <rect x="13" y="16" width="27" height="27" fill="#B1B1B1"/>
              </g>
              <defs>
              <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
              <use href="#image0_211_36552" transform="scale(0.0104167)"/>
              </pattern>
              <image id="image0_211_36552" width="96" height="96" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAADnUlEQVR4nO2du24TQRSGp4E3cOz/XztcK7epgIaLBBW8B6QjXESU8BqQvEqAdIBEWi4SERItl4IkElAZjdiIEHltr70753jP+aTp7PF/2V2vrbEnBKX0+/2TJJcBvCJ5QHJQMA7yx9yJz5HW3QgWFxdBcmdE6EVjJz5XWv9c0/975E8T/uF4s7S0dELax9xCcnmG8A/HbWkfcwuA17MWAOCltI+5heR+BWfAXrAEyWskN0m+H3PHMvUIxa9d+WvlHqKXjSzLrgatdLvd8wC2awphIFjA8Uva816vdy5oAsAlAN9SBEDhAvISoteLQQPtdvsMyS+pzFNBAXkJ3+NZH6SJp2RK41RSQF7Cdtq0h7/hDqwWQHKQZdmVtKn/b3iz4Mj4RfLurF8FsObCSnwVspJ7Gub1aZACwIcC0ytVzE8FBRzRcq9g/vdVzF/pB6VWq9VpWgGtVqtTMP9+kKJOw9oKGKWnqvnVCaIX4AWYPgO0oc6vOkHW/KoTZM2vOkHW/KoTZM2vOkHW/KoTZM2vOkHW/KoTZM2vOkHW/KoTZM2vOkHW/KoTZM2vOkHW/KoTZM2vOkHW/KoTZM2vOkHW/KoTZM2vOkETrGMNTfKrTtCYhVX56rabjfGrTtAEq9oA/CZ5KzTBrzpBEy4pnLYEdX7VCToGgEdFGuPlCMCNMM9+1Qk6Asn7Rfry8bnsm7I6v3UIas94pxIBsDom/E+dTudU2XkbXwCA6wB+klzTdOSbKAD/wj+cZ11T+I0uYEj4g7IlAHhQZ/iNLSD+zqwg/EF+t7I6wRxrdVzzTRSQZVkXwMcxAT6WPPKr9FspVQmatgQkDL9Kv0GjoKxkCQAepgy/ar8qBZHsAdgdE+x6Pmq/5psroMSZkPTIN1XAkRJ2NYVvqoASl6Nk4ZsroGQJtYdvsoAJS0gSvtkCxpSQLHzTBRSUkDT8YL2AYyUkDz9/fdsFROIHrDo+ZE2CFyCMFyCMFyCMFyCMFyCMFyCMFyCMFyCMFyCMFyCMFyCMFyCMFyCMFyCMxgL2hglq4g52vV6PBQX8aOzf12tixN/Xv5MUtTFMVP5z0JUmnAmL4zdweCImLu6rNWK1gomRZdnlIAmALekQKDQAvAjStNvt06m3saKCEfcSU7OhW9zULOVGbpQfX0leCJpYWFg4C+CZgSN/S2JJzMTEfbXi1k7x9qyi3VAHwiN6eBs9ib/hOo7jOI7jOI7jOI7jOKEp/AGfN9b4llBOKwAAAABJRU5ErkJggg=="/>
              </defs>
              </svg>
             : <svg
            
             xmlns="http://www.w3.org/2000/svg"
             xlink="http://www.w3.org/1999/xlink"
             width="56"
             height="56"
             viewBox="0 0 56 56"
             fill="none"
           >
             <mask
               id="mask0_391_2457"
               style={{maskType: 'alpha'}}
               maskUnits="userSpaceOnUse"
               x="16"
               y="15"
               width="24"
               height="24"
             >
               <rect
                 x="16"
                 y="15"
                 width="24"
                 height="24"
                 fill="url(#pattern0)"
               />
             </mask>
             <g mask="url(#mask0_391_2457)">
               <rect x="13" y="15" width="27" height="27" fill="#383838" />
             </g>
             <defs>
               <pattern
                 id="pattern0"
                 patternContentUnits="objectBoundingBox"
                 width="1"
                 height="1"
               >
                 <use
                   href="#image0_391_2457"
                   transform="scale(0.0104167)"
                 />
               </pattern>
               <image
                 id="image0_391_2457"
                 width="96"
                 height="96"
                 href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAADnUlEQVR4nO2du24TQRSGp4E3cOz/XztcK7epgIaLBBW8B6QjXESU8BqQvEqAdIBEWi4SERItl4IkElAZjdiIEHltr70753jP+aTp7PF/2V2vrbEnBKX0+/2TJJcBvCJ5QHJQMA7yx9yJz5HW3QgWFxdBcmdE6EVjJz5XWv9c0/975E8T/uF4s7S0dELax9xCcnmG8A/HbWkfcwuA17MWAOCltI+5heR+BWfAXrAEyWskN0m+H3PHMvUIxa9d+WvlHqKXjSzLrgatdLvd8wC2awphIFjA8Uva816vdy5oAsAlAN9SBEDhAvISoteLQQPtdvsMyS+pzFNBAXkJ3+NZH6SJp2RK41RSQF7Cdtq0h7/hDqwWQHKQZdmVtKn/b3iz4Mj4RfLurF8FsObCSnwVspJ7Gub1aZACwIcC0ytVzE8FBRzRcq9g/vdVzF/pB6VWq9VpWgGtVqtTMP9+kKJOw9oKGKWnqvnVCaIX4AWYPgO0oc6vOkHW/KoTZM2vOkHW/KoTZM2vOkHW/KoTZM2vOkHW/KoTZM2vOkHW/KoTZM2vOkHW/KoTZM2vOkHW/KoTZM2vOkHW/KoTZM2vOkHW/KoTZM2vOkETrGMNTfKrTtCYhVX56rabjfGrTtAEq9oA/CZ5KzTBrzpBEy4pnLYEdX7VCToGgEdFGuPlCMCNMM9+1Qk6Asn7Rfry8bnsm7I6v3UIas94pxIBsDom/E+dTudU2XkbXwCA6wB+klzTdOSbKAD/wj+cZ11T+I0uYEj4g7IlAHhQZ/iNLSD+zqwg/EF+t7I6wRxrdVzzTRSQZVkXwMcxAT6WPPKr9FspVQmatgQkDL9Kv0GjoKxkCQAepgy/ar8qBZHsAdgdE+x6Pmq/5psroMSZkPTIN1XAkRJ2NYVvqoASl6Nk4ZsroGQJtYdvsoAJS0gSvtkCxpSQLHzTBRSUkDT8YL2AYyUkDz9/fdsFROIHrDo+ZE2CFyCMFyCMFyCMFyCMFyCMFyCMFyCMFyCMFyCMFyCMFyCMFyCMFyCMFyCMxgL2hglq4g52vV6PBQX8aOzf12tixN/Xv5MUtTFMVP5z0JUmnAmL4zdweCImLu6rNWK1gomRZdnlIAmALekQKDQAvAjStNvt06m3saKCEfcSU7OhW9zULOVGbpQfX0leCJpYWFg4C+CZgSN/S2JJzMTEfbXi1k7x9qyi3VAHwiN6eBs9ib/hOo7jOI7jOI7jOI7jOKEp/AGfN9b4llBOKwAAAABJRU5ErkJggg=="
               />
             </defs>
           </svg>}     
               
                </NavLink>
              </div>
              <svg
                className="bottom"
                xmlns="http://www.w3.org/2000/svg"
                width="46"
                height="46"
                viewBox="0 0 46 46"
                fill="none"
              >
                <path
                  opacity="0.4"
                  d="M13 17.447C13 14.996 15.0302 13 17.5245 13H22.4856C24.9748 13 27 14.99 27 17.437V28.553C27 31.005 24.9698 33 22.4744 33H17.5154C15.0252 33 13 31.01 13 28.563V27.623V17.447Z"
                  fill="#383838"
                />
                <path
                  d="M32.779 22.4548L29.9332 19.5458C29.6391 19.2458 29.1657 19.2458 28.8726 19.5478C28.5804 19.8498 28.5814 20.3368 28.8745 20.6368L30.4338 22.2298H28.9388H20.5485C20.1346 22.2298 19.7986 22.5748 19.7986 22.9998C19.7986 23.4258 20.1346 23.7698 20.5485 23.7698H30.4338L28.8745 25.3628C28.5814 25.6628 28.5804 26.1498 28.8726 26.4518C29.0196 26.6028 29.2115 26.6788 29.4043 26.6788C29.5952 26.6788 29.7871 26.6028 29.9332 26.4538L32.779 23.5458C32.9202 23.4008 33 23.2048 33 22.9998C33 22.7958 32.9202 22.5998 32.779 22.4548Z"
                  fill="#383838"
                />
              </svg>
            </div>
      </div>

     
     
    </div>

    <Routes>
      <Route exact path="/customer" element={<Customer/>} />
      <Route exact path="/service" element={<Service/>} />
      <Route exact path="/invoice" element={<Invoice/>} />
      <Route exact path="/creditnote" element={<Creditnote/>} />
      <Route exact path="/creditnote/addcreditnote" element={<AddCreditnote/>} />
      <Route exact path="/permissions" element={<Permissions/>} />
      <Route exact path="/customer/addcustomer" element={<AddCustomer/>} />
      <Route exact path="/customer/addcustomer/addContact" element={<AddCustomerContacts/>} />
    </Routes>
  
    </>
  );
}

export default App;
