
import React from "react";
import './globals.css';
import './style.css';
import './styleguide.css';

const Service=()=>{
  return(  
    <div className="services">
      
        <div className="container">
          <div className="overlap-group">
            <div className="table">
              <div className="text-wrapper">Items per page</div>
              <div className="text-wrapper-2">1-10 of 70 items</div>
              <div className="frame">
                <div className="icon">
                  <div className="frame-2">
                    <div className="text-wrapper-3">1</div>
                    <svg className="img" xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                      <path d="M4 6.5L8 10.5L12 6.5" stroke="#8B8D97" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>

                  </div>
                </div>
                <div className="text-wrapper-4">of 7 pages</div>
              </div>
              <div className="frame-3">
                <svg className="img" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path d="M10.4713 11.5286C10.7317 11.7889 10.7317 12.211 10.4713 12.4714C10.211 12.7317 9.78886 12.7317 9.52851 12.4714L5.52852 8.47136C5.27613 8.21898 5.2673 7.81259 5.50848 7.54948L9.17515 3.54948C9.42395 3.27806 9.84566 3.25973 10.1171 3.50852C10.3885 3.75732 10.4068 4.17903 10.158 4.45044L6.92268 7.97991L10.4713 11.5286Z" fill="#666666"></path>
                </svg>
                <svg className="img" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path d="M5.52868 4.47144C5.26833 4.2111 5.26833 3.78899 5.52868 3.52864C5.78903 3.26829 6.21114 3.26829 6.47149 3.52864L10.4715 7.52864C10.7239 7.78102 10.7327 8.18741 10.4915 8.45052L6.82485 12.4505C6.57605 12.7219 6.15434 12.7403 5.88293 12.4915C5.61152 12.2427 5.59318 11.821 5.84198 11.5496L9.07732 8.02009L5.52868 4.47144Z" fill="#666666"></path>
                </svg>
              </div>
              <div className="frame-wrapper"> 
                <div className="frame-2">
                  <div className="text-wrapper-3">10</div>
                  <svg className="img" xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                    <path d="M4 6.5L8 10.5L12 6.5" stroke="#8B8D97" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
              </div>
              <div className="group">
                <div className="div-2">
                  <label className="custom-checkbox2">
                    <input className="group" type="checkbox" />
                    <span className="checkmark2"></span>
                    </label>
                </div>
                <div className="frame-4">
                  <div className="text-wrapper-5">Price</div>
                  <svg className="img"  xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M14 5.16675H2C1.72667 5.16675 1.5 4.94008 1.5 4.66675C1.5 4.39341 1.72667 4.16675 2 4.16675H14C14.2733 4.16675 14.5 4.39341 14.5 4.66675C14.5 4.94008 14.2733 5.16675 14 5.16675Z" fill="#00092E"/>
                    <path d="M12 8.5H4C3.72667 8.5 3.5 8.27333 3.5 8C3.5 7.72667 3.72667 7.5 4 7.5H12C12.2733 7.5 12.5 7.72667 12.5 8C12.5 8.27333 12.2733 8.5 12 8.5Z" fill="#00092E"/>
                    <path d="M9.33335 11.8333H6.66669C6.39335 11.8333 6.16669 11.6066 6.16669 11.3333C6.16669 11.0599 6.39335 10.8333 6.66669 10.8333H9.33335C9.60669 10.8333 9.83335 11.0599 9.83335 11.3333C9.83335 11.6066 9.60669 11.8333 9.33335 11.8333Z" fill="#00092E"/>
                    </svg>

                </div>
                <div className="frame-5">
                  <div className="text-wrapper-5">Unit</div>
                  <svg className="img"  xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M14 5.16675H2C1.72667 5.16675 1.5 4.94008 1.5 4.66675C1.5 4.39341 1.72667 4.16675 2 4.16675H14C14.2733 4.16675 14.5 4.39341 14.5 4.66675C14.5 4.94008 14.2733 5.16675 14 5.16675Z" fill="#00092E"/>
                    <path d="M12 8.5H4C3.72667 8.5 3.5 8.27333 3.5 8C3.5 7.72667 3.72667 7.5 4 7.5H12C12.2733 7.5 12.5 7.72667 12.5 8C12.5 8.27333 12.2733 8.5 12 8.5Z" fill="#00092E"/>
                    <path d="M9.33335 11.8333H6.66669C6.39335 11.8333 6.16669 11.6066 6.16669 11.3333C6.16669 11.0599 6.39335 10.8333 6.66669 10.8333H9.33335C9.60669 10.8333 9.83335 11.0599 9.83335 11.3333C9.83335 11.6066 9.60669 11.8333 9.33335 11.8333Z" fill="#00092E"/>
                    </svg>
                </div>
                <div className="frame-6">
                  <div className="text-wrapper-5">Status</div>
                  <svg className="img"  xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M14 5.16675H2C1.72667 5.16675 1.5 4.94008 1.5 4.66675C1.5 4.39341 1.72667 4.16675 2 4.16675H14C14.2733 4.16675 14.5 4.39341 14.5 4.66675C14.5 4.94008 14.2733 5.16675 14 5.16675Z" fill="#00092E"/>
                    <path d="M12 8.5H4C3.72667 8.5 3.5 8.27333 3.5 8C3.5 7.72667 3.72667 7.5 4 7.5H12C12.2733 7.5 12.5 7.72667 12.5 8C12.5 8.27333 12.2733 8.5 12 8.5Z" fill="#00092E"/>
                    <path d="M9.33335 11.8333H6.66669C6.39335 11.8333 6.16669 11.6066 6.16669 11.3333C6.16669 11.0599 6.39335 10.8333 6.66669 10.8333H9.33335C9.60669 10.8333 9.83335 11.0599 9.83335 11.3333C9.83335 11.6066 9.60669 11.8333 9.33335 11.8333Z" fill="#00092E"/>
                    </svg>
                </div>
                <div className="frame-7">
                  <div className="text-wrapper-5">Service Name</div>
                  <svg className="img"  xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M14 5.16675H2C1.72667 5.16675 1.5 4.94008 1.5 4.66675C1.5 4.39341 1.72667 4.16675 2 4.16675H14C14.2733 4.16675 14.5 4.39341 14.5 4.66675C14.5 4.94008 14.2733 5.16675 14 5.16675Z" fill="#00092E"/>
                    <path d="M12 8.5H4C3.72667 8.5 3.5 8.27333 3.5 8C3.5 7.72667 3.72667 7.5 4 7.5H12C12.2733 7.5 12.5 7.72667 12.5 8C12.5 8.27333 12.2733 8.5 12 8.5Z" fill="#00092E"/>
                    <path d="M9.33335 11.8333H6.66669C6.39335 11.8333 6.16669 11.6066 6.16669 11.3333C6.16669 11.0599 6.39335 10.8333 6.66669 10.8333H9.33335C9.60669 10.8333 9.83335 11.0599 9.83335 11.3333C9.83335 11.6066 9.60669 11.8333 9.33335 11.8333Z" fill="#00092E"/>
                    </svg>
                </div>
              </div>
              <div className="group-2">
                <div className="div-2">  
                <label className="custom-checkbox2">
                  <input className="group" type="checkbox" />
                  </label>
                  <span className="checkmark2"></span></div>
                  
                <div className="group-3">
                  <div className="text-wrapper-6">$4432</div>
                  <svg className="u-copy-alt" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M10.6667 13.3334H5.33333C4.8029 13.3334 4.29419 13.1227 3.91912 12.7476C3.54405 12.3725 3.33333 11.8638 3.33333 11.3334V4.66671C3.33333 4.4899 3.2631 4.32033 3.13807 4.1953C3.01305 4.07028 2.84348 4.00004 2.66667 4.00004C2.48986 4.00004 2.32029 4.07028 2.19526 4.1953C2.07024 4.32033 2 4.4899 2 4.66671V11.3334C2 12.2174 2.35119 13.0653 2.97631 13.6904C3.60143 14.3155 4.44928 14.6667 5.33333 14.6667H10.6667C10.8435 14.6667 11.013 14.5965 11.1381 14.4714C11.2631 14.3464 11.3333 14.1769 11.3333 14C11.3333 13.8232 11.2631 13.6537 11.1381 13.5286C11.013 13.4036 10.8435 13.3334 10.6667 13.3334ZM14 5.96004C13.9931 5.8988 13.9796 5.83846 13.96 5.78004V5.72004C13.9279 5.65149 13.8852 5.58848 13.8333 5.53337V5.53337L9.83333 1.53337C9.77822 1.48152 9.71521 1.43876 9.64667 1.40671H9.58667L9.37333 1.33337H6.66667C6.13623 1.33337 5.62753 1.54409 5.25245 1.91916C4.87738 2.29423 4.66667 2.80294 4.66667 3.33337V10C4.66667 10.5305 4.87738 11.0392 5.25245 11.4143C5.62753 11.7893 6.13623 12 6.66667 12H12C12.5304 12 13.0391 11.7893 13.4142 11.4143C13.7893 11.0392 14 10.5305 14 10V6.00004C14 6.00004 14 6.00004 14 5.96004ZM10 3.60671L11.7267 5.33337H10.6667C10.4899 5.33337 10.3203 5.26314 10.1953 5.13811C10.0702 5.01309 10 4.84352 10 4.66671V3.60671ZM12.6667 10C12.6667 10.1769 12.5964 10.3464 12.4714 10.4714C12.3464 10.5965 12.1768 10.6667 12 10.6667H6.66667C6.48986 10.6667 6.32029 10.5965 6.19526 10.4714C6.07024 10.3464 6 10.1769 6 10V3.33337C6 3.15656 6.07024 2.98699 6.19526 2.86197C6.32029 2.73695 6.48986 2.66671 6.66667 2.66671H8.66667V4.66671C8.66667 5.19714 8.87738 5.70585 9.25245 6.08092C9.62753 6.45599 10.1362 6.66671 10.6667 6.66671H12.6667V10Z" fill="#8B8D97"/>
                    </svg>
                </div>
                <div className="group-4">
                  <div className="text-wrapper-7">56</div>
                  <svg className="u-copy-alt-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M10.6667 13.3334H5.33333C4.8029 13.3334 4.29419 13.1227 3.91912 12.7476C3.54405 12.3725 3.33333 11.8638 3.33333 11.3334V4.66671C3.33333 4.4899 3.2631 4.32033 3.13807 4.1953C3.01305 4.07028 2.84348 4.00004 2.66667 4.00004C2.48986 4.00004 2.32029 4.07028 2.19526 4.1953C2.07024 4.32033 2 4.4899 2 4.66671V11.3334C2 12.2174 2.35119 13.0653 2.97631 13.6904C3.60143 14.3155 4.44928 14.6667 5.33333 14.6667H10.6667C10.8435 14.6667 11.013 14.5965 11.1381 14.4714C11.2631 14.3464 11.3333 14.1769 11.3333 14C11.3333 13.8232 11.2631 13.6537 11.1381 13.5286C11.013 13.4036 10.8435 13.3334 10.6667 13.3334ZM14 5.96004C13.9931 5.8988 13.9796 5.83846 13.96 5.78004V5.72004C13.9279 5.65149 13.8852 5.58848 13.8333 5.53337V5.53337L9.83333 1.53337C9.77822 1.48152 9.71521 1.43876 9.64667 1.40671H9.58667L9.37333 1.33337H6.66667C6.13623 1.33337 5.62753 1.54409 5.25245 1.91916C4.87738 2.29423 4.66667 2.80294 4.66667 3.33337V10C4.66667 10.5305 4.87738 11.0392 5.25245 11.4143C5.62753 11.7893 6.13623 12 6.66667 12H12C12.5304 12 13.0391 11.7893 13.4142 11.4143C13.7893 11.0392 14 10.5305 14 10V6.00004C14 6.00004 14 6.00004 14 5.96004ZM10 3.60671L11.7267 5.33337H10.6667C10.4899 5.33337 10.3203 5.26314 10.1953 5.13811C10.0702 5.01309 10 4.84352 10 4.66671V3.60671ZM12.6667 10C12.6667 10.1769 12.5964 10.3464 12.4714 10.4714C12.3464 10.5965 12.1768 10.6667 12 10.6667H6.66667C6.48986 10.6667 6.32029 10.5965 6.19526 10.4714C6.07024 10.3464 6 10.1769 6 10V3.33337C6 3.15656 6.07024 2.98699 6.19526 2.86197C6.32029 2.73695 6.48986 2.66671 6.66667 2.66671H8.66667V4.66671C8.66667 5.19714 8.87738 5.70585 9.25245 6.08092C9.62753 6.45599 10.1362 6.66671 10.6667 6.66671H12.6667V10Z" fill="#8B8D97"/>
                    </svg>
                </div>
                <div className="status"><div className="text-wrapper-8">Active</div></div>
                <div className="text-wrapper-9">Service 1</div>
              </div>
              <div className="group-5">
                <div className="div-2">  <label className="custom-checkbox2">
                  <input className="group" type="checkbox" />
                  </label>
                  <span className="checkmark2"></span></div>
                <div className="group-3">
                  <div className="text-wrapper-7">$5884</div>
                  <svg className="u-copy-alt" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M10.6667 13.3334H5.33333C4.8029 13.3334 4.29419 13.1227 3.91912 12.7476C3.54405 12.3725 3.33333 11.8638 3.33333 11.3334V4.66671C3.33333 4.4899 3.2631 4.32033 3.13807 4.1953C3.01305 4.07028 2.84348 4.00004 2.66667 4.00004C2.48986 4.00004 2.32029 4.07028 2.19526 4.1953C2.07024 4.32033 2 4.4899 2 4.66671V11.3334C2 12.2174 2.35119 13.0653 2.97631 13.6904C3.60143 14.3155 4.44928 14.6667 5.33333 14.6667H10.6667C10.8435 14.6667 11.013 14.5965 11.1381 14.4714C11.2631 14.3464 11.3333 14.1769 11.3333 14C11.3333 13.8232 11.2631 13.6537 11.1381 13.5286C11.013 13.4036 10.8435 13.3334 10.6667 13.3334ZM14 5.96004C13.9931 5.8988 13.9796 5.83846 13.96 5.78004V5.72004C13.9279 5.65149 13.8852 5.58848 13.8333 5.53337V5.53337L9.83333 1.53337C9.77822 1.48152 9.71521 1.43876 9.64667 1.40671H9.58667L9.37333 1.33337H6.66667C6.13623 1.33337 5.62753 1.54409 5.25245 1.91916C4.87738 2.29423 4.66667 2.80294 4.66667 3.33337V10C4.66667 10.5305 4.87738 11.0392 5.25245 11.4143C5.62753 11.7893 6.13623 12 6.66667 12H12C12.5304 12 13.0391 11.7893 13.4142 11.4143C13.7893 11.0392 14 10.5305 14 10V6.00004C14 6.00004 14 6.00004 14 5.96004ZM10 3.60671L11.7267 5.33337H10.6667C10.4899 5.33337 10.3203 5.26314 10.1953 5.13811C10.0702 5.01309 10 4.84352 10 4.66671V3.60671ZM12.6667 10C12.6667 10.1769 12.5964 10.3464 12.4714 10.4714C12.3464 10.5965 12.1768 10.6667 12 10.6667H6.66667C6.48986 10.6667 6.32029 10.5965 6.19526 10.4714C6.07024 10.3464 6 10.1769 6 10V3.33337C6 3.15656 6.07024 2.98699 6.19526 2.86197C6.32029 2.73695 6.48986 2.66671 6.66667 2.66671H8.66667V4.66671C8.66667 5.19714 8.87738 5.70585 9.25245 6.08092C9.62753 6.45599 10.1362 6.66671 10.6667 6.66671H12.6667V10Z" fill="#8B8D97"/>
                    </svg>
                </div>
                <div className="group-4">
                  <div className="text-wrapper-7">76</div>
                  <svg className="u-copy-alt-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M10.6667 13.3334H5.33333C4.8029 13.3334 4.29419 13.1227 3.91912 12.7476C3.54405 12.3725 3.33333 11.8638 3.33333 11.3334V4.66671C3.33333 4.4899 3.2631 4.32033 3.13807 4.1953C3.01305 4.07028 2.84348 4.00004 2.66667 4.00004C2.48986 4.00004 2.32029 4.07028 2.19526 4.1953C2.07024 4.32033 2 4.4899 2 4.66671V11.3334C2 12.2174 2.35119 13.0653 2.97631 13.6904C3.60143 14.3155 4.44928 14.6667 5.33333 14.6667H10.6667C10.8435 14.6667 11.013 14.5965 11.1381 14.4714C11.2631 14.3464 11.3333 14.1769 11.3333 14C11.3333 13.8232 11.2631 13.6537 11.1381 13.5286C11.013 13.4036 10.8435 13.3334 10.6667 13.3334ZM14 5.96004C13.9931 5.8988 13.9796 5.83846 13.96 5.78004V5.72004C13.9279 5.65149 13.8852 5.58848 13.8333 5.53337V5.53337L9.83333 1.53337C9.77822 1.48152 9.71521 1.43876 9.64667 1.40671H9.58667L9.37333 1.33337H6.66667C6.13623 1.33337 5.62753 1.54409 5.25245 1.91916C4.87738 2.29423 4.66667 2.80294 4.66667 3.33337V10C4.66667 10.5305 4.87738 11.0392 5.25245 11.4143C5.62753 11.7893 6.13623 12 6.66667 12H12C12.5304 12 13.0391 11.7893 13.4142 11.4143C13.7893 11.0392 14 10.5305 14 10V6.00004C14 6.00004 14 6.00004 14 5.96004ZM10 3.60671L11.7267 5.33337H10.6667C10.4899 5.33337 10.3203 5.26314 10.1953 5.13811C10.0702 5.01309 10 4.84352 10 4.66671V3.60671ZM12.6667 10C12.6667 10.1769 12.5964 10.3464 12.4714 10.4714C12.3464 10.5965 12.1768 10.6667 12 10.6667H6.66667C6.48986 10.6667 6.32029 10.5965 6.19526 10.4714C6.07024 10.3464 6 10.1769 6 10V3.33337C6 3.15656 6.07024 2.98699 6.19526 2.86197C6.32029 2.73695 6.48986 2.66671 6.66667 2.66671H8.66667V4.66671C8.66667 5.19714 8.87738 5.70585 9.25245 6.08092C9.62753 6.45599 10.1362 6.66671 10.6667 6.66671H12.6667V10Z" fill="#8B8D97"/>
                    </svg>
                </div>
                <div className="status"><div className="text-wrapper-8">Active</div></div>
                <div className="text-wrapper-9">Service 2</div>
              </div>
              <div className="group-6">
                <div className="div-2">  <label className="custom-checkbox2">
                  <input className="group" type="checkbox" />
                  </label>
                  <span className="checkmark2"></span></div>
                <div className="group-3">
                  <div className="text-wrapper-7">$6577</div>
                  <svg className="u-copy-alt" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M10.6667 13.3334H5.33333C4.8029 13.3334 4.29419 13.1227 3.91912 12.7476C3.54405 12.3725 3.33333 11.8638 3.33333 11.3334V4.66671C3.33333 4.4899 3.2631 4.32033 3.13807 4.1953C3.01305 4.07028 2.84348 4.00004 2.66667 4.00004C2.48986 4.00004 2.32029 4.07028 2.19526 4.1953C2.07024 4.32033 2 4.4899 2 4.66671V11.3334C2 12.2174 2.35119 13.0653 2.97631 13.6904C3.60143 14.3155 4.44928 14.6667 5.33333 14.6667H10.6667C10.8435 14.6667 11.013 14.5965 11.1381 14.4714C11.2631 14.3464 11.3333 14.1769 11.3333 14C11.3333 13.8232 11.2631 13.6537 11.1381 13.5286C11.013 13.4036 10.8435 13.3334 10.6667 13.3334ZM14 5.96004C13.9931 5.8988 13.9796 5.83846 13.96 5.78004V5.72004C13.9279 5.65149 13.8852 5.58848 13.8333 5.53337V5.53337L9.83333 1.53337C9.77822 1.48152 9.71521 1.43876 9.64667 1.40671H9.58667L9.37333 1.33337H6.66667C6.13623 1.33337 5.62753 1.54409 5.25245 1.91916C4.87738 2.29423 4.66667 2.80294 4.66667 3.33337V10C4.66667 10.5305 4.87738 11.0392 5.25245 11.4143C5.62753 11.7893 6.13623 12 6.66667 12H12C12.5304 12 13.0391 11.7893 13.4142 11.4143C13.7893 11.0392 14 10.5305 14 10V6.00004C14 6.00004 14 6.00004 14 5.96004ZM10 3.60671L11.7267 5.33337H10.6667C10.4899 5.33337 10.3203 5.26314 10.1953 5.13811C10.0702 5.01309 10 4.84352 10 4.66671V3.60671ZM12.6667 10C12.6667 10.1769 12.5964 10.3464 12.4714 10.4714C12.3464 10.5965 12.1768 10.6667 12 10.6667H6.66667C6.48986 10.6667 6.32029 10.5965 6.19526 10.4714C6.07024 10.3464 6 10.1769 6 10V3.33337C6 3.15656 6.07024 2.98699 6.19526 2.86197C6.32029 2.73695 6.48986 2.66671 6.66667 2.66671H8.66667V4.66671C8.66667 5.19714 8.87738 5.70585 9.25245 6.08092C9.62753 6.45599 10.1362 6.66671 10.6667 6.66671H12.6667V10Z" fill="#8B8D97"/>
                    </svg>
                </div>
                <div className="group-4">
                  <div className="text-wrapper-7">46</div>
                  <svg className="u-copy-alt-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M10.6667 13.3334H5.33333C4.8029 13.3334 4.29419 13.1227 3.91912 12.7476C3.54405 12.3725 3.33333 11.8638 3.33333 11.3334V4.66671C3.33333 4.4899 3.2631 4.32033 3.13807 4.1953C3.01305 4.07028 2.84348 4.00004 2.66667 4.00004C2.48986 4.00004 2.32029 4.07028 2.19526 4.1953C2.07024 4.32033 2 4.4899 2 4.66671V11.3334C2 12.2174 2.35119 13.0653 2.97631 13.6904C3.60143 14.3155 4.44928 14.6667 5.33333 14.6667H10.6667C10.8435 14.6667 11.013 14.5965 11.1381 14.4714C11.2631 14.3464 11.3333 14.1769 11.3333 14C11.3333 13.8232 11.2631 13.6537 11.1381 13.5286C11.013 13.4036 10.8435 13.3334 10.6667 13.3334ZM14 5.96004C13.9931 5.8988 13.9796 5.83846 13.96 5.78004V5.72004C13.9279 5.65149 13.8852 5.58848 13.8333 5.53337V5.53337L9.83333 1.53337C9.77822 1.48152 9.71521 1.43876 9.64667 1.40671H9.58667L9.37333 1.33337H6.66667C6.13623 1.33337 5.62753 1.54409 5.25245 1.91916C4.87738 2.29423 4.66667 2.80294 4.66667 3.33337V10C4.66667 10.5305 4.87738 11.0392 5.25245 11.4143C5.62753 11.7893 6.13623 12 6.66667 12H12C12.5304 12 13.0391 11.7893 13.4142 11.4143C13.7893 11.0392 14 10.5305 14 10V6.00004C14 6.00004 14 6.00004 14 5.96004ZM10 3.60671L11.7267 5.33337H10.6667C10.4899 5.33337 10.3203 5.26314 10.1953 5.13811C10.0702 5.01309 10 4.84352 10 4.66671V3.60671ZM12.6667 10C12.6667 10.1769 12.5964 10.3464 12.4714 10.4714C12.3464 10.5965 12.1768 10.6667 12 10.6667H6.66667C6.48986 10.6667 6.32029 10.5965 6.19526 10.4714C6.07024 10.3464 6 10.1769 6 10V3.33337C6 3.15656 6.07024 2.98699 6.19526 2.86197C6.32029 2.73695 6.48986 2.66671 6.66667 2.66671H8.66667V4.66671C8.66667 5.19714 8.87738 5.70585 9.25245 6.08092C9.62753 6.45599 10.1362 6.66671 10.6667 6.66671H12.6667V10Z" fill="#8B8D97"/>
                    </svg>
                </div>
                <div className="status"><div className="text-wrapper-8">Active</div></div>
                <div className="text-wrapper-9">Service 3</div>
              </div>
              <div className="group-7">
                <div className="div-2">  <label className="custom-checkbox2">
                  <input className="group" type="checkbox" />
                  </label>
                  <span className="checkmark2"></span></div>
                <div className="group-3">
                  <div className="text-wrapper-7">$5657</div>
                  <svg className="u-copy-alt" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M10.6667 13.3334H5.33333C4.8029 13.3334 4.29419 13.1227 3.91912 12.7476C3.54405 12.3725 3.33333 11.8638 3.33333 11.3334V4.66671C3.33333 4.4899 3.2631 4.32033 3.13807 4.1953C3.01305 4.07028 2.84348 4.00004 2.66667 4.00004C2.48986 4.00004 2.32029 4.07028 2.19526 4.1953C2.07024 4.32033 2 4.4899 2 4.66671V11.3334C2 12.2174 2.35119 13.0653 2.97631 13.6904C3.60143 14.3155 4.44928 14.6667 5.33333 14.6667H10.6667C10.8435 14.6667 11.013 14.5965 11.1381 14.4714C11.2631 14.3464 11.3333 14.1769 11.3333 14C11.3333 13.8232 11.2631 13.6537 11.1381 13.5286C11.013 13.4036 10.8435 13.3334 10.6667 13.3334ZM14 5.96004C13.9931 5.8988 13.9796 5.83846 13.96 5.78004V5.72004C13.9279 5.65149 13.8852 5.58848 13.8333 5.53337V5.53337L9.83333 1.53337C9.77822 1.48152 9.71521 1.43876 9.64667 1.40671H9.58667L9.37333 1.33337H6.66667C6.13623 1.33337 5.62753 1.54409 5.25245 1.91916C4.87738 2.29423 4.66667 2.80294 4.66667 3.33337V10C4.66667 10.5305 4.87738 11.0392 5.25245 11.4143C5.62753 11.7893 6.13623 12 6.66667 12H12C12.5304 12 13.0391 11.7893 13.4142 11.4143C13.7893 11.0392 14 10.5305 14 10V6.00004C14 6.00004 14 6.00004 14 5.96004ZM10 3.60671L11.7267 5.33337H10.6667C10.4899 5.33337 10.3203 5.26314 10.1953 5.13811C10.0702 5.01309 10 4.84352 10 4.66671V3.60671ZM12.6667 10C12.6667 10.1769 12.5964 10.3464 12.4714 10.4714C12.3464 10.5965 12.1768 10.6667 12 10.6667H6.66667C6.48986 10.6667 6.32029 10.5965 6.19526 10.4714C6.07024 10.3464 6 10.1769 6 10V3.33337C6 3.15656 6.07024 2.98699 6.19526 2.86197C6.32029 2.73695 6.48986 2.66671 6.66667 2.66671H8.66667V4.66671C8.66667 5.19714 8.87738 5.70585 9.25245 6.08092C9.62753 6.45599 10.1362 6.66671 10.6667 6.66671H12.6667V10Z" fill="#8B8D97"/>
                    </svg>
                </div>
                <div className="group-4">
                  <div className="text-wrapper-7">47</div>
                  <svg className="u-copy-alt-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M10.6667 13.3334H5.33333C4.8029 13.3334 4.29419 13.1227 3.91912 12.7476C3.54405 12.3725 3.33333 11.8638 3.33333 11.3334V4.66671C3.33333 4.4899 3.2631 4.32033 3.13807 4.1953C3.01305 4.07028 2.84348 4.00004 2.66667 4.00004C2.48986 4.00004 2.32029 4.07028 2.19526 4.1953C2.07024 4.32033 2 4.4899 2 4.66671V11.3334C2 12.2174 2.35119 13.0653 2.97631 13.6904C3.60143 14.3155 4.44928 14.6667 5.33333 14.6667H10.6667C10.8435 14.6667 11.013 14.5965 11.1381 14.4714C11.2631 14.3464 11.3333 14.1769 11.3333 14C11.3333 13.8232 11.2631 13.6537 11.1381 13.5286C11.013 13.4036 10.8435 13.3334 10.6667 13.3334ZM14 5.96004C13.9931 5.8988 13.9796 5.83846 13.96 5.78004V5.72004C13.9279 5.65149 13.8852 5.58848 13.8333 5.53337V5.53337L9.83333 1.53337C9.77822 1.48152 9.71521 1.43876 9.64667 1.40671H9.58667L9.37333 1.33337H6.66667C6.13623 1.33337 5.62753 1.54409 5.25245 1.91916C4.87738 2.29423 4.66667 2.80294 4.66667 3.33337V10C4.66667 10.5305 4.87738 11.0392 5.25245 11.4143C5.62753 11.7893 6.13623 12 6.66667 12H12C12.5304 12 13.0391 11.7893 13.4142 11.4143C13.7893 11.0392 14 10.5305 14 10V6.00004C14 6.00004 14 6.00004 14 5.96004ZM10 3.60671L11.7267 5.33337H10.6667C10.4899 5.33337 10.3203 5.26314 10.1953 5.13811C10.0702 5.01309 10 4.84352 10 4.66671V3.60671ZM12.6667 10C12.6667 10.1769 12.5964 10.3464 12.4714 10.4714C12.3464 10.5965 12.1768 10.6667 12 10.6667H6.66667C6.48986 10.6667 6.32029 10.5965 6.19526 10.4714C6.07024 10.3464 6 10.1769 6 10V3.33337C6 3.15656 6.07024 2.98699 6.19526 2.86197C6.32029 2.73695 6.48986 2.66671 6.66667 2.66671H8.66667V4.66671C8.66667 5.19714 8.87738 5.70585 9.25245 6.08092C9.62753 6.45599 10.1362 6.66671 10.6667 6.66671H12.6667V10Z" fill="#8B8D97"/>
                    </svg>
                </div>
                <div className="status"><div className="text-wrapper-8">Active</div></div>
                <div className="text-wrapper-9">Service 4</div>
              </div>
              <div className="group-8">
                <div className="div-2">  <label className="custom-checkbox2">
                  <input className="group" type="checkbox" />
                  </label>
                  <span className="checkmark2"></span></div>
                <div className="group-3">
                  <div className="text-wrapper-7">$56546</div>
                  <svg className="u-copy-alt" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M10.6667 13.3334H5.33333C4.8029 13.3334 4.29419 13.1227 3.91912 12.7476C3.54405 12.3725 3.33333 11.8638 3.33333 11.3334V4.66671C3.33333 4.4899 3.2631 4.32033 3.13807 4.1953C3.01305 4.07028 2.84348 4.00004 2.66667 4.00004C2.48986 4.00004 2.32029 4.07028 2.19526 4.1953C2.07024 4.32033 2 4.4899 2 4.66671V11.3334C2 12.2174 2.35119 13.0653 2.97631 13.6904C3.60143 14.3155 4.44928 14.6667 5.33333 14.6667H10.6667C10.8435 14.6667 11.013 14.5965 11.1381 14.4714C11.2631 14.3464 11.3333 14.1769 11.3333 14C11.3333 13.8232 11.2631 13.6537 11.1381 13.5286C11.013 13.4036 10.8435 13.3334 10.6667 13.3334ZM14 5.96004C13.9931 5.8988 13.9796 5.83846 13.96 5.78004V5.72004C13.9279 5.65149 13.8852 5.58848 13.8333 5.53337V5.53337L9.83333 1.53337C9.77822 1.48152 9.71521 1.43876 9.64667 1.40671H9.58667L9.37333 1.33337H6.66667C6.13623 1.33337 5.62753 1.54409 5.25245 1.91916C4.87738 2.29423 4.66667 2.80294 4.66667 3.33337V10C4.66667 10.5305 4.87738 11.0392 5.25245 11.4143C5.62753 11.7893 6.13623 12 6.66667 12H12C12.5304 12 13.0391 11.7893 13.4142 11.4143C13.7893 11.0392 14 10.5305 14 10V6.00004C14 6.00004 14 6.00004 14 5.96004ZM10 3.60671L11.7267 5.33337H10.6667C10.4899 5.33337 10.3203 5.26314 10.1953 5.13811C10.0702 5.01309 10 4.84352 10 4.66671V3.60671ZM12.6667 10C12.6667 10.1769 12.5964 10.3464 12.4714 10.4714C12.3464 10.5965 12.1768 10.6667 12 10.6667H6.66667C6.48986 10.6667 6.32029 10.5965 6.19526 10.4714C6.07024 10.3464 6 10.1769 6 10V3.33337C6 3.15656 6.07024 2.98699 6.19526 2.86197C6.32029 2.73695 6.48986 2.66671 6.66667 2.66671H8.66667V4.66671C8.66667 5.19714 8.87738 5.70585 9.25245 6.08092C9.62753 6.45599 10.1362 6.66671 10.6667 6.66671H12.6667V10Z" fill="#8B8D97"/>
                    </svg>
                </div>
                <div className="group-4">
                  <div className="text-wrapper-7">37</div>
                  <svg className="u-copy-alt-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M10.6667 13.3334H5.33333C4.8029 13.3334 4.29419 13.1227 3.91912 12.7476C3.54405 12.3725 3.33333 11.8638 3.33333 11.3334V4.66671C3.33333 4.4899 3.2631 4.32033 3.13807 4.1953C3.01305 4.07028 2.84348 4.00004 2.66667 4.00004C2.48986 4.00004 2.32029 4.07028 2.19526 4.1953C2.07024 4.32033 2 4.4899 2 4.66671V11.3334C2 12.2174 2.35119 13.0653 2.97631 13.6904C3.60143 14.3155 4.44928 14.6667 5.33333 14.6667H10.6667C10.8435 14.6667 11.013 14.5965 11.1381 14.4714C11.2631 14.3464 11.3333 14.1769 11.3333 14C11.3333 13.8232 11.2631 13.6537 11.1381 13.5286C11.013 13.4036 10.8435 13.3334 10.6667 13.3334ZM14 5.96004C13.9931 5.8988 13.9796 5.83846 13.96 5.78004V5.72004C13.9279 5.65149 13.8852 5.58848 13.8333 5.53337V5.53337L9.83333 1.53337C9.77822 1.48152 9.71521 1.43876 9.64667 1.40671H9.58667L9.37333 1.33337H6.66667C6.13623 1.33337 5.62753 1.54409 5.25245 1.91916C4.87738 2.29423 4.66667 2.80294 4.66667 3.33337V10C4.66667 10.5305 4.87738 11.0392 5.25245 11.4143C5.62753 11.7893 6.13623 12 6.66667 12H12C12.5304 12 13.0391 11.7893 13.4142 11.4143C13.7893 11.0392 14 10.5305 14 10V6.00004C14 6.00004 14 6.00004 14 5.96004ZM10 3.60671L11.7267 5.33337H10.6667C10.4899 5.33337 10.3203 5.26314 10.1953 5.13811C10.0702 5.01309 10 4.84352 10 4.66671V3.60671ZM12.6667 10C12.6667 10.1769 12.5964 10.3464 12.4714 10.4714C12.3464 10.5965 12.1768 10.6667 12 10.6667H6.66667C6.48986 10.6667 6.32029 10.5965 6.19526 10.4714C6.07024 10.3464 6 10.1769 6 10V3.33337C6 3.15656 6.07024 2.98699 6.19526 2.86197C6.32029 2.73695 6.48986 2.66671 6.66667 2.66671H8.66667V4.66671C8.66667 5.19714 8.87738 5.70585 9.25245 6.08092C9.62753 6.45599 10.1362 6.66671 10.6667 6.66671H12.6667V10Z" fill="#8B8D97"/>
                    </svg>
                </div>
               
                <div className="status"><div className="text-wrapper-8">Active</div></div>
                <div className="text-wrapper-9">Service 5</div>
              </div>
              <div className="group-9">
                <div className="div-2">  <label className="custom-checkbox2">
                  <input className="group" type="checkbox" />
                  </label>
                  <span className="checkmark2"></span></div>
                <div className="group-3">
                  <div className="text-wrapper-7">$56</div>
    <svg className="u-copy-alt" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M10.6667 13.3334H5.33333C4.8029 13.3334 4.29419 13.1227 3.91912 12.7476C3.54405 12.3725 3.33333 11.8638 3.33333 11.3334V4.66671C3.33333 4.4899 3.2631 4.32033 3.13807 4.1953C3.01305 4.07028 2.84348 4.00004 2.66667 4.00004C2.48986 4.00004 2.32029 4.07028 2.19526 4.1953C2.07024 4.32033 2 4.4899 2 4.66671V11.3334C2 12.2174 2.35119 13.0653 2.97631 13.6904C3.60143 14.3155 4.44928 14.6667 5.33333 14.6667H10.6667C10.8435 14.6667 11.013 14.5965 11.1381 14.4714C11.2631 14.3464 11.3333 14.1769 11.3333 14C11.3333 13.8232 11.2631 13.6537 11.1381 13.5286C11.013 13.4036 10.8435 13.3334 10.6667 13.3334ZM14 5.96004C13.9931 5.8988 13.9796 5.83846 13.96 5.78004V5.72004C13.9279 5.65149 13.8852 5.58848 13.8333 5.53337V5.53337L9.83333 1.53337C9.77822 1.48152 9.71521 1.43876 9.64667 1.40671H9.58667L9.37333 1.33337H6.66667C6.13623 1.33337 5.62753 1.54409 5.25245 1.91916C4.87738 2.29423 4.66667 2.80294 4.66667 3.33337V10C4.66667 10.5305 4.87738 11.0392 5.25245 11.4143C5.62753 11.7893 6.13623 12 6.66667 12H12C12.5304 12 13.0391 11.7893 13.4142 11.4143C13.7893 11.0392 14 10.5305 14 10V6.00004C14 6.00004 14 6.00004 14 5.96004ZM10 3.60671L11.7267 5.33337H10.6667C10.4899 5.33337 10.3203 5.26314 10.1953 5.13811C10.0702 5.01309 10 4.84352 10 4.66671V3.60671ZM12.6667 10C12.6667 10.1769 12.5964 10.3464 12.4714 10.4714C12.3464 10.5965 12.1768 10.6667 12 10.6667H6.66667C6.48986 10.6667 6.32029 10.5965 6.19526 10.4714C6.07024 10.3464 6 10.1769 6 10V3.33337C6 3.15656 6.07024 2.98699 6.19526 2.86197C6.32029 2.73695 6.48986 2.66671 6.66667 2.66671H8.66667V4.66671C8.66667 5.19714 8.87738 5.70585 9.25245 6.08092C9.62753 6.45599 10.1362 6.66671 10.6667 6.66671H12.6667V10Z" fill="#8B8D97"/>
                    </svg>
                  <svg className="u-copy-alt" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M10.6667 13.3334H5.33333C4.8029 13.3334 4.29419 13.1227 3.91912 12.7476C3.54405 12.3725 3.33333 11.8638 3.33333 11.3334V4.66671C3.33333 4.4899 3.2631 4.32033 3.13807 4.1953C3.01305 4.07028 2.84348 4.00004 2.66667 4.00004C2.48986 4.00004 2.32029 4.07028 2.19526 4.1953C2.07024 4.32033 2 4.4899 2 4.66671V11.3334C2 12.2174 2.35119 13.0653 2.97631 13.6904C3.60143 14.3155 4.44928 14.6667 5.33333 14.6667H10.6667C10.8435 14.6667 11.013 14.5965 11.1381 14.4714C11.2631 14.3464 11.3333 14.1769 11.3333 14C11.3333 13.8232 11.2631 13.6537 11.1381 13.5286C11.013 13.4036 10.8435 13.3334 10.6667 13.3334ZM14 5.96004C13.9931 5.8988 13.9796 5.83846 13.96 5.78004V5.72004C13.9279 5.65149 13.8852 5.58848 13.8333 5.53337V5.53337L9.83333 1.53337C9.77822 1.48152 9.71521 1.43876 9.64667 1.40671H9.58667L9.37333 1.33337H6.66667C6.13623 1.33337 5.62753 1.54409 5.25245 1.91916C4.87738 2.29423 4.66667 2.80294 4.66667 3.33337V10C4.66667 10.5305 4.87738 11.0392 5.25245 11.4143C5.62753 11.7893 6.13623 12 6.66667 12H12C12.5304 12 13.0391 11.7893 13.4142 11.4143C13.7893 11.0392 14 10.5305 14 10V6.00004C14 6.00004 14 6.00004 14 5.96004ZM10 3.60671L11.7267 5.33337H10.6667C10.4899 5.33337 10.3203 5.26314 10.1953 5.13811C10.0702 5.01309 10 4.84352 10 4.66671V3.60671ZM12.6667 10C12.6667 10.1769 12.5964 10.3464 12.4714 10.4714C12.3464 10.5965 12.1768 10.6667 12 10.6667H6.66667C6.48986 10.6667 6.32029 10.5965 6.19526 10.4714C6.07024 10.3464 6 10.1769 6 10V3.33337C6 3.15656 6.07024 2.98699 6.19526 2.86197C6.32029 2.73695 6.48986 2.66671 6.66667 2.66671H8.66667V4.66671C8.66667 5.19714 8.87738 5.70585 9.25245 6.08092C9.62753 6.45599 10.1362 6.66671 10.6667 6.66671H12.6667V10Z" fill="#8B8D97"/>
                    </svg>
                </div>
                <div className="group-4">
                  <div className="text-wrapper-7">84</div>
                  <svg className="u-copy-alt-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M10.6667 13.3334H5.33333C4.8029 13.3334 4.29419 13.1227 3.91912 12.7476C3.54405 12.3725 3.33333 11.8638 3.33333 11.3334V4.66671C3.33333 4.4899 3.2631 4.32033 3.13807 4.1953C3.01305 4.07028 2.84348 4.00004 2.66667 4.00004C2.48986 4.00004 2.32029 4.07028 2.19526 4.1953C2.07024 4.32033 2 4.4899 2 4.66671V11.3334C2 12.2174 2.35119 13.0653 2.97631 13.6904C3.60143 14.3155 4.44928 14.6667 5.33333 14.6667H10.6667C10.8435 14.6667 11.013 14.5965 11.1381 14.4714C11.2631 14.3464 11.3333 14.1769 11.3333 14C11.3333 13.8232 11.2631 13.6537 11.1381 13.5286C11.013 13.4036 10.8435 13.3334 10.6667 13.3334ZM14 5.96004C13.9931 5.8988 13.9796 5.83846 13.96 5.78004V5.72004C13.9279 5.65149 13.8852 5.58848 13.8333 5.53337V5.53337L9.83333 1.53337C9.77822 1.48152 9.71521 1.43876 9.64667 1.40671H9.58667L9.37333 1.33337H6.66667C6.13623 1.33337 5.62753 1.54409 5.25245 1.91916C4.87738 2.29423 4.66667 2.80294 4.66667 3.33337V10C4.66667 10.5305 4.87738 11.0392 5.25245 11.4143C5.62753 11.7893 6.13623 12 6.66667 12H12C12.5304 12 13.0391 11.7893 13.4142 11.4143C13.7893 11.0392 14 10.5305 14 10V6.00004C14 6.00004 14 6.00004 14 5.96004ZM10 3.60671L11.7267 5.33337H10.6667C10.4899 5.33337 10.3203 5.26314 10.1953 5.13811C10.0702 5.01309 10 4.84352 10 4.66671V3.60671ZM12.6667 10C12.6667 10.1769 12.5964 10.3464 12.4714 10.4714C12.3464 10.5965 12.1768 10.6667 12 10.6667H6.66667C6.48986 10.6667 6.32029 10.5965 6.19526 10.4714C6.07024 10.3464 6 10.1769 6 10V3.33337C6 3.15656 6.07024 2.98699 6.19526 2.86197C6.32029 2.73695 6.48986 2.66671 6.66667 2.66671H8.66667V4.66671C8.66667 5.19714 8.87738 5.70585 9.25245 6.08092C9.62753 6.45599 10.1362 6.66671 10.6667 6.66671H12.6667V10Z" fill="#8B8D97"/>
                    </svg>
                </div>
                <div className="status"><div className="text-wrapper-8">Active</div></div>
                <div className="text-wrapper-9">Service 6</div>
              </div>
              <div className="group-10">
                <div className="div-2">  <label className="custom-checkbox2">
                  <input className="group" type="checkbox" />
                  </label>
                  <span className="checkmark2"></span></div>
                <div className="group-11">
                  <div className="text-wrapper-7">$545</div>
                   <svg className="u-copy-alt" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M10.6667 13.3334H5.33333C4.8029 13.3334 4.29419 13.1227 3.91912 12.7476C3.54405 12.3725 3.33333 11.8638 3.33333 11.3334V4.66671C3.33333 4.4899 3.2631 4.32033 3.13807 4.1953C3.01305 4.07028 2.84348 4.00004 2.66667 4.00004C2.48986 4.00004 2.32029 4.07028 2.19526 4.1953C2.07024 4.32033 2 4.4899 2 4.66671V11.3334C2 12.2174 2.35119 13.0653 2.97631 13.6904C3.60143 14.3155 4.44928 14.6667 5.33333 14.6667H10.6667C10.8435 14.6667 11.013 14.5965 11.1381 14.4714C11.2631 14.3464 11.3333 14.1769 11.3333 14C11.3333 13.8232 11.2631 13.6537 11.1381 13.5286C11.013 13.4036 10.8435 13.3334 10.6667 13.3334ZM14 5.96004C13.9931 5.8988 13.9796 5.83846 13.96 5.78004V5.72004C13.9279 5.65149 13.8852 5.58848 13.8333 5.53337V5.53337L9.83333 1.53337C9.77822 1.48152 9.71521 1.43876 9.64667 1.40671H9.58667L9.37333 1.33337H6.66667C6.13623 1.33337 5.62753 1.54409 5.25245 1.91916C4.87738 2.29423 4.66667 2.80294 4.66667 3.33337V10C4.66667 10.5305 4.87738 11.0392 5.25245 11.4143C5.62753 11.7893 6.13623 12 6.66667 12H12C12.5304 12 13.0391 11.7893 13.4142 11.4143C13.7893 11.0392 14 10.5305 14 10V6.00004C14 6.00004 14 6.00004 14 5.96004ZM10 3.60671L11.7267 5.33337H10.6667C10.4899 5.33337 10.3203 5.26314 10.1953 5.13811C10.0702 5.01309 10 4.84352 10 4.66671V3.60671ZM12.6667 10C12.6667 10.1769 12.5964 10.3464 12.4714 10.4714C12.3464 10.5965 12.1768 10.6667 12 10.6667H6.66667C6.48986 10.6667 6.32029 10.5965 6.19526 10.4714C6.07024 10.3464 6 10.1769 6 10V3.33337C6 3.15656 6.07024 2.98699 6.19526 2.86197C6.32029 2.73695 6.48986 2.66671 6.66667 2.66671H8.66667V4.66671C8.66667 5.19714 8.87738 5.70585 9.25245 6.08092C9.62753 6.45599 10.1362 6.66671 10.6667 6.66671H12.6667V10Z" fill="#8B8D97"/>
                    </svg>
                </div>
                <div className="group-4">
                  <div className="text-wrapper-7">46</div>
                   <svg className="u-copy-alt-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M10.6667 13.3334H5.33333C4.8029 13.3334 4.29419 13.1227 3.91912 12.7476C3.54405 12.3725 3.33333 11.8638 3.33333 11.3334V4.66671C3.33333 4.4899 3.2631 4.32033 3.13807 4.1953C3.01305 4.07028 2.84348 4.00004 2.66667 4.00004C2.48986 4.00004 2.32029 4.07028 2.19526 4.1953C2.07024 4.32033 2 4.4899 2 4.66671V11.3334C2 12.2174 2.35119 13.0653 2.97631 13.6904C3.60143 14.3155 4.44928 14.6667 5.33333 14.6667H10.6667C10.8435 14.6667 11.013 14.5965 11.1381 14.4714C11.2631 14.3464 11.3333 14.1769 11.3333 14C11.3333 13.8232 11.2631 13.6537 11.1381 13.5286C11.013 13.4036 10.8435 13.3334 10.6667 13.3334ZM14 5.96004C13.9931 5.8988 13.9796 5.83846 13.96 5.78004V5.72004C13.9279 5.65149 13.8852 5.58848 13.8333 5.53337V5.53337L9.83333 1.53337C9.77822 1.48152 9.71521 1.43876 9.64667 1.40671H9.58667L9.37333 1.33337H6.66667C6.13623 1.33337 5.62753 1.54409 5.25245 1.91916C4.87738 2.29423 4.66667 2.80294 4.66667 3.33337V10C4.66667 10.5305 4.87738 11.0392 5.25245 11.4143C5.62753 11.7893 6.13623 12 6.66667 12H12C12.5304 12 13.0391 11.7893 13.4142 11.4143C13.7893 11.0392 14 10.5305 14 10V6.00004C14 6.00004 14 6.00004 14 5.96004ZM10 3.60671L11.7267 5.33337H10.6667C10.4899 5.33337 10.3203 5.26314 10.1953 5.13811C10.0702 5.01309 10 4.84352 10 4.66671V3.60671ZM12.6667 10C12.6667 10.1769 12.5964 10.3464 12.4714 10.4714C12.3464 10.5965 12.1768 10.6667 12 10.6667H6.66667C6.48986 10.6667 6.32029 10.5965 6.19526 10.4714C6.07024 10.3464 6 10.1769 6 10V3.33337C6 3.15656 6.07024 2.98699 6.19526 2.86197C6.32029 2.73695 6.48986 2.66671 6.66667 2.66671H8.66667V4.66671C8.66667 5.19714 8.87738 5.70585 9.25245 6.08092C9.62753 6.45599 10.1362 6.66671 10.6667 6.66671H12.6667V10Z" fill="#8B8D97"/>
                    </svg>
                </div>
                <div className="status"><div className="text-wrapper-8">Active</div></div>
                <div className="text-wrapper-9">Service 7</div>
              </div>
              <div className="group-12">
                <div className="div-2">  <label className="custom-checkbox2">
                  <input className="group" type="checkbox" />
                  </label>
                  <span className="checkmark2"></span></div>
                <div className="group-13">
                  <div className="text-wrapper-7">$6456</div>
                   <svg className="u-copy-alt" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M10.6667 13.3334H5.33333C4.8029 13.3334 4.29419 13.1227 3.91912 12.7476C3.54405 12.3725 3.33333 11.8638 3.33333 11.3334V4.66671C3.33333 4.4899 3.2631 4.32033 3.13807 4.1953C3.01305 4.07028 2.84348 4.00004 2.66667 4.00004C2.48986 4.00004 2.32029 4.07028 2.19526 4.1953C2.07024 4.32033 2 4.4899 2 4.66671V11.3334C2 12.2174 2.35119 13.0653 2.97631 13.6904C3.60143 14.3155 4.44928 14.6667 5.33333 14.6667H10.6667C10.8435 14.6667 11.013 14.5965 11.1381 14.4714C11.2631 14.3464 11.3333 14.1769 11.3333 14C11.3333 13.8232 11.2631 13.6537 11.1381 13.5286C11.013 13.4036 10.8435 13.3334 10.6667 13.3334ZM14 5.96004C13.9931 5.8988 13.9796 5.83846 13.96 5.78004V5.72004C13.9279 5.65149 13.8852 5.58848 13.8333 5.53337V5.53337L9.83333 1.53337C9.77822 1.48152 9.71521 1.43876 9.64667 1.40671H9.58667L9.37333 1.33337H6.66667C6.13623 1.33337 5.62753 1.54409 5.25245 1.91916C4.87738 2.29423 4.66667 2.80294 4.66667 3.33337V10C4.66667 10.5305 4.87738 11.0392 5.25245 11.4143C5.62753 11.7893 6.13623 12 6.66667 12H12C12.5304 12 13.0391 11.7893 13.4142 11.4143C13.7893 11.0392 14 10.5305 14 10V6.00004C14 6.00004 14 6.00004 14 5.96004ZM10 3.60671L11.7267 5.33337H10.6667C10.4899 5.33337 10.3203 5.26314 10.1953 5.13811C10.0702 5.01309 10 4.84352 10 4.66671V3.60671ZM12.6667 10C12.6667 10.1769 12.5964 10.3464 12.4714 10.4714C12.3464 10.5965 12.1768 10.6667 12 10.6667H6.66667C6.48986 10.6667 6.32029 10.5965 6.19526 10.4714C6.07024 10.3464 6 10.1769 6 10V3.33337C6 3.15656 6.07024 2.98699 6.19526 2.86197C6.32029 2.73695 6.48986 2.66671 6.66667 2.66671H8.66667V4.66671C8.66667 5.19714 8.87738 5.70585 9.25245 6.08092C9.62753 6.45599 10.1362 6.66671 10.6667 6.66671H12.6667V10Z" fill="#8B8D97"/>
                    </svg>
                </div>
                <div className="group-4">
                  <div className="text-wrapper-7">87</div>
                   <svg className="u-copy-alt-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M10.6667 13.3334H5.33333C4.8029 13.3334 4.29419 13.1227 3.91912 12.7476C3.54405 12.3725 3.33333 11.8638 3.33333 11.3334V4.66671C3.33333 4.4899 3.2631 4.32033 3.13807 4.1953C3.01305 4.07028 2.84348 4.00004 2.66667 4.00004C2.48986 4.00004 2.32029 4.07028 2.19526 4.1953C2.07024 4.32033 2 4.4899 2 4.66671V11.3334C2 12.2174 2.35119 13.0653 2.97631 13.6904C3.60143 14.3155 4.44928 14.6667 5.33333 14.6667H10.6667C10.8435 14.6667 11.013 14.5965 11.1381 14.4714C11.2631 14.3464 11.3333 14.1769 11.3333 14C11.3333 13.8232 11.2631 13.6537 11.1381 13.5286C11.013 13.4036 10.8435 13.3334 10.6667 13.3334ZM14 5.96004C13.9931 5.8988 13.9796 5.83846 13.96 5.78004V5.72004C13.9279 5.65149 13.8852 5.58848 13.8333 5.53337V5.53337L9.83333 1.53337C9.77822 1.48152 9.71521 1.43876 9.64667 1.40671H9.58667L9.37333 1.33337H6.66667C6.13623 1.33337 5.62753 1.54409 5.25245 1.91916C4.87738 2.29423 4.66667 2.80294 4.66667 3.33337V10C4.66667 10.5305 4.87738 11.0392 5.25245 11.4143C5.62753 11.7893 6.13623 12 6.66667 12H12C12.5304 12 13.0391 11.7893 13.4142 11.4143C13.7893 11.0392 14 10.5305 14 10V6.00004C14 6.00004 14 6.00004 14 5.96004ZM10 3.60671L11.7267 5.33337H10.6667C10.4899 5.33337 10.3203 5.26314 10.1953 5.13811C10.0702 5.01309 10 4.84352 10 4.66671V3.60671ZM12.6667 10C12.6667 10.1769 12.5964 10.3464 12.4714 10.4714C12.3464 10.5965 12.1768 10.6667 12 10.6667H6.66667C6.48986 10.6667 6.32029 10.5965 6.19526 10.4714C6.07024 10.3464 6 10.1769 6 10V3.33337C6 3.15656 6.07024 2.98699 6.19526 2.86197C6.32029 2.73695 6.48986 2.66671 6.66667 2.66671H8.66667V4.66671C8.66667 5.19714 8.87738 5.70585 9.25245 6.08092C9.62753 6.45599 10.1362 6.66671 10.6667 6.66671H12.6667V10Z" fill="#8B8D97"/>
                    </svg>
                </div>
                <div className="status"><div className="text-wrapper-8">Active</div></div>
                <div className="text-wrapper-9">Service 8</div>
              </div>
              <div className="group-14">
                <div className="div-2">  <label className="custom-checkbox2">
                  <input className="group" type="checkbox" />
                  </label>
                  <span className="checkmark2"></span></div>
                <div className="group-11">
                  <div className="text-wrapper-7">$5446</div>
                   <svg className="u-copy-alt-3" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M10.6667 13.3334H5.33333C4.8029 13.3334 4.29419 13.1227 3.91912 12.7476C3.54405 12.3725 3.33333 11.8638 3.33333 11.3334V4.66671C3.33333 4.4899 3.2631 4.32033 3.13807 4.1953C3.01305 4.07028 2.84348 4.00004 2.66667 4.00004C2.48986 4.00004 2.32029 4.07028 2.19526 4.1953C2.07024 4.32033 2 4.4899 2 4.66671V11.3334C2 12.2174 2.35119 13.0653 2.97631 13.6904C3.60143 14.3155 4.44928 14.6667 5.33333 14.6667H10.6667C10.8435 14.6667 11.013 14.5965 11.1381 14.4714C11.2631 14.3464 11.3333 14.1769 11.3333 14C11.3333 13.8232 11.2631 13.6537 11.1381 13.5286C11.013 13.4036 10.8435 13.3334 10.6667 13.3334ZM14 5.96004C13.9931 5.8988 13.9796 5.83846 13.96 5.78004V5.72004C13.9279 5.65149 13.8852 5.58848 13.8333 5.53337V5.53337L9.83333 1.53337C9.77822 1.48152 9.71521 1.43876 9.64667 1.40671H9.58667L9.37333 1.33337H6.66667C6.13623 1.33337 5.62753 1.54409 5.25245 1.91916C4.87738 2.29423 4.66667 2.80294 4.66667 3.33337V10C4.66667 10.5305 4.87738 11.0392 5.25245 11.4143C5.62753 11.7893 6.13623 12 6.66667 12H12C12.5304 12 13.0391 11.7893 13.4142 11.4143C13.7893 11.0392 14 10.5305 14 10V6.00004C14 6.00004 14 6.00004 14 5.96004ZM10 3.60671L11.7267 5.33337H10.6667C10.4899 5.33337 10.3203 5.26314 10.1953 5.13811C10.0702 5.01309 10 4.84352 10 4.66671V3.60671ZM12.6667 10C12.6667 10.1769 12.5964 10.3464 12.4714 10.4714C12.3464 10.5965 12.1768 10.6667 12 10.6667H6.66667C6.48986 10.6667 6.32029 10.5965 6.19526 10.4714C6.07024 10.3464 6 10.1769 6 10V3.33337C6 3.15656 6.07024 2.98699 6.19526 2.86197C6.32029 2.73695 6.48986 2.66671 6.66667 2.66671H8.66667V4.66671C8.66667 5.19714 8.87738 5.70585 9.25245 6.08092C9.62753 6.45599 10.1362 6.66671 10.6667 6.66671H12.6667V10Z" fill="#8B8D97"/>
                    </svg>
                </div>
                <div className="group-4">
                  <div className="text-wrapper-7">66</div>
                   <svg className="u-copy-alt-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M10.6667 13.3334H5.33333C4.8029 13.3334 4.29419 13.1227 3.91912 12.7476C3.54405 12.3725 3.33333 11.8638 3.33333 11.3334V4.66671C3.33333 4.4899 3.2631 4.32033 3.13807 4.1953C3.01305 4.07028 2.84348 4.00004 2.66667 4.00004C2.48986 4.00004 2.32029 4.07028 2.19526 4.1953C2.07024 4.32033 2 4.4899 2 4.66671V11.3334C2 12.2174 2.35119 13.0653 2.97631 13.6904C3.60143 14.3155 4.44928 14.6667 5.33333 14.6667H10.6667C10.8435 14.6667 11.013 14.5965 11.1381 14.4714C11.2631 14.3464 11.3333 14.1769 11.3333 14C11.3333 13.8232 11.2631 13.6537 11.1381 13.5286C11.013 13.4036 10.8435 13.3334 10.6667 13.3334ZM14 5.96004C13.9931 5.8988 13.9796 5.83846 13.96 5.78004V5.72004C13.9279 5.65149 13.8852 5.58848 13.8333 5.53337V5.53337L9.83333 1.53337C9.77822 1.48152 9.71521 1.43876 9.64667 1.40671H9.58667L9.37333 1.33337H6.66667C6.13623 1.33337 5.62753 1.54409 5.25245 1.91916C4.87738 2.29423 4.66667 2.80294 4.66667 3.33337V10C4.66667 10.5305 4.87738 11.0392 5.25245 11.4143C5.62753 11.7893 6.13623 12 6.66667 12H12C12.5304 12 13.0391 11.7893 13.4142 11.4143C13.7893 11.0392 14 10.5305 14 10V6.00004C14 6.00004 14 6.00004 14 5.96004ZM10 3.60671L11.7267 5.33337H10.6667C10.4899 5.33337 10.3203 5.26314 10.1953 5.13811C10.0702 5.01309 10 4.84352 10 4.66671V3.60671ZM12.6667 10C12.6667 10.1769 12.5964 10.3464 12.4714 10.4714C12.3464 10.5965 12.1768 10.6667 12 10.6667H6.66667C6.48986 10.6667 6.32029 10.5965 6.19526 10.4714C6.07024 10.3464 6 10.1769 6 10V3.33337C6 3.15656 6.07024 2.98699 6.19526 2.86197C6.32029 2.73695 6.48986 2.66671 6.66667 2.66671H8.66667V4.66671C8.66667 5.19714 8.87738 5.70585 9.25245 6.08092C9.62753 6.45599 10.1362 6.66671 10.6667 6.66671H12.6667V10Z" fill="#8B8D97"/>
                    </svg>
                </div>
                <div className="status"><div className="text-wrapper-8">Active</div></div>
                <div className="text-wrapper-9">Service 9</div>
              </div>
              <div className="group-15">
                <div className="div-2">  <label className="custom-checkbox2">
                  <input className="group" type="checkbox" />
                  </label>
                  <span className="checkmark2"></span></div>
                <div className="group-3">
                  <div className="text-wrapper-7">$546</div>
                   <svg className="u-copy-alt" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M10.6667 13.3334H5.33333C4.8029 13.3334 4.29419 13.1227 3.91912 12.7476C3.54405 12.3725 3.33333 11.8638 3.33333 11.3334V4.66671C3.33333 4.4899 3.2631 4.32033 3.13807 4.1953C3.01305 4.07028 2.84348 4.00004 2.66667 4.00004C2.48986 4.00004 2.32029 4.07028 2.19526 4.1953C2.07024 4.32033 2 4.4899 2 4.66671V11.3334C2 12.2174 2.35119 13.0653 2.97631 13.6904C3.60143 14.3155 4.44928 14.6667 5.33333 14.6667H10.6667C10.8435 14.6667 11.013 14.5965 11.1381 14.4714C11.2631 14.3464 11.3333 14.1769 11.3333 14C11.3333 13.8232 11.2631 13.6537 11.1381 13.5286C11.013 13.4036 10.8435 13.3334 10.6667 13.3334ZM14 5.96004C13.9931 5.8988 13.9796 5.83846 13.96 5.78004V5.72004C13.9279 5.65149 13.8852 5.58848 13.8333 5.53337V5.53337L9.83333 1.53337C9.77822 1.48152 9.71521 1.43876 9.64667 1.40671H9.58667L9.37333 1.33337H6.66667C6.13623 1.33337 5.62753 1.54409 5.25245 1.91916C4.87738 2.29423 4.66667 2.80294 4.66667 3.33337V10C4.66667 10.5305 4.87738 11.0392 5.25245 11.4143C5.62753 11.7893 6.13623 12 6.66667 12H12C12.5304 12 13.0391 11.7893 13.4142 11.4143C13.7893 11.0392 14 10.5305 14 10V6.00004C14 6.00004 14 6.00004 14 5.96004ZM10 3.60671L11.7267 5.33337H10.6667C10.4899 5.33337 10.3203 5.26314 10.1953 5.13811C10.0702 5.01309 10 4.84352 10 4.66671V3.60671ZM12.6667 10C12.6667 10.1769 12.5964 10.3464 12.4714 10.4714C12.3464 10.5965 12.1768 10.6667 12 10.6667H6.66667C6.48986 10.6667 6.32029 10.5965 6.19526 10.4714C6.07024 10.3464 6 10.1769 6 10V3.33337C6 3.15656 6.07024 2.98699 6.19526 2.86197C6.32029 2.73695 6.48986 2.66671 6.66667 2.66671H8.66667V4.66671C8.66667 5.19714 8.87738 5.70585 9.25245 6.08092C9.62753 6.45599 10.1362 6.66671 10.6667 6.66671H12.6667V10Z" fill="#8B8D97"/>
                    </svg>
                </div>
                <div className="group-4">
                  <div className="text-wrapper-7">64</div>
                   <svg className="u-copy-alt-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M10.6667 13.3334H5.33333C4.8029 13.3334 4.29419 13.1227 3.91912 12.7476C3.54405 12.3725 3.33333 11.8638 3.33333 11.3334V4.66671C3.33333 4.4899 3.2631 4.32033 3.13807 4.1953C3.01305 4.07028 2.84348 4.00004 2.66667 4.00004C2.48986 4.00004 2.32029 4.07028 2.19526 4.1953C2.07024 4.32033 2 4.4899 2 4.66671V11.3334C2 12.2174 2.35119 13.0653 2.97631 13.6904C3.60143 14.3155 4.44928 14.6667 5.33333 14.6667H10.6667C10.8435 14.6667 11.013 14.5965 11.1381 14.4714C11.2631 14.3464 11.3333 14.1769 11.3333 14C11.3333 13.8232 11.2631 13.6537 11.1381 13.5286C11.013 13.4036 10.8435 13.3334 10.6667 13.3334ZM14 5.96004C13.9931 5.8988 13.9796 5.83846 13.96 5.78004V5.72004C13.9279 5.65149 13.8852 5.58848 13.8333 5.53337V5.53337L9.83333 1.53337C9.77822 1.48152 9.71521 1.43876 9.64667 1.40671H9.58667L9.37333 1.33337H6.66667C6.13623 1.33337 5.62753 1.54409 5.25245 1.91916C4.87738 2.29423 4.66667 2.80294 4.66667 3.33337V10C4.66667 10.5305 4.87738 11.0392 5.25245 11.4143C5.62753 11.7893 6.13623 12 6.66667 12H12C12.5304 12 13.0391 11.7893 13.4142 11.4143C13.7893 11.0392 14 10.5305 14 10V6.00004C14 6.00004 14 6.00004 14 5.96004ZM10 3.60671L11.7267 5.33337H10.6667C10.4899 5.33337 10.3203 5.26314 10.1953 5.13811C10.0702 5.01309 10 4.84352 10 4.66671V3.60671ZM12.6667 10C12.6667 10.1769 12.5964 10.3464 12.4714 10.4714C12.3464 10.5965 12.1768 10.6667 12 10.6667H6.66667C6.48986 10.6667 6.32029 10.5965 6.19526 10.4714C6.07024 10.3464 6 10.1769 6 10V3.33337C6 3.15656 6.07024 2.98699 6.19526 2.86197C6.32029 2.73695 6.48986 2.66671 6.66667 2.66671H8.66667V4.66671C8.66667 5.19714 8.87738 5.70585 9.25245 6.08092C9.62753 6.45599 10.1362 6.66671 10.6667 6.66671H12.6667V10Z" fill="#8B8D97"/>
                    </svg>
                </div>
                <div className="status"><div className="text-wrapper-8">Active</div></div>
                <div className="text-wrapper-9">Service 10</div>
              </div>
            </div>
            <div className="search-filter-share">
              <div className="frame-8">
                <div className="left">
                  <div className="input-content">
             
                    <svg
                    className="iconly-light-search"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                  >
                    <circle
                      cx="9.80549"
                      cy="10.3055"
                      r="7.49047"
                      stroke="#130F26"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M15.0153 15.9043L17.9519 18.8333"
                      stroke="#130F26"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                    
                    <div className="div-wrapper"><div className="text-wrapper-10">
                      <input
                            className="text-wrappers"
                            type="text"
                            placeholder="Search"
                          />



                    </div></div>
                  </div>
                </div>
                <div className="right">
                  <button className="table-buttons">
                    <div className="label-container">
                      <svg
                      className="img"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                    >
                      <path
                        d="M14.6667 2.5H1.33333L6.66666 8.80667V13.1667L9.33333 14.5V8.80667L14.6667 2.5Z"
                        stroke="#53545C"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                      

                      
                      <div className="text-wrapper-11">Filter</div>
                    </div>
                  </button>
                  <button className="table-buttons">
                    <div className="label-containers">
                     
              
                      <input type="date" id="calendar" name="calendar" />

                      <div className="text-wrapper-11">Filter</div>
                    </div>
                  </button>
                  <button className="table-buttons">
                    <div className="label-container">
  
                      <svg
                      className="img"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                    >
                      <path
                        d="M10.555 5.94976L6.73936 9.80612L2.39962 7.09178C1.77783 6.70276 1.90718 5.75829 2.61048 5.55262L12.9142 2.53518C13.5582 2.34642 14.155 2.94855 13.9637 3.59466L10.9154 13.8912C10.7066 14.5955 9.76747 14.7213 9.38214 14.0968L6.73734 9.8068"
                        stroke="#53545C"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>


                      <div className="text-wrapper-11">Share</div>
                    </div>
                  </button>
                  <div className="label-container-wrapper">
                    <div className="label-container">
                      <div className="text-wrapper-11">Bulk Action</div>

                   
                      <svg
                      className="img"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                    >
                      <path
                        d="M4 6.5L8 10.5L12 6.5"
                        stroke="#53545C"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    </div>
                    <div className="dropdown-1">
                      <ul>
                        <li>
                          <input type="checkbox" id="selectAll" />
                          <label for="selectAll">Select all Services</label>
                        </li>
                        <li>
                          <input type="checkbox" id="selectActive" />
                          <label for="selectActive">Select active Services</label>
                        </li>
                        <li>
                          <input type="checkbox" id="selectInactive" />
                          <label for="selectInactive"
                            >Select inactive services</label
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <header className="header">
            <div className="text-wrapper-12">Summary</div>
            <a href="../services_form/index.html">
            <div className="buttons">
              <div className="label-container-2">
                <svg  className="fi-plus" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M12 5V19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M5 12H19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>

                    <div className="text-wrapper-13">Add a New Service</div>
                  </div>
                </div>
              </a>
          </header>
        </div>
        <div className="top-nav">
          <div className="nav-container-wrapper">
            <div className="nav-container">
              <div className="text-wrapper-14">Services</div>
              <div className="profile">
                <svg className="iconly-bulk"  xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M16.4746 9.70453C15.8658 8.99366 15.5892 8.37763 15.5892 7.33104V6.97519C15.5892 5.61136 15.2753 4.73264 14.5928 3.85391C13.541 2.48924 11.7703 1.66675 10.0368 1.66675H9.9631C8.26609 1.66675 6.55085 2.45147 5.48079 3.76075C4.76107 4.6571 4.41073 5.57359 4.41073 6.97519V7.33104C4.41073 8.37763 4.15233 8.99366 3.52537 9.70453C3.06405 10.2282 2.91663 10.9013 2.91663 11.6298C2.91663 12.3592 3.15598 13.0499 3.63635 13.6114C4.26331 14.2845 5.14867 14.7142 6.05309 14.7889C7.3625 14.9383 8.67191 14.9945 10.0004 14.9945C11.328 14.9945 12.6374 14.9005 13.9477 14.7889C14.8512 14.7142 15.7366 14.2845 16.3636 13.6114C16.8431 13.0499 17.0833 12.3592 17.0833 11.6298C17.0833 10.9013 16.9359 10.2282 16.4746 9.70453Z" fill="#DDDDDD"/>
                  <path opacity="0.4" d="M11.6738 16.0238C11.2572 15.9348 8.71876 15.9348 8.30217 16.0238C7.94603 16.106 7.56091 16.2974 7.56091 16.717C7.58162 17.1173 7.816 17.4707 8.14067 17.6948L8.13984 17.6956C8.55974 18.0229 9.05253 18.2311 9.56851 18.3058C9.84348 18.3435 10.1234 18.3418 10.4083 18.3058C10.9235 18.2311 11.4163 18.0229 11.8362 17.6956L11.8353 17.6948C12.16 17.4707 12.3944 17.1173 12.4151 16.717C12.4151 16.2974 12.03 16.106 11.6738 16.0238Z" fill="#DDDDDD"/>
                  </svg>
                <div className="profile-2"></div>
              </div>
            </div>
          </div>
          <div className="breadcrumbs">
            <div className="div-3">
              <a href="../dashboard/index.html">

                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path d="M6.09578 13.8547V11.8102C6.09577 11.2921 6.51707 10.8711 7.03892 10.8679H8.95503C9.47921 10.8679 9.90414 11.2898 9.90414 11.8102V13.8488C9.90413 14.2982 10.2693 14.6634 10.7219 14.6666H12.0292C12.6397 14.6682 13.2258 14.4285 13.6581 14.0005C14.0903 13.5724 14.3333 12.9912 14.3333 12.385V6.57723C14.3333 6.08759 14.1147 5.62314 13.7364 5.309L9.29528 1.78285C8.51897 1.16608 7.41021 1.18601 6.65689 1.83026L2.3113 5.309C1.91512 5.61388 1.67833 6.07971 1.66663 6.57723V12.3791C1.66663 13.6425 2.69821 14.6666 3.97074 14.6666H5.24815C5.46607 14.6682 5.67562 14.5834 5.83027 14.431C5.98493 14.2785 6.07191 14.0711 6.0719 13.8547H6.09578Z" fill="#434343"/>
                  </svg>     
              </a>
              <div className="div-3">
                <div className="text-wrapper-15">/</div>
                <div className="text-wrapper-15">Services</div>
              </div>
              <div className="item">
                <div className="text-wrapper-15">/</div>
                <div className="text-wrapper-15">Page</div>
              </div>
              <div className="item">
                <div className="text-wrapper-15">/</div>
                <div className="text-wrapper-15">Page</div>
              </div>
              <div className="item">
                <div className="text-wrapper-15">/</div>
                <div className="text-wrapper-15">Page</div>
              </div>
            </div>
          </div>
        </div>
       
    
    </div>
    )
  }
      
      
  export default Service;
  
