import React from "react";
import './globals.css';
import './style.css';
import './styleguide.css';

const Invoice=()=>{
  return( 
    <div class="invoice">
    
      <div class="main-container">
        <div class="top-nav">
          <div class="nav-container-wrapper">
            <div class="nav-container">
              <div class="text-wrapper">Invoices</div>
              <div class="profile">

                <svg class="iconly-bulk" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M16.4747 9.7044C15.8659 8.99354 15.5893 8.3775 15.5893 7.33092V6.97507C15.5893 5.61124 15.2754 4.73251 14.593 3.85379C13.5411 2.48912 11.7704 1.66663 10.0369 1.66663H9.96323C8.26621 1.66663 6.55097 2.45135 5.48091 3.76063C4.76119 4.65698 4.41085 5.57347 4.41085 6.97507V7.33092C4.41085 8.3775 4.15245 8.99354 3.52549 9.7044C3.06417 10.2281 2.91675 10.9012 2.91675 11.6297C2.91675 12.359 3.1561 13.0498 3.63647 13.6113C4.26343 14.2844 5.1488 14.7141 6.05321 14.7888C7.36262 14.9382 8.67203 14.9944 10.0005 14.9944C11.3281 14.9944 12.6375 14.9004 13.9478 14.7888C14.8514 14.7141 15.7367 14.2844 16.3637 13.6113C16.8432 13.0498 17.0834 12.359 17.0834 11.6297C17.0834 10.9012 16.936 10.2281 16.4747 9.7044Z" fill="#DDDDDD"/>
                  <path opacity="0.4" d="M11.674 16.0236C11.2574 15.9347 8.71888 15.9347 8.30229 16.0236C7.94616 16.1059 7.56104 16.2972 7.56104 16.7169C7.58174 17.1172 7.81613 17.4706 8.14079 17.6946L8.13996 17.6955C8.55987 18.0228 9.05266 18.2309 9.56864 18.3056C9.8436 18.3434 10.1235 18.3417 10.4084 18.3056C10.9236 18.2309 11.4164 18.0228 11.8363 17.6955L11.8355 17.6946C12.1601 17.4706 12.3945 17.1172 12.4152 16.7169C12.4152 16.2972 12.0301 16.1059 11.674 16.0236Z" fill="#DDDDDD"/>
                  </svg>
                <div class="profile-2"></div>
              </div>
            </div>
          </div>
          <div class="breadcrumbs">
            <div class="div-2">
             
              <a href="../dashboard/index.html">

                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path d="M6.09578 13.8547V11.8102C6.09577 11.2921 6.51707 10.8711 7.03892 10.8679H8.95503C9.47921 10.8679 9.90414 11.2898 9.90414 11.8102V13.8488C9.90413 14.2982 10.2693 14.6634 10.7219 14.6666H12.0292C12.6397 14.6682 13.2258 14.4285 13.6581 14.0005C14.0903 13.5724 14.3333 12.9912 14.3333 12.385V6.57723C14.3333 6.08759 14.1147 5.62314 13.7364 5.309L9.29528 1.78285C8.51897 1.16608 7.41021 1.18601 6.65689 1.83026L2.3113 5.309C1.91512 5.61388 1.67833 6.07971 1.66663 6.57723V12.3791C1.66663 13.6425 2.69821 14.6666 3.97074 14.6666H5.24815C5.46607 14.6682 5.67562 14.5834 5.83027 14.431C5.98493 14.2785 6.07191 14.0711 6.0719 13.8547H6.09578Z" fill="#434343"/>
                  </svg>     
              </a>
              <div class="div-2">
                <div class="text-wrapper-2">/</div>
                <div class="text-wrapper-2">Invoices</div>
              </div>
              <div class="item">
                <div class="text-wrapper-2">/</div>
                <div class="text-wrapper-2">Page</div>
              </div>
              <div class="item">
                <div class="text-wrapper-2">/</div>
                <div class="text-wrapper-2">Page</div>
              </div>
              <div class="item">
                <div class="text-wrapper-2">/</div>
                <div class="text-wrapper-2">Page</div>
              </div>
            </div>
          </div>
        </div>
        <header class="header">
          <div class="text-wrapper-3">Summary</div>
          <a href="../invoice2/index.html">
          <div class="buttons">
            <div class="label-container">
           
              <svg class="fi-plus" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 5V19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5 12H19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

                <div class="text-wrapper-4">Add a New Invoice</div>
              </div>
            </div>
          </a>
        </header>
        <div class="container">
          <div class="table">
            <div class="overlap-group">
              <div class="main-container-2">
                <div class="frame">
                  <div class="frame-2">
                    <div class="left">
                      <div class="input-content">
         
                        <svg class="iconly-light-search" xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                          <circle cx="9.80553" cy="10.3055" r="7.49047" stroke="#130F26" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M15.0153 15.9042L17.9519 18.8333" stroke="#130F26" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        <div class="div-wrapper">
                          <div class="text-wrapper-5">
                            <input
                          class="text-wrappers"
                          type="text"
                          placeholder="Search"
                        />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="right">
                      <button class="table-buttons">
                        <div class="label-container-2">

                          
                          <svg class="img" xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                            <path d="M14.6667 2.5H1.33337L6.66671 8.80667V13.1667L9.33337 14.5V8.80667L14.6667 2.5Z" stroke="#53545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                          <div class="text-wrapper-6">Filter</div>
                        </div>
                      </button>
                      <button class="table-buttons">

                        <div class="label-container-3">
 
                          <input type="date" id="calendar" name="calendar" />
                          <div class="text-wrapper-6">Filter</div>
                        </div>
                      </button>
                      <button class="table-buttons">
                        <div class="label-container-2">

                          <svg class="img" xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                            <path d="M10.555 5.94976L6.73936 9.80612L2.39962 7.09178C1.77783 6.70276 1.90718 5.75829 2.61048 5.55262L12.9142 2.53518C13.5582 2.34642 14.155 2.94855 13.9637 3.59466L10.9154 13.8912C10.7066 14.5955 9.76747 14.7213 9.38214 14.0968L6.73734 9.8068" stroke="#53545C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                          <div class="text-wrapper-6">Share</div>
                        </div>
                      </button>
                      <div class="label-container-wrapper">
                        <div class="label-container">
                          <div class="text-wrapper-6">Bulk Action</div>

                          <svg class="img" xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                            <path d="M4 6.5L8 10.5L12 6.5" stroke="#53545C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <div class="dropdown-1">
                          <ul>
                            <li>
                              <input type="checkbox" id="selectAll" />
                              <label for="selectAll">Select all Invoices</label>
                            </li>
                            <li>
                              <input type="checkbox" id="selectActive" />
                              <label for="selectActive">Select Sent</label>
                            </li>
                            <li>
                              <input type="checkbox" id="selectInactive" />
                              <label for="selectInactive"
                                >Select Paid</label
                              >
                            </li>
                            <li>
                              <input type="checkbox" id="selectInactive" />
                              <label for="selectInactive"
                                >Select Overdue</label
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="table-2">
                  <div class="table-header"><div class="group">
                    <label class="custom-checkbox2">
                      <input class="group" type="checkbox" />
                      </label>
                      <span class="checkmark2"></span>
                   
                  </div></div>
                  <div class="table-row-container">
                    <div class="table-row"><div class="col-contents"> <label class="custom-checkbox2">
                      <input class="group" type="checkbox" />
                      </label>
                      <span class="checkmark2"></span></div></div>
                    <div class="col-contents-wrapper"><div class="col-contents"> <label class="custom-checkbox2">
                      <input class="group" type="checkbox" />
                      </label>
                      <span class="checkmark2"></span></div></div>
                    <div class="table-row-2"><div class="col-contents"> <label class="custom-checkbox2">
                      <input class="group" type="checkbox" />
                      </label>
                      <span class="checkmark2"></span></div></div>
                    <div class="table-row-3"><div class="col-contents"> <label class="custom-checkbox2">
                      <input class="group" type="checkbox" />
                      </label>
                      <span class="checkmark2"></span></div></div>
                    <div class="table-row-4"><div class="col-contents"> <label class="custom-checkbox2">
                      <input class="group" type="checkbox" />
                      </label>
                      <span class="checkmark2"></span></div></div>
                    <div class="table-row-5"><div class="col-contents"> <label class="custom-checkbox2">
                      <input class="group" type="checkbox" />
                      </label>
                      <span class="checkmark2"></span></div></div>
                    <div class="table-row-6"><div class="col-contents"> <label class="custom-checkbox2">
                      <input class="group" type="checkbox" />
                      </label>
                      <span class="checkmark2"></span></div></div>
                    <div class="table-row-7"><div class="col-contents"> <label class="custom-checkbox2">
                      <input class="group" type="checkbox" />
                      </label>
                      <span class="checkmark2"></span></div></div>
                    <div class="table-row-8"><div class="col-contents"> <label class="custom-checkbox2">
                      <input class="group" type="checkbox" />
                      </label>
                      <span class="checkmark2"></span></div></div>
                    <div class="table-row-9"><div class="col-contents"> <label class="custom-checkbox2">
                      <input class="group" type="checkbox" />
                      </label>
                      <span class="checkmark2"></span></div></div>
                  </div>
                  <div class="table-header-2">
                    <div class="text-wrapper-7">Items per page</div>
                    <div class="text-wrapper-8">1-10 of 70 items</div>
                    <div class="frame-3">
                      <div class="icon">
                        <div class="frame-4">
                          <div class="text-wrapper-9">1</div>
    
                          <svg class="img"  xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                            <path d="M4 6.5L8 10.5L12 6.5" stroke="#8B8D97" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                      </div>
                      <div class="text-wrapper-10">of 7 pages</div>
                    </div>
                    <div class="frame-5">
 
                      <svg class="img" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M10.4716 11.5286C10.7319 11.789 10.7319 12.2111 10.4716 12.4714C10.2112 12.7318 9.78911 12.7318 9.52876 12.4714L5.52876 8.47142C5.27637 8.21904 5.26754 7.81265 5.50873 7.54954L9.17539 3.54954C9.42419 3.27812 9.8459 3.25979 10.1173 3.50858C10.3887 3.75738 10.4071 4.17909 10.1583 4.4505L6.92292 7.97997L10.4716 11.5286Z" fill="#666666"/>
                        </svg>
         
                      <svg class="img" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M5.52843 4.47138C5.26808 4.21103 5.26808 3.78892 5.52843 3.52858C5.78878 3.26823 6.21089 3.26823 6.47124 3.52858L10.4712 7.52858C10.7236 7.78096 10.7325 8.18735 10.4913 8.45046L6.82461 12.4505C6.57581 12.7219 6.1541 12.7402 5.88269 12.4914C5.61127 12.2426 5.59294 11.8209 5.84173 11.5495L9.07708 8.02003L5.52843 4.47138Z" fill="#666666"/>
                        </svg>
                    </div>
                    <div class="frame-wrapper">
                      <div class="frame-4">
                        <div class="text-wrapper-9">10</div>
       
                        <svg class="img" xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                          <path d="M4 6.5L8 10.5L12 6.5" stroke="#8B8D97" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="phone">
                <div class="frame-6">
                  <div class="text-wrapper-11">Invoice value</div>

                  <svg class="img" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M14 5.16669H2C1.72667 5.16669 1.5 4.94002 1.5 4.66669C1.5 4.39335 1.72667 4.16669 2 4.16669H14C14.2733 4.16669 14.5 4.39335 14.5 4.66669C14.5 4.94002 14.2733 5.16669 14 5.16669Z" fill="#00092E"/>
                    <path d="M12 8.5H4C3.72667 8.5 3.5 8.27333 3.5 8C3.5 7.72667 3.72667 7.5 4 7.5H12C12.2733 7.5 12.5 7.72667 12.5 8C12.5 8.27333 12.2733 8.5 12 8.5Z" fill="#00092E"/>
                    <path d="M9.33329 11.8333H6.66663C6.39329 11.8333 6.16663 11.6066 6.16663 11.3333C6.16663 11.06 6.39329 10.8333 6.66663 10.8333H9.33329C9.60663 10.8333 9.83329 11.06 9.83329 11.3333C9.83329 11.6066 9.60663 11.8333 9.33329 11.8333Z" fill="#00092E"/>
                    </svg>
                </div>
                <div class="group-2">
                  <div class="group-3">
                    <div class="text-wrapper-12">$4432</div>
                    
                    <svg class="u-copy-alt" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path d="M10.6667 13.3333H5.33333C4.8029 13.3333 4.29419 13.1226 3.91912 12.7475C3.54405 12.3725 3.33333 11.8637 3.33333 11.3333V4.66665C3.33333 4.48984 3.2631 4.32027 3.13807 4.19524C3.01305 4.07022 2.84348 3.99998 2.66667 3.99998C2.48986 3.99998 2.32029 4.07022 2.19526 4.19524C2.07024 4.32027 2 4.48984 2 4.66665V11.3333C2 12.2174 2.35119 13.0652 2.97631 13.6903C3.60143 14.3155 4.44928 14.6666 5.33333 14.6666H10.6667C10.8435 14.6666 11.013 14.5964 11.1381 14.4714C11.2631 14.3464 11.3333 14.1768 11.3333 14C11.3333 13.8232 11.2631 13.6536 11.1381 13.5286C11.013 13.4035 10.8435 13.3333 10.6667 13.3333ZM14 5.95998C13.9931 5.89874 13.9796 5.8384 13.96 5.77998V5.71998C13.9279 5.65143 13.8852 5.58842 13.8333 5.53331L9.83333 1.53331C9.77822 1.48146 9.71521 1.4387 9.64667 1.40665H9.58667L9.37333 1.33331H6.66667C6.13623 1.33331 5.62753 1.54403 5.25245 1.9191C4.87738 2.29417 4.66667 2.80288 4.66667 3.33331V9.99998C4.66667 10.5304 4.87738 11.0391 5.25245 11.4142C5.62753 11.7893 6.13623 12 6.66667 12H12C12.5304 12 13.0391 11.7893 13.4142 11.4142C13.7893 11.0391 14 10.5304 14 9.99998V5.99998C14 5.99998 14 5.99998 14 5.95998ZM10 3.60665L11.7267 5.33331H10.6667C10.4899 5.33331 10.3203 5.26307 10.1953 5.13805C10.0702 5.01303 10 4.84346 10 4.66665V3.60665ZM12.6667 9.99998C12.6667 10.1768 12.5964 10.3464 12.4714 10.4714C12.3464 10.5964 12.1768 10.6666 12 10.6666H6.66667C6.48986 10.6666 6.32029 10.5964 6.19526 10.4714C6.07024 10.3464 6 10.1768 6 9.99998V3.33331C6 3.1565 6.07024 2.98693 6.19526 2.86191C6.32029 2.73688 6.48986 2.66665 6.66667 2.66665H8.66667V4.66665C8.66667 5.19708 8.87738 5.70579 9.25245 6.08086C9.62753 6.45593 10.1362 6.66665 10.6667 6.66665H12.6667V9.99998Z" fill="#8B8D97"/>
                      </svg>
                  </div>
                  <div class="group-4">
                    <div class="text-wrapper-13">$5884</div>
                    
                    <svg class="u-copy-alt" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path d="M10.6667 13.3333H5.33333C4.8029 13.3333 4.29419 13.1226 3.91912 12.7475C3.54405 12.3725 3.33333 11.8637 3.33333 11.3333V4.66665C3.33333 4.48984 3.2631 4.32027 3.13807 4.19524C3.01305 4.07022 2.84348 3.99998 2.66667 3.99998C2.48986 3.99998 2.32029 4.07022 2.19526 4.19524C2.07024 4.32027 2 4.48984 2 4.66665V11.3333C2 12.2174 2.35119 13.0652 2.97631 13.6903C3.60143 14.3155 4.44928 14.6666 5.33333 14.6666H10.6667C10.8435 14.6666 11.013 14.5964 11.1381 14.4714C11.2631 14.3464 11.3333 14.1768 11.3333 14C11.3333 13.8232 11.2631 13.6536 11.1381 13.5286C11.013 13.4035 10.8435 13.3333 10.6667 13.3333ZM14 5.95998C13.9931 5.89874 13.9796 5.8384 13.96 5.77998V5.71998C13.9279 5.65143 13.8852 5.58842 13.8333 5.53331L9.83333 1.53331C9.77822 1.48146 9.71521 1.4387 9.64667 1.40665H9.58667L9.37333 1.33331H6.66667C6.13623 1.33331 5.62753 1.54403 5.25245 1.9191C4.87738 2.29417 4.66667 2.80288 4.66667 3.33331V9.99998C4.66667 10.5304 4.87738 11.0391 5.25245 11.4142C5.62753 11.7893 6.13623 12 6.66667 12H12C12.5304 12 13.0391 11.7893 13.4142 11.4142C13.7893 11.0391 14 10.5304 14 9.99998V5.99998C14 5.99998 14 5.99998 14 5.95998ZM10 3.60665L11.7267 5.33331H10.6667C10.4899 5.33331 10.3203 5.26307 10.1953 5.13805C10.0702 5.01303 10 4.84346 10 4.66665V3.60665ZM12.6667 9.99998C12.6667 10.1768 12.5964 10.3464 12.4714 10.4714C12.3464 10.5964 12.1768 10.6666 12 10.6666H6.66667C6.48986 10.6666 6.32029 10.5964 6.19526 10.4714C6.07024 10.3464 6 10.1768 6 9.99998V3.33331C6 3.1565 6.07024 2.98693 6.19526 2.86191C6.32029 2.73688 6.48986 2.66665 6.66667 2.66665H8.66667V4.66665C8.66667 5.19708 8.87738 5.70579 9.25245 6.08086C9.62753 6.45593 10.1362 6.66665 10.6667 6.66665H12.6667V9.99998Z" fill="#8B8D97"/>
                      </svg>
                  </div>
                  <div class="group-5">
                    <div class="text-wrapper-13">$6577</div>
                    
                    <svg class="u-copy-alt" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path d="M10.6667 13.3333H5.33333C4.8029 13.3333 4.29419 13.1226 3.91912 12.7475C3.54405 12.3725 3.33333 11.8637 3.33333 11.3333V4.66665C3.33333 4.48984 3.2631 4.32027 3.13807 4.19524C3.01305 4.07022 2.84348 3.99998 2.66667 3.99998C2.48986 3.99998 2.32029 4.07022 2.19526 4.19524C2.07024 4.32027 2 4.48984 2 4.66665V11.3333C2 12.2174 2.35119 13.0652 2.97631 13.6903C3.60143 14.3155 4.44928 14.6666 5.33333 14.6666H10.6667C10.8435 14.6666 11.013 14.5964 11.1381 14.4714C11.2631 14.3464 11.3333 14.1768 11.3333 14C11.3333 13.8232 11.2631 13.6536 11.1381 13.5286C11.013 13.4035 10.8435 13.3333 10.6667 13.3333ZM14 5.95998C13.9931 5.89874 13.9796 5.8384 13.96 5.77998V5.71998C13.9279 5.65143 13.8852 5.58842 13.8333 5.53331L9.83333 1.53331C9.77822 1.48146 9.71521 1.4387 9.64667 1.40665H9.58667L9.37333 1.33331H6.66667C6.13623 1.33331 5.62753 1.54403 5.25245 1.9191C4.87738 2.29417 4.66667 2.80288 4.66667 3.33331V9.99998C4.66667 10.5304 4.87738 11.0391 5.25245 11.4142C5.62753 11.7893 6.13623 12 6.66667 12H12C12.5304 12 13.0391 11.7893 13.4142 11.4142C13.7893 11.0391 14 10.5304 14 9.99998V5.99998C14 5.99998 14 5.99998 14 5.95998ZM10 3.60665L11.7267 5.33331H10.6667C10.4899 5.33331 10.3203 5.26307 10.1953 5.13805C10.0702 5.01303 10 4.84346 10 4.66665V3.60665ZM12.6667 9.99998C12.6667 10.1768 12.5964 10.3464 12.4714 10.4714C12.3464 10.5964 12.1768 10.6666 12 10.6666H6.66667C6.48986 10.6666 6.32029 10.5964 6.19526 10.4714C6.07024 10.3464 6 10.1768 6 9.99998V3.33331C6 3.1565 6.07024 2.98693 6.19526 2.86191C6.32029 2.73688 6.48986 2.66665 6.66667 2.66665H8.66667V4.66665C8.66667 5.19708 8.87738 5.70579 9.25245 6.08086C9.62753 6.45593 10.1362 6.66665 10.6667 6.66665H12.6667V9.99998Z" fill="#8B8D97"/>
                      </svg>
                    
                  </div>
                  <div class="group-6">
                    <div class="text-wrapper-13">$5657</div>
                    
                    <svg class="u-copy-alt" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path d="M10.6667 13.3333H5.33333C4.8029 13.3333 4.29419 13.1226 3.91912 12.7475C3.54405 12.3725 3.33333 11.8637 3.33333 11.3333V4.66665C3.33333 4.48984 3.2631 4.32027 3.13807 4.19524C3.01305 4.07022 2.84348 3.99998 2.66667 3.99998C2.48986 3.99998 2.32029 4.07022 2.19526 4.19524C2.07024 4.32027 2 4.48984 2 4.66665V11.3333C2 12.2174 2.35119 13.0652 2.97631 13.6903C3.60143 14.3155 4.44928 14.6666 5.33333 14.6666H10.6667C10.8435 14.6666 11.013 14.5964 11.1381 14.4714C11.2631 14.3464 11.3333 14.1768 11.3333 14C11.3333 13.8232 11.2631 13.6536 11.1381 13.5286C11.013 13.4035 10.8435 13.3333 10.6667 13.3333ZM14 5.95998C13.9931 5.89874 13.9796 5.8384 13.96 5.77998V5.71998C13.9279 5.65143 13.8852 5.58842 13.8333 5.53331L9.83333 1.53331C9.77822 1.48146 9.71521 1.4387 9.64667 1.40665H9.58667L9.37333 1.33331H6.66667C6.13623 1.33331 5.62753 1.54403 5.25245 1.9191C4.87738 2.29417 4.66667 2.80288 4.66667 3.33331V9.99998C4.66667 10.5304 4.87738 11.0391 5.25245 11.4142C5.62753 11.7893 6.13623 12 6.66667 12H12C12.5304 12 13.0391 11.7893 13.4142 11.4142C13.7893 11.0391 14 10.5304 14 9.99998V5.99998C14 5.99998 14 5.99998 14 5.95998ZM10 3.60665L11.7267 5.33331H10.6667C10.4899 5.33331 10.3203 5.26307 10.1953 5.13805C10.0702 5.01303 10 4.84346 10 4.66665V3.60665ZM12.6667 9.99998C12.6667 10.1768 12.5964 10.3464 12.4714 10.4714C12.3464 10.5964 12.1768 10.6666 12 10.6666H6.66667C6.48986 10.6666 6.32029 10.5964 6.19526 10.4714C6.07024 10.3464 6 10.1768 6 9.99998V3.33331C6 3.1565 6.07024 2.98693 6.19526 2.86191C6.32029 2.73688 6.48986 2.66665 6.66667 2.66665H8.66667V4.66665C8.66667 5.19708 8.87738 5.70579 9.25245 6.08086C9.62753 6.45593 10.1362 6.66665 10.6667 6.66665H12.6667V9.99998Z" fill="#8B8D97"/>
                      </svg>
                  </div>
                  <div class="group-7">
                    <div class="text-wrapper-13">$56546</div>
                    
                    <svg class="u-copy-alt" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path d="M10.6667 13.3333H5.33333C4.8029 13.3333 4.29419 13.1226 3.91912 12.7475C3.54405 12.3725 3.33333 11.8637 3.33333 11.3333V4.66665C3.33333 4.48984 3.2631 4.32027 3.13807 4.19524C3.01305 4.07022 2.84348 3.99998 2.66667 3.99998C2.48986 3.99998 2.32029 4.07022 2.19526 4.19524C2.07024 4.32027 2 4.48984 2 4.66665V11.3333C2 12.2174 2.35119 13.0652 2.97631 13.6903C3.60143 14.3155 4.44928 14.6666 5.33333 14.6666H10.6667C10.8435 14.6666 11.013 14.5964 11.1381 14.4714C11.2631 14.3464 11.3333 14.1768 11.3333 14C11.3333 13.8232 11.2631 13.6536 11.1381 13.5286C11.013 13.4035 10.8435 13.3333 10.6667 13.3333ZM14 5.95998C13.9931 5.89874 13.9796 5.8384 13.96 5.77998V5.71998C13.9279 5.65143 13.8852 5.58842 13.8333 5.53331L9.83333 1.53331C9.77822 1.48146 9.71521 1.4387 9.64667 1.40665H9.58667L9.37333 1.33331H6.66667C6.13623 1.33331 5.62753 1.54403 5.25245 1.9191C4.87738 2.29417 4.66667 2.80288 4.66667 3.33331V9.99998C4.66667 10.5304 4.87738 11.0391 5.25245 11.4142C5.62753 11.7893 6.13623 12 6.66667 12H12C12.5304 12 13.0391 11.7893 13.4142 11.4142C13.7893 11.0391 14 10.5304 14 9.99998V5.99998C14 5.99998 14 5.99998 14 5.95998ZM10 3.60665L11.7267 5.33331H10.6667C10.4899 5.33331 10.3203 5.26307 10.1953 5.13805C10.0702 5.01303 10 4.84346 10 4.66665V3.60665ZM12.6667 9.99998C12.6667 10.1768 12.5964 10.3464 12.4714 10.4714C12.3464 10.5964 12.1768 10.6666 12 10.6666H6.66667C6.48986 10.6666 6.32029 10.5964 6.19526 10.4714C6.07024 10.3464 6 10.1768 6 9.99998V3.33331C6 3.1565 6.07024 2.98693 6.19526 2.86191C6.32029 2.73688 6.48986 2.66665 6.66667 2.66665H8.66667V4.66665C8.66667 5.19708 8.87738 5.70579 9.25245 6.08086C9.62753 6.45593 10.1362 6.66665 10.6667 6.66665H12.6667V9.99998Z" fill="#8B8D97"/>
                      </svg>
                  </div>
                  <div class="group-8">
                    <div class="text-wrapper-13">$56</div>
                    
                    <svg class="u-copy-alt" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path d="M10.6667 13.3333H5.33333C4.8029 13.3333 4.29419 13.1226 3.91912 12.7475C3.54405 12.3725 3.33333 11.8637 3.33333 11.3333V4.66665C3.33333 4.48984 3.2631 4.32027 3.13807 4.19524C3.01305 4.07022 2.84348 3.99998 2.66667 3.99998C2.48986 3.99998 2.32029 4.07022 2.19526 4.19524C2.07024 4.32027 2 4.48984 2 4.66665V11.3333C2 12.2174 2.35119 13.0652 2.97631 13.6903C3.60143 14.3155 4.44928 14.6666 5.33333 14.6666H10.6667C10.8435 14.6666 11.013 14.5964 11.1381 14.4714C11.2631 14.3464 11.3333 14.1768 11.3333 14C11.3333 13.8232 11.2631 13.6536 11.1381 13.5286C11.013 13.4035 10.8435 13.3333 10.6667 13.3333ZM14 5.95998C13.9931 5.89874 13.9796 5.8384 13.96 5.77998V5.71998C13.9279 5.65143 13.8852 5.58842 13.8333 5.53331L9.83333 1.53331C9.77822 1.48146 9.71521 1.4387 9.64667 1.40665H9.58667L9.37333 1.33331H6.66667C6.13623 1.33331 5.62753 1.54403 5.25245 1.9191C4.87738 2.29417 4.66667 2.80288 4.66667 3.33331V9.99998C4.66667 10.5304 4.87738 11.0391 5.25245 11.4142C5.62753 11.7893 6.13623 12 6.66667 12H12C12.5304 12 13.0391 11.7893 13.4142 11.4142C13.7893 11.0391 14 10.5304 14 9.99998V5.99998C14 5.99998 14 5.99998 14 5.95998ZM10 3.60665L11.7267 5.33331H10.6667C10.4899 5.33331 10.3203 5.26307 10.1953 5.13805C10.0702 5.01303 10 4.84346 10 4.66665V3.60665ZM12.6667 9.99998C12.6667 10.1768 12.5964 10.3464 12.4714 10.4714C12.3464 10.5964 12.1768 10.6666 12 10.6666H6.66667C6.48986 10.6666 6.32029 10.5964 6.19526 10.4714C6.07024 10.3464 6 10.1768 6 9.99998V3.33331C6 3.1565 6.07024 2.98693 6.19526 2.86191C6.32029 2.73688 6.48986 2.66665 6.66667 2.66665H8.66667V4.66665C8.66667 5.19708 8.87738 5.70579 9.25245 6.08086C9.62753 6.45593 10.1362 6.66665 10.6667 6.66665H12.6667V9.99998Z" fill="#8B8D97"/>
                      </svg>
                  </div>
                  <div class="group-9">
                    <div class="text-wrapper-13">$545</div>

                    <svg class="u-copy-alt" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path d="M10.6667 13.3333H5.33333C4.8029 13.3333 4.29419 13.1226 3.91912 12.7475C3.54405 12.3725 3.33333 11.8637 3.33333 11.3333V4.66665C3.33333 4.48984 3.2631 4.32027 3.13807 4.19524C3.01305 4.07022 2.84348 3.99998 2.66667 3.99998C2.48986 3.99998 2.32029 4.07022 2.19526 4.19524C2.07024 4.32027 2 4.48984 2 4.66665V11.3333C2 12.2174 2.35119 13.0652 2.97631 13.6903C3.60143 14.3155 4.44928 14.6666 5.33333 14.6666H10.6667C10.8435 14.6666 11.013 14.5964 11.1381 14.4714C11.2631 14.3464 11.3333 14.1768 11.3333 14C11.3333 13.8232 11.2631 13.6536 11.1381 13.5286C11.013 13.4035 10.8435 13.3333 10.6667 13.3333ZM14 5.95998C13.9931 5.89874 13.9796 5.8384 13.96 5.77998V5.71998C13.9279 5.65143 13.8852 5.58842 13.8333 5.53331L9.83333 1.53331C9.77822 1.48146 9.71521 1.4387 9.64667 1.40665H9.58667L9.37333 1.33331H6.66667C6.13623 1.33331 5.62753 1.54403 5.25245 1.9191C4.87738 2.29417 4.66667 2.80288 4.66667 3.33331V9.99998C4.66667 10.5304 4.87738 11.0391 5.25245 11.4142C5.62753 11.7893 6.13623 12 6.66667 12H12C12.5304 12 13.0391 11.7893 13.4142 11.4142C13.7893 11.0391 14 10.5304 14 9.99998V5.99998C14 5.99998 14 5.99998 14 5.95998ZM10 3.60665L11.7267 5.33331H10.6667C10.4899 5.33331 10.3203 5.26307 10.1953 5.13805C10.0702 5.01303 10 4.84346 10 4.66665V3.60665ZM12.6667 9.99998C12.6667 10.1768 12.5964 10.3464 12.4714 10.4714C12.3464 10.5964 12.1768 10.6666 12 10.6666H6.66667C6.48986 10.6666 6.32029 10.5964 6.19526 10.4714C6.07024 10.3464 6 10.1768 6 9.99998V3.33331C6 3.1565 6.07024 2.98693 6.19526 2.86191C6.32029 2.73688 6.48986 2.66665 6.66667 2.66665H8.66667V4.66665C8.66667 5.19708 8.87738 5.70579 9.25245 6.08086C9.62753 6.45593 10.1362 6.66665 10.6667 6.66665H12.6667V9.99998Z" fill="#8B8D97"/>
                      </svg>
                  </div>
                  <div class="group-10">
                    <div class="text-wrapper-13">$6456</div>

                    <svg class="u-copy-alt" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path d="M10.6667 13.3333H5.33333C4.8029 13.3333 4.29419 13.1226 3.91912 12.7475C3.54405 12.3725 3.33333 11.8637 3.33333 11.3333V4.66665C3.33333 4.48984 3.2631 4.32027 3.13807 4.19524C3.01305 4.07022 2.84348 3.99998 2.66667 3.99998C2.48986 3.99998 2.32029 4.07022 2.19526 4.19524C2.07024 4.32027 2 4.48984 2 4.66665V11.3333C2 12.2174 2.35119 13.0652 2.97631 13.6903C3.60143 14.3155 4.44928 14.6666 5.33333 14.6666H10.6667C10.8435 14.6666 11.013 14.5964 11.1381 14.4714C11.2631 14.3464 11.3333 14.1768 11.3333 14C11.3333 13.8232 11.2631 13.6536 11.1381 13.5286C11.013 13.4035 10.8435 13.3333 10.6667 13.3333ZM14 5.95998C13.9931 5.89874 13.9796 5.8384 13.96 5.77998V5.71998C13.9279 5.65143 13.8852 5.58842 13.8333 5.53331L9.83333 1.53331C9.77822 1.48146 9.71521 1.4387 9.64667 1.40665H9.58667L9.37333 1.33331H6.66667C6.13623 1.33331 5.62753 1.54403 5.25245 1.9191C4.87738 2.29417 4.66667 2.80288 4.66667 3.33331V9.99998C4.66667 10.5304 4.87738 11.0391 5.25245 11.4142C5.62753 11.7893 6.13623 12 6.66667 12H12C12.5304 12 13.0391 11.7893 13.4142 11.4142C13.7893 11.0391 14 10.5304 14 9.99998V5.99998C14 5.99998 14 5.99998 14 5.95998ZM10 3.60665L11.7267 5.33331H10.6667C10.4899 5.33331 10.3203 5.26307 10.1953 5.13805C10.0702 5.01303 10 4.84346 10 4.66665V3.60665ZM12.6667 9.99998C12.6667 10.1768 12.5964 10.3464 12.4714 10.4714C12.3464 10.5964 12.1768 10.6666 12 10.6666H6.66667C6.48986 10.6666 6.32029 10.5964 6.19526 10.4714C6.07024 10.3464 6 10.1768 6 9.99998V3.33331C6 3.1565 6.07024 2.98693 6.19526 2.86191C6.32029 2.73688 6.48986 2.66665 6.66667 2.66665H8.66667V4.66665C8.66667 5.19708 8.87738 5.70579 9.25245 6.08086C9.62753 6.45593 10.1362 6.66665 10.6667 6.66665H12.6667V9.99998Z" fill="#8B8D97"/>
                      </svg>
                  </div>
                  <div class="group-11">
                    <div class="text-wrapper-13">$5446</div>
                    <svg class="u-copy-alt" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path d="M10.6667 13.3333H5.33333C4.8029 13.3333 4.29419 13.1226 3.91912 12.7475C3.54405 12.3725 3.33333 11.8637 3.33333 11.3333V4.66665C3.33333 4.48984 3.2631 4.32027 3.13807 4.19524C3.01305 4.07022 2.84348 3.99998 2.66667 3.99998C2.48986 3.99998 2.32029 4.07022 2.19526 4.19524C2.07024 4.32027 2 4.48984 2 4.66665V11.3333C2 12.2174 2.35119 13.0652 2.97631 13.6903C3.60143 14.3155 4.44928 14.6666 5.33333 14.6666H10.6667C10.8435 14.6666 11.013 14.5964 11.1381 14.4714C11.2631 14.3464 11.3333 14.1768 11.3333 14C11.3333 13.8232 11.2631 13.6536 11.1381 13.5286C11.013 13.4035 10.8435 13.3333 10.6667 13.3333ZM14 5.95998C13.9931 5.89874 13.9796 5.8384 13.96 5.77998V5.71998C13.9279 5.65143 13.8852 5.58842 13.8333 5.53331L9.83333 1.53331C9.77822 1.48146 9.71521 1.4387 9.64667 1.40665H9.58667L9.37333 1.33331H6.66667C6.13623 1.33331 5.62753 1.54403 5.25245 1.9191C4.87738 2.29417 4.66667 2.80288 4.66667 3.33331V9.99998C4.66667 10.5304 4.87738 11.0391 5.25245 11.4142C5.62753 11.7893 6.13623 12 6.66667 12H12C12.5304 12 13.0391 11.7893 13.4142 11.4142C13.7893 11.0391 14 10.5304 14 9.99998V5.99998C14 5.99998 14 5.99998 14 5.95998ZM10 3.60665L11.7267 5.33331H10.6667C10.4899 5.33331 10.3203 5.26307 10.1953 5.13805C10.0702 5.01303 10 4.84346 10 4.66665V3.60665ZM12.6667 9.99998C12.6667 10.1768 12.5964 10.3464 12.4714 10.4714C12.3464 10.5964 12.1768 10.6666 12 10.6666H6.66667C6.48986 10.6666 6.32029 10.5964 6.19526 10.4714C6.07024 10.3464 6 10.1768 6 9.99998V3.33331C6 3.1565 6.07024 2.98693 6.19526 2.86191C6.32029 2.73688 6.48986 2.66665 6.66667 2.66665H8.66667V4.66665C8.66667 5.19708 8.87738 5.70579 9.25245 6.08086C9.62753 6.45593 10.1362 6.66665 10.6667 6.66665H12.6667V9.99998Z" fill="#8B8D97"/>
                      </svg>
                  </div>
                  <div class="group-12">
                    <div class="text-wrapper-13">$546</div>
                    <svg class="u-copy-alt" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path d="M10.6667 13.3333H5.33333C4.8029 13.3333 4.29419 13.1226 3.91912 12.7476C3.54405 12.3725 3.33333 11.8638 3.33333 11.3333V4.66668C3.33333 4.48987 3.2631 4.3203 3.13807 4.19527C3.01305 4.07025 2.84348 4.00001 2.66667 4.00001C2.48986 4.00001 2.32029 4.07025 2.19526 4.19527C2.07024 4.3203 2 4.48987 2 4.66668V11.3333C2 12.2174 2.35119 13.0652 2.97631 13.6904C3.60143 14.3155 4.44928 14.6667 5.33333 14.6667H10.6667C10.8435 14.6667 11.013 14.5964 11.1381 14.4714C11.2631 14.3464 11.3333 14.1768 11.3333 14C11.3333 13.8232 11.2631 13.6536 11.1381 13.5286C11.013 13.4036 10.8435 13.3333 10.6667 13.3333ZM14 5.96001C13.9931 5.89877 13.9796 5.83843 13.96 5.78001V5.72001C13.9279 5.65146 13.8852 5.58845 13.8333 5.53334L9.83333 1.53334C9.77822 1.48149 9.71521 1.43873 9.64667 1.40668H9.58667L9.37333 1.33334H6.66667C6.13623 1.33334 5.62753 1.54406 5.25245 1.91913C4.87738 2.2942 4.66667 2.80291 4.66667 3.33334V10C4.66667 10.5304 4.87738 11.0392 5.25245 11.4142C5.62753 11.7893 6.13623 12 6.66667 12H12C12.5304 12 13.0391 11.7893 13.4142 11.4142C13.7893 11.0392 14 10.5304 14 10V6.00001C14 6.00001 14 6.00001 14 5.96001ZM10 3.60668L11.7267 5.33334H10.6667C10.4899 5.33334 10.3203 5.26311 10.1953 5.13808C10.0702 5.01306 10 4.84349 10 4.66668V3.60668ZM12.6667 10C12.6667 10.1768 12.5964 10.3464 12.4714 10.4714C12.3464 10.5964 12.1768 10.6667 12 10.6667H6.66667C6.48986 10.6667 6.32029 10.5964 6.19526 10.4714C6.07024 10.3464 6 10.1768 6 10V3.33334C6 3.15653 6.07024 2.98696 6.19526 2.86194C6.32029 2.73691 6.48986 2.66668 6.66667 2.66668H8.66667V4.66668C8.66667 5.19711 8.87738 5.70582 9.25245 6.08089C9.62753 6.45596 10.1362 6.66668 10.6667 6.66668H12.6667V10Z" fill="#8B8D97"/>
                      </svg>
                  </div>
                </div>
              </div>
              <div class="email">
                <div class="frame-6">
                  <div class="text-wrapper-11">Invoice no.</div>
                  <svg class="img" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M14 5.16669H2C1.72667 5.16669 1.5 4.94002 1.5 4.66669C1.5 4.39335 1.72667 4.16669 2 4.16669H14C14.2733 4.16669 14.5 4.39335 14.5 4.66669C14.5 4.94002 14.2733 5.16669 14 5.16669Z" fill="#00092E"/>
                    <path d="M12 8.5H4C3.72667 8.5 3.5 8.27333 3.5 8C3.5 7.72667 3.72667 7.5 4 7.5H12C12.2733 7.5 12.5 7.72667 12.5 8C12.5 8.27333 12.2733 8.5 12 8.5Z" fill="#00092E"/>
                    <path d="M9.33329 11.8333H6.66663C6.39329 11.8333 6.16663 11.6066 6.16663 11.3333C6.16663 11.06 6.39329 10.8333 6.66663 10.8333H9.33329C9.60663 10.8333 9.83329 11.06 9.83329 11.3333C9.83329 11.6066 9.60663 11.8333 9.33329 11.8333Z" fill="#00092E"/>
                    </svg>
                </div>
                <div class="group-13">
                  <div class="group-14">
                    <div class="text-wrapper-13">56</div>
                    <svg class="u-copy-alt-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path d="M10.6667 13.3333H5.33333C4.8029 13.3333 4.29419 13.1226 3.91912 12.7475C3.54405 12.3725 3.33333 11.8637 3.33333 11.3333V4.66665C3.33333 4.48984 3.2631 4.32027 3.13807 4.19524C3.01305 4.07022 2.84348 3.99998 2.66667 3.99998C2.48986 3.99998 2.32029 4.07022 2.19526 4.19524C2.07024 4.32027 2 4.48984 2 4.66665V11.3333C2 12.2174 2.35119 13.0652 2.97631 13.6903C3.60143 14.3155 4.44928 14.6666 5.33333 14.6666H10.6667C10.8435 14.6666 11.013 14.5964 11.1381 14.4714C11.2631 14.3464 11.3333 14.1768 11.3333 14C11.3333 13.8232 11.2631 13.6536 11.1381 13.5286C11.013 13.4035 10.8435 13.3333 10.6667 13.3333ZM14 5.95998C13.9931 5.89874 13.9796 5.8384 13.96 5.77998V5.71998C13.9279 5.65143 13.8852 5.58842 13.8333 5.53331L9.83333 1.53331C9.77822 1.48146 9.71521 1.4387 9.64667 1.40665H9.58667L9.37333 1.33331H6.66667C6.13623 1.33331 5.62753 1.54403 5.25245 1.9191C4.87738 2.29417 4.66667 2.80288 4.66667 3.33331V9.99998C4.66667 10.5304 4.87738 11.0391 5.25245 11.4142C5.62753 11.7893 6.13623 12 6.66667 12H12C12.5304 12 13.0391 11.7893 13.4142 11.4142C13.7893 11.0391 14 10.5304 14 9.99998V5.99998C14 5.99998 14 5.99998 14 5.95998ZM10 3.60665L11.7267 5.33331H10.6667C10.4899 5.33331 10.3203 5.26307 10.1953 5.13805C10.0702 5.01303 10 4.84346 10 4.66665V3.60665ZM12.6667 9.99998C12.6667 10.1768 12.5964 10.3464 12.4714 10.4714C12.3464 10.5964 12.1768 10.6666 12 10.6666H6.66667C6.48986 10.6666 6.32029 10.5964 6.19526 10.4714C6.07024 10.3464 6 10.1768 6 9.99998V3.33331C6 3.1565 6.07024 2.98693 6.19526 2.86191C6.32029 2.73688 6.48986 2.66665 6.66667 2.66665H8.66667V4.66665C8.66667 5.19708 8.87738 5.70579 9.25245 6.08086C9.62753 6.45593 10.1362 6.66665 10.6667 6.66665H12.6667V9.99998Z" fill="#8B8D97"/>
                      </svg>
                  </div>
                  <div class="group-15">
                    <div class="text-wrapper-13">76</div>
                    <svg class="u-copy-alt-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path d="M10.6667 13.3333H5.33333C4.8029 13.3333 4.29419 13.1226 3.91912 12.7475C3.54405 12.3725 3.33333 11.8637 3.33333 11.3333V4.66665C3.33333 4.48984 3.2631 4.32027 3.13807 4.19524C3.01305 4.07022 2.84348 3.99998 2.66667 3.99998C2.48986 3.99998 2.32029 4.07022 2.19526 4.19524C2.07024 4.32027 2 4.48984 2 4.66665V11.3333C2 12.2174 2.35119 13.0652 2.97631 13.6903C3.60143 14.3155 4.44928 14.6666 5.33333 14.6666H10.6667C10.8435 14.6666 11.013 14.5964 11.1381 14.4714C11.2631 14.3464 11.3333 14.1768 11.3333 14C11.3333 13.8232 11.2631 13.6536 11.1381 13.5286C11.013 13.4035 10.8435 13.3333 10.6667 13.3333ZM14 5.95998C13.9931 5.89874 13.9796 5.8384 13.96 5.77998V5.71998C13.9279 5.65143 13.8852 5.58842 13.8333 5.53331L9.83333 1.53331C9.77822 1.48146 9.71521 1.4387 9.64667 1.40665H9.58667L9.37333 1.33331H6.66667C6.13623 1.33331 5.62753 1.54403 5.25245 1.9191C4.87738 2.29417 4.66667 2.80288 4.66667 3.33331V9.99998C4.66667 10.5304 4.87738 11.0391 5.25245 11.4142C5.62753 11.7893 6.13623 12 6.66667 12H12C12.5304 12 13.0391 11.7893 13.4142 11.4142C13.7893 11.0391 14 10.5304 14 9.99998V5.99998C14 5.99998 14 5.99998 14 5.95998ZM10 3.60665L11.7267 5.33331H10.6667C10.4899 5.33331 10.3203 5.26307 10.1953 5.13805C10.0702 5.01303 10 4.84346 10 4.66665V3.60665ZM12.6667 9.99998C12.6667 10.1768 12.5964 10.3464 12.4714 10.4714C12.3464 10.5964 12.1768 10.6666 12 10.6666H6.66667C6.48986 10.6666 6.32029 10.5964 6.19526 10.4714C6.07024 10.3464 6 10.1768 6 9.99998V3.33331C6 3.1565 6.07024 2.98693 6.19526 2.86191C6.32029 2.73688 6.48986 2.66665 6.66667 2.66665H8.66667V4.66665C8.66667 5.19708 8.87738 5.70579 9.25245 6.08086C9.62753 6.45593 10.1362 6.66665 10.6667 6.66665H12.6667V9.99998Z" fill="#8B8D97"/>
                      </svg>
                  </div>
                  <div class="group-16">
                    <div class="text-wrapper-13">46</div>
                    <svg class="u-copy-alt-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path d="M10.6667 13.3333H5.33333C4.8029 13.3333 4.29419 13.1226 3.91912 12.7475C3.54405 12.3725 3.33333 11.8637 3.33333 11.3333V4.66665C3.33333 4.48984 3.2631 4.32027 3.13807 4.19524C3.01305 4.07022 2.84348 3.99998 2.66667 3.99998C2.48986 3.99998 2.32029 4.07022 2.19526 4.19524C2.07024 4.32027 2 4.48984 2 4.66665V11.3333C2 12.2174 2.35119 13.0652 2.97631 13.6903C3.60143 14.3155 4.44928 14.6666 5.33333 14.6666H10.6667C10.8435 14.6666 11.013 14.5964 11.1381 14.4714C11.2631 14.3464 11.3333 14.1768 11.3333 14C11.3333 13.8232 11.2631 13.6536 11.1381 13.5286C11.013 13.4035 10.8435 13.3333 10.6667 13.3333ZM14 5.95998C13.9931 5.89874 13.9796 5.8384 13.96 5.77998V5.71998C13.9279 5.65143 13.8852 5.58842 13.8333 5.53331L9.83333 1.53331C9.77822 1.48146 9.71521 1.4387 9.64667 1.40665H9.58667L9.37333 1.33331H6.66667C6.13623 1.33331 5.62753 1.54403 5.25245 1.9191C4.87738 2.29417 4.66667 2.80288 4.66667 3.33331V9.99998C4.66667 10.5304 4.87738 11.0391 5.25245 11.4142C5.62753 11.7893 6.13623 12 6.66667 12H12C12.5304 12 13.0391 11.7893 13.4142 11.4142C13.7893 11.0391 14 10.5304 14 9.99998V5.99998C14 5.99998 14 5.99998 14 5.95998ZM10 3.60665L11.7267 5.33331H10.6667C10.4899 5.33331 10.3203 5.26307 10.1953 5.13805C10.0702 5.01303 10 4.84346 10 4.66665V3.60665ZM12.6667 9.99998C12.6667 10.1768 12.5964 10.3464 12.4714 10.4714C12.3464 10.5964 12.1768 10.6666 12 10.6666H6.66667C6.48986 10.6666 6.32029 10.5964 6.19526 10.4714C6.07024 10.3464 6 10.1768 6 9.99998V3.33331C6 3.1565 6.07024 2.98693 6.19526 2.86191C6.32029 2.73688 6.48986 2.66665 6.66667 2.66665H8.66667V4.66665C8.66667 5.19708 8.87738 5.70579 9.25245 6.08086C9.62753 6.45593 10.1362 6.66665 10.6667 6.66665H12.6667V9.99998Z" fill="#8B8D97"/>
                      </svg>
                  </div>
                  <div class="group-17">
                    <div class="text-wrapper-13">47</div>
                    <svg class="u-copy-alt-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path d="M10.6667 13.3333H5.33333C4.8029 13.3333 4.29419 13.1226 3.91912 12.7475C3.54405 12.3725 3.33333 11.8637 3.33333 11.3333V4.66665C3.33333 4.48984 3.2631 4.32027 3.13807 4.19524C3.01305 4.07022 2.84348 3.99998 2.66667 3.99998C2.48986 3.99998 2.32029 4.07022 2.19526 4.19524C2.07024 4.32027 2 4.48984 2 4.66665V11.3333C2 12.2174 2.35119 13.0652 2.97631 13.6903C3.60143 14.3155 4.44928 14.6666 5.33333 14.6666H10.6667C10.8435 14.6666 11.013 14.5964 11.1381 14.4714C11.2631 14.3464 11.3333 14.1768 11.3333 14C11.3333 13.8232 11.2631 13.6536 11.1381 13.5286C11.013 13.4035 10.8435 13.3333 10.6667 13.3333ZM14 5.95998C13.9931 5.89874 13.9796 5.8384 13.96 5.77998V5.71998C13.9279 5.65143 13.8852 5.58842 13.8333 5.53331L9.83333 1.53331C9.77822 1.48146 9.71521 1.4387 9.64667 1.40665H9.58667L9.37333 1.33331H6.66667C6.13623 1.33331 5.62753 1.54403 5.25245 1.9191C4.87738 2.29417 4.66667 2.80288 4.66667 3.33331V9.99998C4.66667 10.5304 4.87738 11.0391 5.25245 11.4142C5.62753 11.7893 6.13623 12 6.66667 12H12C12.5304 12 13.0391 11.7893 13.4142 11.4142C13.7893 11.0391 14 10.5304 14 9.99998V5.99998C14 5.99998 14 5.99998 14 5.95998ZM10 3.60665L11.7267 5.33331H10.6667C10.4899 5.33331 10.3203 5.26307 10.1953 5.13805C10.0702 5.01303 10 4.84346 10 4.66665V3.60665ZM12.6667 9.99998C12.6667 10.1768 12.5964 10.3464 12.4714 10.4714C12.3464 10.5964 12.1768 10.6666 12 10.6666H6.66667C6.48986 10.6666 6.32029 10.5964 6.19526 10.4714C6.07024 10.3464 6 10.1768 6 9.99998V3.33331C6 3.1565 6.07024 2.98693 6.19526 2.86191C6.32029 2.73688 6.48986 2.66665 6.66667 2.66665H8.66667V4.66665C8.66667 5.19708 8.87738 5.70579 9.25245 6.08086C9.62753 6.45593 10.1362 6.66665 10.6667 6.66665H12.6667V9.99998Z" fill="#8B8D97"/>
                      </svg>
                  </div>
                  <div class="group-18">
                    <div class="text-wrapper-13">37</div>
                    <svg class="u-copy-alt-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path d="M10.6667 13.3333H5.33333C4.8029 13.3333 4.29419 13.1226 3.91912 12.7475C3.54405 12.3725 3.33333 11.8637 3.33333 11.3333V4.66665C3.33333 4.48984 3.2631 4.32027 3.13807 4.19524C3.01305 4.07022 2.84348 3.99998 2.66667 3.99998C2.48986 3.99998 2.32029 4.07022 2.19526 4.19524C2.07024 4.32027 2 4.48984 2 4.66665V11.3333C2 12.2174 2.35119 13.0652 2.97631 13.6903C3.60143 14.3155 4.44928 14.6666 5.33333 14.6666H10.6667C10.8435 14.6666 11.013 14.5964 11.1381 14.4714C11.2631 14.3464 11.3333 14.1768 11.3333 14C11.3333 13.8232 11.2631 13.6536 11.1381 13.5286C11.013 13.4035 10.8435 13.3333 10.6667 13.3333ZM14 5.95998C13.9931 5.89874 13.9796 5.8384 13.96 5.77998V5.71998C13.9279 5.65143 13.8852 5.58842 13.8333 5.53331L9.83333 1.53331C9.77822 1.48146 9.71521 1.4387 9.64667 1.40665H9.58667L9.37333 1.33331H6.66667C6.13623 1.33331 5.62753 1.54403 5.25245 1.9191C4.87738 2.29417 4.66667 2.80288 4.66667 3.33331V9.99998C4.66667 10.5304 4.87738 11.0391 5.25245 11.4142C5.62753 11.7893 6.13623 12 6.66667 12H12C12.5304 12 13.0391 11.7893 13.4142 11.4142C13.7893 11.0391 14 10.5304 14 9.99998V5.99998C14 5.99998 14 5.99998 14 5.95998ZM10 3.60665L11.7267 5.33331H10.6667C10.4899 5.33331 10.3203 5.26307 10.1953 5.13805C10.0702 5.01303 10 4.84346 10 4.66665V3.60665ZM12.6667 9.99998C12.6667 10.1768 12.5964 10.3464 12.4714 10.4714C12.3464 10.5964 12.1768 10.6666 12 10.6666H6.66667C6.48986 10.6666 6.32029 10.5964 6.19526 10.4714C6.07024 10.3464 6 10.1768 6 9.99998V3.33331C6 3.1565 6.07024 2.98693 6.19526 2.86191C6.32029 2.73688 6.48986 2.66665 6.66667 2.66665H8.66667V4.66665C8.66667 5.19708 8.87738 5.70579 9.25245 6.08086C9.62753 6.45593 10.1362 6.66665 10.6667 6.66665H12.6667V9.99998Z" fill="#8B8D97"/>
                      </svg>
                  </div>
                  <div class="group-19">
                    <div class="text-wrapper-13">84</div>
                    <svg class="u-copy-alt-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path d="M10.6667 13.3333H5.33333C4.8029 13.3333 4.29419 13.1226 3.91912 12.7475C3.54405 12.3725 3.33333 11.8637 3.33333 11.3333V4.66665C3.33333 4.48984 3.2631 4.32027 3.13807 4.19524C3.01305 4.07022 2.84348 3.99998 2.66667 3.99998C2.48986 3.99998 2.32029 4.07022 2.19526 4.19524C2.07024 4.32027 2 4.48984 2 4.66665V11.3333C2 12.2174 2.35119 13.0652 2.97631 13.6903C3.60143 14.3155 4.44928 14.6666 5.33333 14.6666H10.6667C10.8435 14.6666 11.013 14.5964 11.1381 14.4714C11.2631 14.3464 11.3333 14.1768 11.3333 14C11.3333 13.8232 11.2631 13.6536 11.1381 13.5286C11.013 13.4035 10.8435 13.3333 10.6667 13.3333ZM14 5.95998C13.9931 5.89874 13.9796 5.8384 13.96 5.77998V5.71998C13.9279 5.65143 13.8852 5.58842 13.8333 5.53331L9.83333 1.53331C9.77822 1.48146 9.71521 1.4387 9.64667 1.40665H9.58667L9.37333 1.33331H6.66667C6.13623 1.33331 5.62753 1.54403 5.25245 1.9191C4.87738 2.29417 4.66667 2.80288 4.66667 3.33331V9.99998C4.66667 10.5304 4.87738 11.0391 5.25245 11.4142C5.62753 11.7893 6.13623 12 6.66667 12H12C12.5304 12 13.0391 11.7893 13.4142 11.4142C13.7893 11.0391 14 10.5304 14 9.99998V5.99998C14 5.99998 14 5.99998 14 5.95998ZM10 3.60665L11.7267 5.33331H10.6667C10.4899 5.33331 10.3203 5.26307 10.1953 5.13805C10.0702 5.01303 10 4.84346 10 4.66665V3.60665ZM12.6667 9.99998C12.6667 10.1768 12.5964 10.3464 12.4714 10.4714C12.3464 10.5964 12.1768 10.6666 12 10.6666H6.66667C6.48986 10.6666 6.32029 10.5964 6.19526 10.4714C6.07024 10.3464 6 10.1768 6 9.99998V3.33331C6 3.1565 6.07024 2.98693 6.19526 2.86191C6.32029 2.73688 6.48986 2.66665 6.66667 2.66665H8.66667V4.66665C8.66667 5.19708 8.87738 5.70579 9.25245 6.08086C9.62753 6.45593 10.1362 6.66665 10.6667 6.66665H12.6667V9.99998Z" fill="#8B8D97"/>
                      </svg>
                  </div>
                  <div class="group-20">
                    <div class="text-wrapper-13">46</div>
                    <svg class="u-copy-alt-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path d="M10.6667 13.3333H5.33333C4.8029 13.3333 4.29419 13.1226 3.91912 12.7475C3.54405 12.3725 3.33333 11.8637 3.33333 11.3333V4.66665C3.33333 4.48984 3.2631 4.32027 3.13807 4.19524C3.01305 4.07022 2.84348 3.99998 2.66667 3.99998C2.48986 3.99998 2.32029 4.07022 2.19526 4.19524C2.07024 4.32027 2 4.48984 2 4.66665V11.3333C2 12.2174 2.35119 13.0652 2.97631 13.6903C3.60143 14.3155 4.44928 14.6666 5.33333 14.6666H10.6667C10.8435 14.6666 11.013 14.5964 11.1381 14.4714C11.2631 14.3464 11.3333 14.1768 11.3333 14C11.3333 13.8232 11.2631 13.6536 11.1381 13.5286C11.013 13.4035 10.8435 13.3333 10.6667 13.3333ZM14 5.95998C13.9931 5.89874 13.9796 5.8384 13.96 5.77998V5.71998C13.9279 5.65143 13.8852 5.58842 13.8333 5.53331L9.83333 1.53331C9.77822 1.48146 9.71521 1.4387 9.64667 1.40665H9.58667L9.37333 1.33331H6.66667C6.13623 1.33331 5.62753 1.54403 5.25245 1.9191C4.87738 2.29417 4.66667 2.80288 4.66667 3.33331V9.99998C4.66667 10.5304 4.87738 11.0391 5.25245 11.4142C5.62753 11.7893 6.13623 12 6.66667 12H12C12.5304 12 13.0391 11.7893 13.4142 11.4142C13.7893 11.0391 14 10.5304 14 9.99998V5.99998C14 5.99998 14 5.99998 14 5.95998ZM10 3.60665L11.7267 5.33331H10.6667C10.4899 5.33331 10.3203 5.26307 10.1953 5.13805C10.0702 5.01303 10 4.84346 10 4.66665V3.60665ZM12.6667 9.99998C12.6667 10.1768 12.5964 10.3464 12.4714 10.4714C12.3464 10.5964 12.1768 10.6666 12 10.6666H6.66667C6.48986 10.6666 6.32029 10.5964 6.19526 10.4714C6.07024 10.3464 6 10.1768 6 9.99998V3.33331C6 3.1565 6.07024 2.98693 6.19526 2.86191C6.32029 2.73688 6.48986 2.66665 6.66667 2.66665H8.66667V4.66665C8.66667 5.19708 8.87738 5.70579 9.25245 6.08086C9.62753 6.45593 10.1362 6.66665 10.6667 6.66665H12.6667V9.99998Z" fill="#8B8D97"/>
                      </svg>
                  </div>
                  <div class="group-21">
                    <div class="text-wrapper-13">87</div>
                    <svg class="u-copy-alt-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path d="M10.6667 13.3333H5.33333C4.8029 13.3333 4.29419 13.1226 3.91912 12.7475C3.54405 12.3725 3.33333 11.8637 3.33333 11.3333V4.66665C3.33333 4.48984 3.2631 4.32027 3.13807 4.19524C3.01305 4.07022 2.84348 3.99998 2.66667 3.99998C2.48986 3.99998 2.32029 4.07022 2.19526 4.19524C2.07024 4.32027 2 4.48984 2 4.66665V11.3333C2 12.2174 2.35119 13.0652 2.97631 13.6903C3.60143 14.3155 4.44928 14.6666 5.33333 14.6666H10.6667C10.8435 14.6666 11.013 14.5964 11.1381 14.4714C11.2631 14.3464 11.3333 14.1768 11.3333 14C11.3333 13.8232 11.2631 13.6536 11.1381 13.5286C11.013 13.4035 10.8435 13.3333 10.6667 13.3333ZM14 5.95998C13.9931 5.89874 13.9796 5.8384 13.96 5.77998V5.71998C13.9279 5.65143 13.8852 5.58842 13.8333 5.53331L9.83333 1.53331C9.77822 1.48146 9.71521 1.4387 9.64667 1.40665H9.58667L9.37333 1.33331H6.66667C6.13623 1.33331 5.62753 1.54403 5.25245 1.9191C4.87738 2.29417 4.66667 2.80288 4.66667 3.33331V9.99998C4.66667 10.5304 4.87738 11.0391 5.25245 11.4142C5.62753 11.7893 6.13623 12 6.66667 12H12C12.5304 12 13.0391 11.7893 13.4142 11.4142C13.7893 11.0391 14 10.5304 14 9.99998V5.99998C14 5.99998 14 5.99998 14 5.95998ZM10 3.60665L11.7267 5.33331H10.6667C10.4899 5.33331 10.3203 5.26307 10.1953 5.13805C10.0702 5.01303 10 4.84346 10 4.66665V3.60665ZM12.6667 9.99998C12.6667 10.1768 12.5964 10.3464 12.4714 10.4714C12.3464 10.5964 12.1768 10.6666 12 10.6666H6.66667C6.48986 10.6666 6.32029 10.5964 6.19526 10.4714C6.07024 10.3464 6 10.1768 6 9.99998V3.33331C6 3.1565 6.07024 2.98693 6.19526 2.86191C6.32029 2.73688 6.48986 2.66665 6.66667 2.66665H8.66667V4.66665C8.66667 5.19708 8.87738 5.70579 9.25245 6.08086C9.62753 6.45593 10.1362 6.66665 10.6667 6.66665H12.6667V9.99998Z" fill="#8B8D97"/>
                      </svg>
                  </div>
                  <div class="group-22">
                    <div class="text-wrapper-13">66</div>
                    <svg class="u-copy-alt-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path d="M10.6667 13.3333H5.33333C4.8029 13.3333 4.29419 13.1226 3.91912 12.7475C3.54405 12.3725 3.33333 11.8637 3.33333 11.3333V4.66665C3.33333 4.48984 3.2631 4.32027 3.13807 4.19524C3.01305 4.07022 2.84348 3.99998 2.66667 3.99998C2.48986 3.99998 2.32029 4.07022 2.19526 4.19524C2.07024 4.32027 2 4.48984 2 4.66665V11.3333C2 12.2174 2.35119 13.0652 2.97631 13.6903C3.60143 14.3155 4.44928 14.6666 5.33333 14.6666H10.6667C10.8435 14.6666 11.013 14.5964 11.1381 14.4714C11.2631 14.3464 11.3333 14.1768 11.3333 14C11.3333 13.8232 11.2631 13.6536 11.1381 13.5286C11.013 13.4035 10.8435 13.3333 10.6667 13.3333ZM14 5.95998C13.9931 5.89874 13.9796 5.8384 13.96 5.77998V5.71998C13.9279 5.65143 13.8852 5.58842 13.8333 5.53331L9.83333 1.53331C9.77822 1.48146 9.71521 1.4387 9.64667 1.40665H9.58667L9.37333 1.33331H6.66667C6.13623 1.33331 5.62753 1.54403 5.25245 1.9191C4.87738 2.29417 4.66667 2.80288 4.66667 3.33331V9.99998C4.66667 10.5304 4.87738 11.0391 5.25245 11.4142C5.62753 11.7893 6.13623 12 6.66667 12H12C12.5304 12 13.0391 11.7893 13.4142 11.4142C13.7893 11.0391 14 10.5304 14 9.99998V5.99998C14 5.99998 14 5.99998 14 5.95998ZM10 3.60665L11.7267 5.33331H10.6667C10.4899 5.33331 10.3203 5.26307 10.1953 5.13805C10.0702 5.01303 10 4.84346 10 4.66665V3.60665ZM12.6667 9.99998C12.6667 10.1768 12.5964 10.3464 12.4714 10.4714C12.3464 10.5964 12.1768 10.6666 12 10.6666H6.66667C6.48986 10.6666 6.32029 10.5964 6.19526 10.4714C6.07024 10.3464 6 10.1768 6 9.99998V3.33331C6 3.1565 6.07024 2.98693 6.19526 2.86191C6.32029 2.73688 6.48986 2.66665 6.66667 2.66665H8.66667V4.66665C8.66667 5.19708 8.87738 5.70579 9.25245 6.08086C9.62753 6.45593 10.1362 6.66665 10.6667 6.66665H12.6667V9.99998Z" fill="#8B8D97"/>
                      </svg>
                  </div>
                  <div class="group-23">
                    <div class="text-wrapper-13">64</div>
                    <svg class="u-copy-alt-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path d="M10.6667 13.3333H5.33333C4.8029 13.3333 4.29419 13.1226 3.91912 12.7476C3.54405 12.3725 3.33333 11.8638 3.33333 11.3333V4.66668C3.33333 4.48987 3.2631 4.3203 3.13807 4.19527C3.01305 4.07025 2.84348 4.00001 2.66667 4.00001C2.48986 4.00001 2.32029 4.07025 2.19526 4.19527C2.07024 4.3203 2 4.48987 2 4.66668V11.3333C2 12.2174 2.35119 13.0652 2.97631 13.6904C3.60143 14.3155 4.44928 14.6667 5.33333 14.6667H10.6667C10.8435 14.6667 11.013 14.5964 11.1381 14.4714C11.2631 14.3464 11.3333 14.1768 11.3333 14C11.3333 13.8232 11.2631 13.6536 11.1381 13.5286C11.013 13.4036 10.8435 13.3333 10.6667 13.3333ZM14 5.96001C13.9931 5.89877 13.9796 5.83843 13.96 5.78001V5.72001C13.9279 5.65146 13.8852 5.58845 13.8333 5.53334L9.83333 1.53334C9.77822 1.48149 9.71521 1.43873 9.64667 1.40668H9.58667L9.37333 1.33334H6.66667C6.13623 1.33334 5.62753 1.54406 5.25245 1.91913C4.87738 2.2942 4.66667 2.80291 4.66667 3.33334V10C4.66667 10.5304 4.87738 11.0392 5.25245 11.4142C5.62753 11.7893 6.13623 12 6.66667 12H12C12.5304 12 13.0391 11.7893 13.4142 11.4142C13.7893 11.0392 14 10.5304 14 10V6.00001C14 6.00001 14 6.00001 14 5.96001ZM10 3.60668L11.7267 5.33334H10.6667C10.4899 5.33334 10.3203 5.26311 10.1953 5.13808C10.0702 5.01306 10 4.84349 10 4.66668V3.60668ZM12.6667 10C12.6667 10.1768 12.5964 10.3464 12.4714 10.4714C12.3464 10.5964 12.1768 10.6667 12 10.6667H6.66667C6.48986 10.6667 6.32029 10.5964 6.19526 10.4714C6.07024 10.3464 6 10.1768 6 10V3.33334C6 3.15653 6.07024 2.98696 6.19526 2.86194C6.32029 2.73691 6.48986 2.66668 6.66667 2.66668H8.66667V4.66668C8.66667 5.19711 8.87738 5.70582 9.25245 6.08089C9.62753 6.45596 10.1362 6.66668 10.6667 6.66668H12.6667V10Z" fill="#8B8D97"/>
                      </svg>
                    
                  </div>
                </div>
              </div>
              <div class="status">
                <div class="frame-6">
                  <div class="text-wrapper-11">Status</div>
                  <svg class="img" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M14 5.16669H2C1.72667 5.16669 1.5 4.94002 1.5 4.66669C1.5 4.39335 1.72667 4.16669 2 4.16669H14C14.2733 4.16669 14.5 4.39335 14.5 4.66669C14.5 4.94002 14.2733 5.16669 14 5.16669Z" fill="#00092E"/>
                    <path d="M12 8.5H4C3.72667 8.5 3.5 8.27333 3.5 8C3.5 7.72667 3.72667 7.5 4 7.5H12C12.2733 7.5 12.5 7.72667 12.5 8C12.5 8.27333 12.2733 8.5 12 8.5Z" fill="#00092E"/>
                    <path d="M9.33341 11.8333H6.66675C6.39341 11.8333 6.16675 11.6066 6.16675 11.3333C6.16675 11.06 6.39341 10.8333 6.66675 10.8333H9.33341C9.60675 10.8333 9.83341 11.06 9.83341 11.3333C9.83341 11.6066 9.60675 11.8333 9.33341 11.8333Z" fill="#00092E"/>
                    </svg>
                </div>
                <div class="status-2"><div class="text-wrapper-14">Sent</div></div>
                <div class="status-3"><div class="text-wrapper-14">Sent</div></div>
                <div class="status-4"><div class="text-wrapper-14">Sent</div></div>
                <div class="status-5"><div class="text-wrapper-14">Sent</div></div>
                <div class="status-6"><div class="text-wrapper-14">Sent</div></div>
                <div class="status-7"><div class="text-wrapper-15">Paid</div></div>
                <div class="status-8"><div class="text-wrapper-15">Paid</div></div>
                <div class="status-9"><div class="text-wrapper-15">Paid</div></div>
                <div class="status-10"><div class="text-wrapper-15">Overdue</div></div>
                <div class="status-11"><div class="text-wrapper-15">Overdue</div></div>
              </div>
              <div class="customer-since">
                <div class="frame-6">
                  <div class="frame-7">
                    <div class="text-wrapper-11">Invoice date &amp; time</div>
                    <svg class="img" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path d="M14 5.16669H2C1.72667 5.16669 1.5 4.94002 1.5 4.66669C1.5 4.39335 1.72667 4.16669 2 4.16669H14C14.2733 4.16669 14.5 4.39335 14.5 4.66669C14.5 4.94002 14.2733 5.16669 14 5.16669Z" fill="#00092E"/>
                      <path d="M12 8.5H4C3.72667 8.5 3.5 8.27333 3.5 8C3.5 7.72667 3.72667 7.5 4 7.5H12C12.2733 7.5 12.5 7.72667 12.5 8C12.5 8.27333 12.2733 8.5 12 8.5Z" fill="#00092E"/>
                      <path d="M9.33341 11.8333H6.66675C6.39341 11.8333 6.16675 11.6066 6.16675 11.3333C6.16675 11.06 6.39341 10.8333 6.66675 10.8333H9.33341C9.60675 10.8333 9.83341 11.06 9.83341 11.3333C9.83341 11.6066 9.60675 11.8333 9.33341 11.8333Z" fill="#00092E"/>
                      </svg>
                  </div>
                </div>
                <p class="text-wrapper-16">12 Aug 2022 - 12:25 am</p>
                <p class="text-wrapper-17">12 Aug 2022 - 12:25 am</p>
                <p class="text-wrapper-18">12 Aug 2022 - 12:25 am</p>
                <p class="text-wrapper-19">12 Aug 2022 - 12:25 am</p>
                <p class="text-wrapper-20">12 Aug 2022 - 12:25 am</p>
                <p class="text-wrapper-21">12 Aug 2022 - 12:25 am</p>
                <p class="text-wrapper-22">12 Aug 2022 - 12:25 am</p>
                <p class="text-wrapper-23">12 Aug 2022 - 12:25 am</p>
                <p class="text-wrapper-24">12 Aug 2022 - 12:25 am</p>
                <p class="text-wrapper-25">12 Aug 2022 - 12:25 am</p>
              </div>
              <div class="customer-name">
                <div class="frame-6">
                  <div class="text-wrapper-11">Company Name</div>
                  <svg class="img" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M14 5.16669H2C1.72667 5.16669 1.5 4.94002 1.5 4.66669C1.5 4.39335 1.72667 4.16669 2 4.16669H14C14.2733 4.16669 14.5 4.39335 14.5 4.66669C14.5 4.94002 14.2733 5.16669 14 5.16669Z" fill="#00092E"/>
                    <path d="M12 8.5H4C3.72667 8.5 3.5 8.27333 3.5 8C3.5 7.72667 3.72667 7.5 4 7.5H12C12.2733 7.5 12.5 7.72667 12.5 8C12.5 8.27333 12.2733 8.5 12 8.5Z" fill="#00092E"/>
                    <path d="M9.33329 11.8333H6.66663C6.39329 11.8333 6.16663 11.6066 6.16663 11.3333C6.16663 11.06 6.39329 10.8333 6.66663 10.8333H9.33329C9.60663 10.8333 9.83329 11.06 9.83329 11.3333C9.83329 11.6066 9.60663 11.8333 9.33329 11.8333Z" fill="#00092E"/>
                    </svg>
                </div>
                <div class="text-wrapper-16">Albert Pitt</div>
                <div class="text-wrapper-17">Emma Timberland</div>
                <div class="text-wrapper-18">Jannet Ali</div>
                <div class="text-wrapper-19">Tim Washington</div>
                <div class="text-wrapper-20">Dwayne Dornan</div>
                <div class="text-wrapper-21">Lilly Jackson</div>
                <div class="text-wrapper-22">John Stone</div>
                <div class="text-wrapper-23">Roman D jr.</div>
                <div class="text-wrapper-24">Tony Montano</div>
                <div class="text-wrapper-25">Charlie DiCaprio</div>
              </div>
            </div>
          </div>
          <div class="summary-row"></div>
        </div>
      </div>
      
    
  </div>
  )
}

export default Invoice;

 
  
 

