import React, { useState } from "react";
import "./globals.css";
import "./style.css";
import "./styleguide.css";
import { useNavigate } from "react-router-dom";
//import {  Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import {
  pushAddressToList,
  pushToContactList,
  clearContactList,
  setCompanyNameAndMemberCount,
  clearCompanyData,
} from "../../redux/companyslice";
import ApiConstant from "../../constants/api_contants";

// function ErrorSummary({ errors }) {
//   if (Object.keys(errors).length === 0) {
//     return null;
//   }
//   return (
//     <div className="error-summary">
//       {Object.keys(errors).map((fieldName) => toast("Invalid " + fieldName))}
//     </div>
//   );
// }

const AddCustomer = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    formState,
    getValues,
  } = useForm();
  const { isSubmitting } = formState;
  const navigate = useNavigate();
  const [isPostingData, setPostingData] = useState(false);
  const [contactAddClickCount, setContactAddClickCount] = useState(0);

  console.log(isSubmitting);

  const companyData = useSelector((state) => state.company.companyData);
  const contactData = useSelector((state) => state.company.contactData);
  const dispatch = useDispatch();

  // const onSubmit = values =>{
  //   if (errors) {
  //     toast('Hello Geeks');
  //   }
  //   alert(values.email + " " + values.password);

  // }
  const addContact = () => {
    // trigger([
    //   "contactPhonePrefix",
    //   "contactPhoneNo",
    //   "contactEmail",
    //   "contactName",
    //   "companyEmployeeCount",
    //   "companyName",
    // ]);
    var form1 = document.getElementById("form");
    form1.requestSubmit();
    setContactAddClickCount(contactAddClickCount + 1);
    if (contactAddClickCount === 0) {
      toast("Click Again to Confirm");
      return;
    }

    if (
      errors["contactPhonePrefix"] === undefined &&
      errors["contactPhoneNumber"] === undefined &&
      errors["contactEmail"] === undefined &&
      errors["contactName"] === undefined &&
      errors["companyEmployeeCount"] === undefined &&
      errors["companyName"] === undefined
    ) {
      const contacts = {
        contact_phone_prefix: getValues("contactPhonePrefix"),
        contact_phone_number: getValues("contactPhoneNumber"),
        contact_email: getValues("contactEmail"),
        contact_name: getValues("contactName"),
      };
      dispatch(pushToContactList(contacts));
      const companyNameAndAddress = {
        company_name: getValues("companyName"),
        company_employee_count: getValues("companyEmployeeCount"),
      };
      dispatch(setCompanyNameAndMemberCount(companyNameAndAddress));

      console.log(`contactData ${contactData}`);
      console.log(`companyData ${companyData}`);
      console.log(companyData);
      navigate("/customer/addcustomer/addContact");
    } else {
      Object.keys(errors).forEach((fieldName) => {
        if (
          fieldName === "contactPhonePrefix" ||
          fieldName === "contactPhoneNumber" ||
          fieldName === "contactEmail" ||
          fieldName === "contactName" ||
          fieldName === "companyEmployeeCount" ||
          fieldName === "companyName"
        ) {
          toast("Invalid " + fieldName);
        }
      });
      console.log(errors["contactPhonePrefix"]);
      console.log(`errors ${errors}`);
    }
  };
  const addAddress = () => {
    setContactAddClickCount(contactAddClickCount + 1);
    console.log(`contactAddClickCount ${contactAddClickCount}`);
    var form1 = document.getElementById("form");
    form1.requestSubmit();
    // handleSubmit(onSubmit);
    // trigger();

    if (Object.keys(errors).length <= 0) {
      if (contactAddClickCount > 0) {
        const contacts = {
          contact_phone_prefix: getValues("contactPhonePrefix"),
          contact_phone_number: getValues("contactPhoneNumber"),
          contact_email: getValues("contactEmail"),
          contact_name: getValues("contactName"),
        };
        dispatch(pushToContactList(contacts));
        var tempContactList = [...contactData];

        tempContactList.push(contacts);
        console.log(tempContactList);
        const address = {
          company_city: getValues("companyCity"),
          company_zip_code: getValues("companyZipCode"),
          company_street_address: getValues("companyStreetAddress"),
          company_state_code: getValues("companyStateCode"),
          company_address_primary: 1,
          company_address_hq: 0,
          company_contacts: tempContactList,
        };
        // console.log(address);
        const companyNameAndAddress = {
          company_name: getValues("companyName"),
          company_employee_count: getValues("companyEmployeeCount"),
        };
        dispatch(setCompanyNameAndMemberCount(companyNameAndAddress));
        dispatch(pushAddressToList(address));
        dispatch(clearContactList());
        navigate("/customer/addcustomer/addContact");
        // reset(
        //   {
        //     city: "",
        //     postalcode: "",
        //     companyStreetAddress: "",
        //     companyStateCode: "",
        //     contactPhonePrefix: "",
        //     contactPhoneNo: "",
        //     contactEmail: "",
        //     contactName: "",
        //   },
        //   { keepTouched: false }
        // );
      } else {
        toast("Click Again to Confirm");
      }
    } else {
      Object.keys(errors).map((fieldName) => toast("Invalid " + fieldName));
      console.log(`errors ${errors}`);
      console.log(`errors one two testing`);
    }
  };
  const onSubmit = async (data) => {};
  const onSubmitData = async (data) => {
    //alert(data.email + " " + data.password);
    var form1 = document.getElementById("form");
    form1.requestSubmit();
    setContactAddClickCount(contactAddClickCount + 1);
    if (contactAddClickCount === 0) {
      toast("Click Again to Confirm");
      return;
    }

    if (Object.keys(errors).length > 0) {
      validate();
      return;
    }

    setPostingData(true);
    const jsonData = {
      company_name: getValues("companyName"),
      company_employee_count: getValues("companyEmployeeCount"),
      company_addresses: [
        {
          company_street_address: getValues("companyStreetAddress"),
          company_city: getValues("companyCity"),
          company_state_code: getValues("companyStateCode"),
          company_zip_code: getValues("companyZipCode"),
          company_address_primary: 1,
          company_address_hq: 0,
          company_contacts: [
            {
              contact_name: getValues("contactName"),
              contact_email: getValues("contactEmail"),
              contact_phone_prefix: getValues("contactPhonePrefix"),
              contact_phone_number: getValues("contactPhoneNumber"),
            },
          ],
        },
      ],
    };
    // dispatch(setCompanyData(jsonData));
    console.log(companyData);

    fetch(`${ApiConstant.BASE_URL}${ApiConstant.API}/company`, {
      method: "post",
      body: JSON.stringify(jsonData),
      headers: new Headers({
        "content-type": "application/json",
        Accept: "application/json",
      }),
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.status);
        // else return response.json();
        setPostingData(false);
      })
      .then((data) => {
        console.log("DATA STORED");
        toast("Company added successfully");
        setPostingData(false);
        dispatch(clearCompanyData());
        navigate("/customer");
      })
      .catch((error) => {
        console.log("error: " + error);
        setPostingData(false);
        toast("Company not added");
      });
    // const json = await response.json();
    // console.log(json);
    // if(json.status_code===200){
    //   toast("Company added successfully");
    // }else{
    //   toast("Company not added");
    // }
  };
  const validate = () => {
    Object.keys(errors).map((fieldName) => toast("Invalid " + fieldName));
  };

  // const sent = () => {
  //   Object.keys(errors).map((fieldName) => toast("Invalid " + fieldName));
  // };

  //companyZipCode companyCity companyStreetAddress
  //contactPhonePrefix contactPhoneNumber contactEmail contactName
  //companyName companyEmployeeCount

  return (
    <div class="element-customers">
      <form onSubmit={handleSubmit(onSubmit)} class="form" id="form">
        <ToastContainer />
        <div class="buttons">
          <button
            mat-button
            type="button"
            onClick={() => navigate(-1)}
            class="div-wrapper"
          >
            <div class="text-wrapper">Cancel</div>
          </button>
          <button
            disabled={isPostingData}
            onClick={onSubmitData}
            class="label-wrapper"
          >
            {/* <Link to="/customer/addcustomer/addContact">

           
         </Link> */}
            <div class="label1">{isPostingData ? "Please Wait.." : "Save"}</div>
          </button>
        </div>
        <div class="overlap-group">
          <div class="add-address">
            <div class="inputs">
              <svg
                type=" svg-button"
                onClick={addAddress}
                class="add-button"
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="35"
                viewBox="0 0 35 35"
                fill="none"
              >
                <rect width="35" height="35" rx="8" fill="#434343" />
                <g clip-path="url(#clip0_474_6113)">
                  <path
                    d="M26.4853 17.9706H9.51472"
                    stroke="#DDDDDD"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M18 9.48535V26.4559"
                    stroke="#DDDDDD"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_474_6113">
                    <rect
                      width="24"
                      height="24"
                      fill="white"
                      transform="translate(18 1) rotate(45)"
                    />
                  </clipPath>
                </defs>
              </svg>

              <div class="state">
                <div class="top">
                  <div class="input-content">
                    <div class="input-content-2">
                      <div class="text-wrapper-2">
                        <input
                          class="text-wrapper-6"
                          type="text"
                          placeholder="State"
                          {...register("companyStateCode", {
                            required: "Required",
                          })}
                        />
                      </div>
                    </div>
                    <svg
                      class="img"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M6 9L12 15L18 9"
                        stroke="#5E6366"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div class="zip-code">
                <div class="top">
                  <div class="input-content-3">
                    <input
                      class="text-wrapper-6"
                      type="text"
                      placeholder="zip-code"
                      {...register("companyZipCode", {
                        required: "Required",
                        pattern: {
                          value: /[0-9]{6}/,
                          message: "invalid zip code",
                        },
                      })}
                    />
                  </div>
                </div>
              </div>
              <div class="city">
                <div class="input">
                  <div class="top">
                    <div class="input-content-3">
                      <div class="text-wrapper-2">
                        <input
                          class="text-wrapper-6"
                          type="text"
                          placeholder="City"
                          {...register("companyCity", {
                            required: "Required",
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="input-wrapper">
                <div class="top-wrapper">
                  <div class="top">
                    <div class="input-content-3">
                      <div class="text-wrapper-2">
                        <input
                          class="text-wrapper-6"
                          type="text"
                          placeholder="street name or number"
                          {...register("companyStreetAddress", {
                            required: "Required",
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-wrapper-3">Add Address</div>
          </div>
          <div class="checkbox-primary">
            <div class="control">
              <div class="checkbox-radio">
                <div>
                  <label class="custom-checkbox2">
                    <input class="group" type="checkbox" />
                  </label>
                  <span class="checkmark2"></span>
                </div>
                <div class="control-wrapper">
                  <div class="fi-check-wrapper"></div>
                </div>
              </div>
            </div>
            <div class="checkbox-radio-2">
              <div class="text-wrapper-4">Primary</div>
            </div>
          </div>
          <div class="checkbox-hq">
            <div class="div-2">
              <label class="custom-checkbox2">
                <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
            </div>
            <div class="checkbox-radio-3">
              <div class="text-wrapper-5">HQ</div>
            </div>
          </div>
        </div>
        <div class="contact-information">
          <div class="inputs-2">
            <div class="phone-no">
              <div class="frame">
                <div class="phone">
                  <div class="country-code">
                    <div class="input-content-4">
                      <div class="input-content-wrapper">
                        <div class="input-content-5">
                          <div class="text-wrapper-2">
                            <input
                              class="text-wrapper-6"
                              type="text"
                              placeholder="+234"
                              {...register("contactPhonePrefix", {
                                required: "Required",
                                pattern: {
                                  value: /^0|\+[0-9]{1,5}$/,
                                  message: "invalid phone prefix",
                                },
                              })}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="iconly-light-arrow"></div>
                    </div>
                  </div>
                  <div class="input-2">
                    <div class="input-content">
                      <div class="input-content-2">
                        <div class="text-wrapper-2">
                          <input
                            class="text-wrapper-6"
                            type="text"
                            placeholder="78966541001"
                            {...register("contactPhoneNumber", {
                              required: "Required",
                              pattern: {
                                value: /^[0]?[6789]\d{9}$/,
                                message: "invalid phone number",
                              },
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <svg
                type="svg-button"
                onClick={addContact}
                class="frame-2"
                xmlns="http://www.w3.org/2000/svg"
                width="35"
                height="35"
                viewBox="0 0 35 35"
                fill="none"
              >
                <rect width="35" height="35" rx="8" fill="#434343" />
                <g clip-path="url(#clip0_474_6163)">
                  <path
                    d="M26.4853 17.9706H9.51472"
                    stroke="#DDDDDD"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M18 9.48535V26.4559"
                    stroke="#DDDDDD"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_474_6163">
                    <rect
                      width="24"
                      height="24"
                      fill="white"
                      transform="translate(18 1) rotate(45)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div class="contact-email">
              <div class="top-wrapper">
                <div class="top">
                  <div class="input-content-3">
                    <div class="text-wrapper-2">
                      <input
                        class="text-wrapper-6"
                        type="text"
                        placeholder="Contact Email"
                        {...register("contactEmail", {
                          required: "Required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address",
                          },
                        })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="input-wrapper">
              <div class="top-wrapper">
                <div class="top">
                  <div class="input-content-3">
                    <div class="text-wrapper-2">
                      {" "}
                      <input
                        class="text-wrapper-6"
                        type="text"
                        placeholder="Contact Name"
                        {...register("contactName", {
                          required: "Required",
                        })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="text-wrapper-3">Contact Information</div>
        </div>
        <div class="add-company-info">
          <div class="inputs-3">
            <div class="name">
              <div class="input-3">
                <div class="top-2">
                  <div class="input-content-3">
                    <div class="text-wrapper-2">
                      {" "}
                      <input
                        class="text-wrapper-6"
                        type="text"
                        placeholder="Name"
                        {...register("companyName", {
                          required: "Required",
                        })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="no-of-employees">
              <div class="input-4">
                <div class="top-2">
                  <div class="input-content-3">
                    <div class="text-wrapper-2">
                      <input
                        class="text-wrapper-6"
                        type="text"
                        placeholder="No of Employees"
                        {...register("companyEmployeeCount", {
                          required: "Required",
                          pattern: {
                            value: /^[0-9]*$/,
                            message: "invalid employee count",
                          },
                        })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="frame-3">
            <div class="text-wrapper-6">Add company info</div>
          </div>
        </div>
        <div class="heading">
          <div class="text-wrapper-7">Add a New Customer</div>
        </div>
      </form>
      <div class="table">
        <div class="company-names">
          <div class="group">
            <div class="text-wrapper-8">More</div>
            <div class="div-2">
              <label class="custom-checkbox2">
                <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
            </div>
            <div class="text-wrapper-9">Charlie DiCaprio</div>
          </div>
          <div class="group-2">
            <div class="text-wrapper-8">More</div>
            <div class="div-2">
              <label class="custom-checkbox2">
                <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
            </div>
            <div class="text-wrapper-9">Tony Montano</div>
          </div>
          <div class="group-3">
            <div class="text-wrapper-8">More</div>
            <div class="div-2">
              <label class="custom-checkbox2">
                <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
            </div>
            <div class="text-wrapper-9">Roman D jr.</div>
          </div>
          <div class="group-4">
            <div class="text-wrapper-8">More</div>
            <div class="div-2">
              <label class="custom-checkbox2">
                <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
            </div>
            <div class="text-wrapper-9">DiCaprio’s bar</div>
          </div>
          <div class="group-5">
            <div class="text-wrapper-8">More</div>
            <div class="div-2">
              <label class="custom-checkbox2">
                <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
            </div>
            <div class="text-wrapper-9">Tom &amp; Will</div>
          </div>
          <div class="group-6">
            <div class="text-wrapper-8">More</div>
            <div class="div-2">
              <label class="custom-checkbox2">
                <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
            </div>
            <div class="text-wrapper-9">Roman Logistics</div>
          </div>
          <div class="group-7">
            <div class="text-wrapper-10">More</div>
            <div class="div-2">
              <label class="custom-checkbox2">
                <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
            </div>
            <div class="text-wrapper-11">Charlie DiCaprio</div>
          </div>
          <div class="group-8">
            <div class="text-wrapper-10">More</div>
            <div class="div-2">
              <label class="custom-checkbox2">
                <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
            </div>
            <div class="text-wrapper-11">John &amp; Stone</div>
          </div>
          <div class="group-9">
            <div class="text-wrapper-10">More</div>
            <div class="div-2">
              <label class="custom-checkbox2">
                <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
            </div>
            <div class="text-wrapper-11">Roman D jr.</div>
          </div>
          <div class="group-10">
            <div class="text-wrapper-10">More</div>
            <div class="div-2">
              <label class="custom-checkbox2">
                <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
            </div>
            <div class="text-wrapper-11">Jackson waves</div>
          </div>
          <div class="group-11">
            <div class="text-wrapper-10">More</div>
            <div class="div-2">
              <label class="custom-checkbox2">
                <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
            </div>
            <div class="text-wrapper-11">Dornan Pvt Ltd</div>
          </div>
          <div class="group-12">
            <div class="text-wrapper-10">More</div>
            <div class="div-2">
              <label class="custom-checkbox2">
                <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
            </div>
            <div class="text-wrapper-11">Washington Ltd.</div>
          </div>
          <div class="group-13">
            <div class="text-wrapper-10">More</div>
            <div class="div-2">
              <label class="custom-checkbox2">
                <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
            </div>
            <div class="text-wrapper-11">Tim Washington</div>
          </div>
          <div class="group-14">
            <div class="text-wrapper-10">More</div>
            <div class="div-2">
              <label class="custom-checkbox2">
                <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
            </div>
            <div class="text-wrapper-11">Denver Ports</div>
          </div>
          <div class="group-15">
            <div class="text-wrapper-10">More</div>
            <div class="div-2">
              <label class="custom-checkbox2">
                <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
            </div>
            <div class="text-wrapper-11">Tommy Exporters</div>
          </div>
          <div class="group-16">
            <div class="text-wrapper-12">More</div>
            <div class="div-2">
              <label class="custom-checkbox2">
                <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
            </div>
            <div class="text-wrapper-11">Vr Logistics</div>
          </div>
        </div>
        <div class="company-name-heading">
          <div class="text-wrapper-13">Company Name</div>
          <svg
            class="img-2"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M14 5.1665H2C1.72667 5.1665 1.5 4.93984 1.5 4.6665C1.5 4.39317 1.72667 4.1665 2 4.1665H14C14.2733 4.1665 14.5 4.39317 14.5 4.6665C14.5 4.93984 14.2733 5.1665 14 5.1665Z"
              fill="#00092E"
            />
            <path
              d="M12 8.5H4C3.72667 8.5 3.5 8.27333 3.5 8C3.5 7.72667 3.72667 7.5 4 7.5H12C12.2733 7.5 12.5 7.72667 12.5 8C12.5 8.27333 12.2733 8.5 12 8.5Z"
              fill="#00092E"
            />
            <path
              d="M9.33341 11.8335H6.66675C6.39341 11.8335 6.16675 11.6068 6.16675 11.3335C6.16675 11.0602 6.39341 10.8335 6.66675 10.8335H9.33341C9.60675 10.8335 9.83341 11.0602 9.83341 11.3335C9.83341 11.6068 9.60675 11.8335 9.33341 11.8335Z"
              fill="#00092E"
            />
          </svg>
        </div>
      </div>
      <div class="frame-wrapper">
        <div class="bar">
          <div class="search">
            <div class="search-icon-bar">
              <svg
                class="iconly-light-search"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
              >
                <circle
                  cx="9.80549"
                  cy="10.3055"
                  r="7.49047"
                  stroke="#130F26"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M15.0153 15.9043L17.9519 18.8333"
                  stroke="#130F26"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <input class="text-wrappers" type="text" placeholder="Search" />
          </div>

          <div class="filters">
            <div class="filter-icons">
              <svg
                class="icn"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
              >
                <path
                  d="M14.6667 2.5H1.33333L6.66666 8.80667V13.1667L9.33333 14.5V8.80667L14.6667 2.5Z"
                  stroke="#53545C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div class="label">Filter</div>
          </div>
          <div class="filter-2">
            <div class="filter2-icon"></div>
            <input type="date" id="calendar" name="calendar" />
            <div class="label">Filter</div>
          </div>
          <div class="bulk-actions">
            <div class="label">Bulk action</div>
            <div class="blkicon">
              <svg
                class="icn"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
              >
                <path
                  d="M4 6.5L8 10.5L12 6.5"
                  stroke="#53545C"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div class="dropdown-1">
              <ul>
                <li>
                  <input type="checkbox" id="selectAll" />
                  <label for="selectAll">Select all </label>
                </li>
                {/* <!-- <li>
                  <input type="checkbox" id="selectActive" />
                  <label for="selectActive">Select active customers</label>
                </li>
                <li>
                  <input type="checkbox" id="selectInactive" />
                  <label for="selectInactive"
                    >Select inactive customers</label
                  >
                </li> -->*/}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCustomer;
