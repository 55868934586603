import React from "react";
import './globals.css';
import './style.css';
import './styleguide.css';
  
const AddCreditnote=()=>{
  return(
    <div class="add-credit-note">
    
  
      <div class="top-nav">
        <div class="nav-container-wrapper">
          <div class="nav-container">
            <div class="text-wrapper">Credit Note</div>
            <div class="profile">
           
              <div class="profile-2"></div>
            </div>
          </div>
        </div>
        <div class="breadcrumbs">
          <div class="div-2">
            <a href="../dashboard/index.html">

              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M6.09578 13.8547V11.8102C6.09577 11.2921 6.51707 10.8711 7.03892 10.8679H8.95503C9.47921 10.8679 9.90414 11.2898 9.90414 11.8102V13.8488C9.90413 14.2982 10.2693 14.6634 10.7219 14.6666H12.0292C12.6397 14.6682 13.2258 14.4285 13.6581 14.0005C14.0903 13.5724 14.3333 12.9912 14.3333 12.385V6.57723C14.3333 6.08759 14.1147 5.62314 13.7364 5.309L9.29528 1.78285C8.51897 1.16608 7.41021 1.18601 6.65689 1.83026L2.3113 5.309C1.91512 5.61388 1.67833 6.07971 1.66663 6.57723V12.3791C1.66663 13.6425 2.69821 14.6666 3.97074 14.6666H5.24815C5.46607 14.6682 5.67562 14.5834 5.83027 14.431C5.98493 14.2785 6.07191 14.0711 6.0719 13.8547H6.09578Z" fill="#434343"/>
                </svg>     
            </a>
            <div class="div-2">
              <div class="text-wrapper-2">/</div>
              <div class="text-wrapper-2">Credit Note</div>
            </div>
            <div class="item">
              <div class="text-wrapper-2">/</div>
              <div class="text-wrapper-2">Page</div>
            </div>
            <div class="item">
              <div class="text-wrapper-2">/</div>
              <div class="text-wrapper-2">Page</div>
            </div>
            <div class="item">
              <div class="text-wrapper-2">/</div>
              <div class="text-wrapper-2">Page</div>
            </div>
          </div>
        </div>
      </div>


     
      <header class="header">
        <div class="bar">
          <div class="search">
            <div class="search-icon-bar">
              <svg
                class="iconly-light-search"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
              >
                <circle
                  cx="9.80549"
                  cy="10.3055"
                  r="7.49047"
                  stroke="#130F26"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M15.0153 15.9043L17.9519 18.8333"
                  stroke="#130F26"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <input
            class="text-wrappers"
            type="text"
            placeholder="Search"
          />

          </div>

          <div class="filters">
            <div class="filter-icons">
              <svg
                class="icn"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
              >
                <path
                  d="M14.6667 2.5H1.33333L6.66666 8.80667V13.1667L9.33333 14.5V8.80667L14.6667 2.5Z"
                  stroke="#53545C"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div class="label">Filter</div>
          </div>
          <div class="filter-2">
            <div class="filter2-icon">
             
            </div>
            <input type="date" id="calendar" name="calendar" />

            <div class="label">Filter</div>
          </div>
          <div class="bulk-actions">
            <div class="label">Bulk action</div>
            <div class="blkicon">
              <svg
                class="icn"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
              >
                <path
                  d="M4 6.5L8 10.5L12 6.5"
                  stroke="#53545C"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div class="dropdown-1">
              <ul>
                <li>
                  <input type="checkbox" id="selectAll" />
                  <label for="selectAll">Select all </label>
                </li>
                {/*<li>
                  <input type="checkbox" id="selectActive" />
                  <label for="selectActive">Select active customers</label>
                </li>
                <li>
                  <input type="checkbox" id="selectInactive" />
                  <label for="selectInactive"
                    >Select inactive customers</label
                  >
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </header>
      <div class="form">
        <div class="buttons">
          <button class="cancel-button">
            <div class="text-wrapper-4">Cancel</div>
          </button>
          <button class="save-button">
            <div class="text-wrapper-5">Save</div>
          </button>
        </div>
        <div class="inputs">
          <div class="payment-receipt">
            <div class="frame">
              <div class="top">
                <div class="input-content">
                  <div class="div-wrapper">
                    <input
                      class="text-wrapper-6"
                      type="text"
                      placeholder="Payment Recipt Reference"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="invoice-reference">
            <div class="top">
              <div class="input-content">
                <div class="div-wrapper">
                  <input
                    class="text-wrapper-6"
                    type="text"
                    placeholder="Invloice Reference"
                  />
                </div>
                <svg
                  class="fi-chevron-down"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M6 9L12 15L18 9"
                    stroke="#5E6366"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div class="credit-date">
            <div class="input">
              <div class="top">
                <div class="input-content-2">
                {/*<!-- <input
                    class="text-wrapper-6"
                    type="text"
                    placeholder="Credit Date"
              /> -->*/}
                  <input type="date" id="calendar" class="text-wrapper-6" name="calendar" />
                </div>
              </div>
            </div>
          </div>
          <div class="credit-amount">
            <div class="top-wrapper">
              <div class="top">
                <div class="input-content-2">
                  <input
                    class="text-wrapper-6"
                    type="text"
                    placeholder="Credit amount"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="company-name">
            <div class="input-2">
              <div class="top-2">
                <div class="input-content-3">
                  <input
                    class="text-wrapper-6"
                    type="text"
                    placeholder="Company name"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <p class="p">Add a New Credit Note</p>
      </div>
      <div class="companies-list">
        <div class="company-names">
          <div class="group">
            <div class="text-wrapper-7">More</div>
            <div class="controls">
              <label class="custom-checkbox2">
                <input class="group" type="checkbox" />
                <span class="checkmark2"></span>
              </label>
            </div>
            <div class="text-wrapper-8">Albert Pitt</div>
          </div>
          <div class="group-2">
            <div class="text-wrapper-9">More</div>
            <div class="controls">
              <label class="custom-checkbox2">
                <input class="group" type="checkbox" />
                <span class="checkmark2"></span>
              </label>
            </div>
            <div class="text-wrapper-8">Emma Timberland</div>
          </div>
          <div class="group-3">
            <div class="text-wrapper-9">More</div>
            <div class="controls">
              <label class="custom-checkbox2">
                <input class="group" type="checkbox" />
                <span class="checkmark2"></span>
              </label>
            </div>
            <div class="text-wrapper-8">Jannet Ali</div>
          </div>
          <div class="group-4">
            <div class="text-wrapper-9">More</div>
            <div class="controls">
              <label class="custom-checkbox2">
                <input class="group" type="checkbox" />
                <span class="checkmark2"></span>
              </label>
            </div>
            <div class="text-wrapper-8">Tim Washington</div>
          </div>
          <div class="group-5">
            <div class="text-wrapper-9">More</div>
            <div class="controls">
              <label class="custom-checkbox2">
                <input class="group" type="checkbox" />
                <span class="checkmark2"></span>
              </label>
            </div>
            <div class="text-wrapper-8">Dwayne Dornan</div>
          </div>
          <div class="group-6">
            <div class="text-wrapper-9">More</div>
            <div class="controls">
              <label class="custom-checkbox2">
                <input class="group" type="checkbox" />
                <span class="checkmark2"></span>
              </label>
            </div>
            <div class="text-wrapper-8">Lilly Jackson</div>
          </div>
          <div class="group-7">
            <div class="text-wrapper-9">More</div>
            <div class="controls">
              <label class="custom-checkbox2">
                <input class="group" type="checkbox" />
                <span class="checkmark2"></span>
              </label>
            </div>
            <div class="text-wrapper-8">John Stone</div>
          </div>
          <div class="group-8">
            <div class="text-wrapper-9">More</div>
            <div class="controls">
              <label class="custom-checkbox2">
                <input class="group" type="checkbox" />
                <span class="checkmark2"></span>
              </label>
            </div>
            <div class="text-wrapper-8">Roman D jr.</div>
          </div>
          <div class="group-9">
            <div class="text-wrapper-9">More</div>
            <div class="controls">
              <label class="custom-checkbox2">
                <input class="group" type="checkbox" />
                <span class="checkmark2"></span>
              </label>
            </div>
            <div class="text-wrapper-8">Tony Montano</div>
          </div>
          <div class="group-10">
            <div class="text-wrapper-9">More</div>
            <div class="controls">
              <label class="custom-checkbox2">
                <input class="group" type="checkbox" />
                <span class="checkmark2"></span>
              </label>
            </div>
            <div class="text-wrapper-8">Charlie DiCaprio</div>
          </div>
          <div class="group-11">
            <div class="text-wrapper-10">More</div>
            <div class="controls">
              <label class="custom-checkbox2">
                <input class="group" type="checkbox" />
                <span class="checkmark2"></span>
              </label>
            </div>
            <div class="text-wrapper-11">Dwayne Dornan</div>
          </div>
          <div class="group-12">
            <div class="text-wrapper-10">More</div>
            <div class="controls">
              <label class="custom-checkbox2">
                <input class="group" type="checkbox" />
                <span class="checkmark2"></span>
              </label>
            </div>
            <div class="text-wrapper-11">Lilly Jackson</div>
          </div>
          <div class="group-13">
            <div class="text-wrapper-10">More</div>
            <div class="controls">
              <label class="custom-checkbox2">
                <input class="group" type="checkbox" />
                <span class="checkmark2"></span>
              </label>
            </div>
            <div class="text-wrapper-11">John Stone</div>
          </div>
          <div class="group-14">
            <div class="text-wrapper-10">More</div>
            <div class="controls">
              <label class="custom-checkbox2">
                <input class="group" type="checkbox" />
                <span class="checkmark2"></span>
              </label>
            </div>
            <div class="text-wrapper-11">Roman D jr.</div>
          </div>
          <div class="group-15">
            <div class="text-wrapper-10">More</div>
            <div class="controls">
              <label class="custom-checkbox2">
                <input class="group" type="checkbox" />
                <span class="checkmark2"></span>
              </label>
            </div>
            <div class="text-wrapper-11">Tony Montano</div>
          </div>
          <div class="group-16">
            <div class="text-wrapper-10">More</div>
            <div class="controls">
              <label class="custom-checkbox2">
                <input class="group" type="checkbox" />
                <span class="checkmark2"></span>
              </label>
            </div>
            <div class="text-wrapper-11">Charlie DiCaprio</div>
          </div>
        </div>
        <div class="company-name-header-wrapper">
          <div class="company-name-header">
            <div class="text-wrapper-12">Company Name</div>
          </div>
        </div>
      </div>
  
  </div>
  )
}
   
 

export default AddCreditnote;