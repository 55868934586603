import linkToEachOther from "../assests/link-to-each-other-1@2x.png";
import { useCollapse } from "react-collapsed";
import { useForm, useFormContext } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
var converter = require("number-to-words");

export default function ContactTile(props) {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
  const companyData = useSelector((state) => state.company.companyData);
  const dispatch = useDispatch();
  const { setValue } = useFormContext();
  const editContact = (index) => {
    console.log(`index ${index}`);
    const form1 = document.getElementById("form");
    form1.style.visibility = "visible";
    setValue("city", companyData.company_addresses[index].company_city);
    // setValue("city", "hello");
  };
  return (
    <div class="overlap">
      <div class="contact-one">
        <div className="contact-collapse-group" {...getToggleProps()}>
          <div className="contact-tile-head">
            <div class="col-contents">
              <label class="custom-checkbox2">
                <input class="group" type="checkbox" />
              </label>
            </div>
            <div class="text-wrapper-14">
              Contact {converter.toWords(props.index + 1)}
            </div>
          </div>
          {isExpanded ? (
            <svg
              class="fi-x-2"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M18 6L6 18"
                stroke="#383838"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6 6L18 18"
                stroke="#383838"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          ) : (
            <svg
              class="fi-x"
              xmlns="http://www.w3.org/2000/svg"
              width="34"
              height="34"
              viewBox="0 0 34 34"
              fill="none"
            >
              <g clip-path="url(#clip0_153_34680)">
                <path
                  d="M16.9999 8.51472V25.4853"
                  stroke="#383838"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.51452 16.9999H25.4851M-31.9704 -65.5003H-14.9998"
                  stroke="#383838"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_153_34680">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(0.0292969 17) rotate(-45)"
                  />
                </clipPath>
              </defs>
            </svg>
          )}
        </div>

        <svg
          class="line-7"
          xmlns="http://www.w3.org/2000/svg"
          width="371"
          height="1"
          viewBox="0 0 371 1"
          fill="none"
        >
          <line y1="0.5" x2="371" y2="0.5" stroke="#B1B1B1" />
        </svg>

        <div {...getCollapseProps()}>
          <div className="contact-details">
            <div class="contact-one-details">
              <div class="group-13">
                <div class="text-wrapper-15">Contact Name</div>
                <div class="text-wrapper-16">{props.object.contact_name}</div>
              </div>
              <div class="group-14">
                <div class="text-wrapper-15">Contact Phone No.</div>
                <div class="text-wrapper-16">{`${props.object.contact_phone_prefix}-${props.object.contact_phone_number}`}</div>
              </div>
              <div class="group-15">
                <div class="text-wrapper-15">Contact Email</div>
                <div class="text-wrapper-16">{props.object.contact_email}</div>
              </div>
            </div>

            <div className="edit-and-link">
              <svg
                onClick={() => editContact(props.index)}
                class="edit"
                id="edit"
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
              >
                <g clip-path="url(#clip0_153_34714)">
                  <path
                    d="M6.33228 17.4163V18.0101H6.57822L6.75212 17.8362L6.33228 17.4163ZM17.4156 6.33301L17.8354 6.75285C18.0673 6.52098 18.0673 6.14503 17.8354 5.91316L17.4156 6.33301ZM1.58301 12.6663L1.16315 12.2465L0.989258 12.4204V12.6663H1.58301ZM12.6656 1.58301L13.0854 1.16316C12.9741 1.05181 12.8231 0.989258 12.6656 0.989258C12.5081 0.989258 12.3571 1.05182 12.2457 1.16318L12.6656 1.58301ZM1.58301 17.4163H0.989258V18.0101H1.58301V17.4163ZM6.75212 17.8362L17.8354 6.75285L16.9957 5.91316L5.91244 16.9965L6.75212 17.8362ZM2.00287 13.0862L13.0854 2.00284L12.2457 1.16318L1.16315 12.2465L2.00287 13.0862ZM12.2457 2.00285L16.9957 6.75285L17.8354 5.91316L13.0854 1.16316L12.2457 2.00285ZM6.33228 16.8226H1.58301V18.0101H6.33228V16.8226ZM2.17676 17.4163V12.6663H0.989258V17.4163H2.17676Z"
                    fill="#383838"
                  />
                  <path
                    d="M9.5 4.75L14.25 9.5"
                    stroke="#383838"
                    stroke-width="0.890625"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.292 17.417H17.417"
                    stroke="#383838"
                    stroke-width="0.890625"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_153_34714">
                    <rect width="19" height="19" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <img class="link-to-each-other" src={linkToEachOther} alt="" />
            </div>
          </div>
          <svg
            class="line-7"
            xmlns="http://www.w3.org/2000/svg"
            width="371"
            height="1"
            viewBox="0 0 371 1"
            fill="none"
          >
            <line y1="0.5" x2="371" y2="0.5" stroke="#B1B1B1" />
          </svg>
        </div>
      </div>
    </div>
  );
}
