import React, { useEffect, useState } from "react";
import "./globals.css";
import "./style.css";
import "./styleguide.css";
import { Link } from "react-router-dom";
import ApiConstant from "../../constants/api_contants";
import { BeatLoader } from "react-spinners";

const Customer = () => {
  console.log(`${ApiConstant.BASE_URL}${ApiConstant.API}/company`);
  const [customersList, setCustomersList] = useState({});
  const [isLoading, setLoading] = useState(true);
  console.log(customersList);

  // const getCompany = async () => {
  //   const resp = await fetch(
  //     `${ApiConstant.BASE_URL}${ApiConstant.API}/company`
  //   );
  //   const companyResp = await resp.json();
  //   for (let i = 0; i < companyResp.length; i++) {
  //     const element = companyResp[i];
  //     const addressResponse = await fetch(
  //       `${ApiConstant.BASE_URL}${ApiConstant.API}/company/${element.company_id}/company_address`
  //     );
  //     console.log(element);
  //     const addressResp = await addressResponse.json();
  //     companyResp[i].company_addresses = addressResp;
  //     for (let j = 0; j < addressResp.length; j++) {
  //       const addressElement = addressResp[j];
  //       const contactResponse = await fetch(
  //         `${ApiConstant.BASE_URL}${ApiConstant.API}/company/${addressElement.address_id}/company_contact`
  //       );
  //       const contactResp = await contactResponse.json();
  //       console.log(contactResp);
  //       companyResp[i].company_addresses[j].company_contacts = contactResp;
  //     }
  //   }
  //   setCustomersList(companyResp);
  //   console.log(companyResp);

  //   setLoading(false);
  // };

  const getCompanyV2 = async () => {
    const resp = await fetch(
      `${ApiConstant.BASE_URL}${ApiConstant.API}/company`
    );
    const companyResp = await resp.json();
    for (let i = 0; i < companyResp.length; i++) {
      const element = companyResp[i];
      const addressResponse = await fetch(
        `${ApiConstant.BASE_URL}${ApiConstant.API}/company/${element.company_id}/company_address`
      );
      console.log(element);
      const addressResp = await addressResponse.json();
      companyResp[i].company_addresses = addressResp;
      if (addressResp.length > 0) {
        const addressElement = addressResp[0];
        const contactResponse = await fetch(
          `${ApiConstant.BASE_URL}${ApiConstant.API}/company/${addressElement.address_id}/company_contact`
        );
        const contactResp = await contactResponse.json();
        console.log(contactResp);
        companyResp[i].company_addresses[0].company_contacts = contactResp;
      }
    }
    setCustomersList(companyResp);
    console.log(companyResp);

    setLoading(false);
  };

  useEffect(() => {
    getCompanyV2();
  }, []);

  if (isLoading) {
    return (
      <div className="spinner">
        <BeatLoader loading />
      </div>
    );
  }

  return (
    <div className="customers">
      <div className="top-nav">
        <div className="nav-container-wrapper">
          <div className="div-2">
            <div className="text-wrapper">Customers</div>
            <div className="profile">
              <svg
                className="iconly-bulk"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M16.4747 9.70428C15.8659 8.99341 15.5893 8.37738 15.5893 7.3308V6.97495C15.5893 5.61112 15.2754 4.73239 14.593 3.85367C13.5411 2.489 11.7704 1.6665 10.0369 1.6665H9.96323C8.26621 1.6665 6.55097 2.45123 5.48091 3.76051C4.76119 4.65686 4.41085 5.57335 4.41085 6.97495V7.3308C4.41085 8.37738 4.15245 8.99341 3.52549 9.70428C3.06417 10.228 2.91675 10.9011 2.91675 11.6296C2.91675 12.3589 3.1561 13.0497 3.63647 13.6111C4.26343 14.2842 5.1488 14.7139 6.05321 14.7886C7.36262 14.938 8.67203 14.9943 10.0005 14.9943C11.3281 14.9943 12.6375 14.9003 13.9478 14.7886C14.8514 14.7139 15.7367 14.2842 16.3637 13.6111C16.8432 13.0497 17.0834 12.3589 17.0834 11.6296C17.0834 10.9011 16.936 10.228 16.4747 9.70428Z"
                  fill="#DDDDDD"
                />
                <path
                  opacity="0.4"
                  d="M11.674 16.0233C11.2574 15.9343 8.71888 15.9343 8.30229 16.0233C7.94616 16.1055 7.56104 16.2969 7.56104 16.7165C7.58174 17.1168 7.81613 17.4702 8.14079 17.6943L8.13996 17.6951C8.55987 18.0224 9.05266 18.2306 9.56864 18.3053C9.8436 18.343 10.1235 18.3414 10.4084 18.3053C10.9236 18.2306 11.4164 18.0224 11.8363 17.6951L11.8355 17.6943C12.1601 17.4702 12.3945 17.1168 12.4152 16.7165C12.4152 16.2969 12.0301 16.1055 11.674 16.0233Z"
                  fill="#DDDDDD"
                />
              </svg>
              <div className="profile-2"></div>
            </div>
          </div>
        </div>
        <div className="breadcrumbs">
          <div className="div-3">
            <a href="../dashboard/index.html">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M6.09578 13.8547V11.8102C6.09577 11.2921 6.51707 10.8711 7.03892 10.8679H8.95503C9.47921 10.8679 9.90414 11.2898 9.90414 11.8102V13.8488C9.90413 14.2982 10.2693 14.6634 10.7219 14.6666H12.0292C12.6397 14.6682 13.2258 14.4285 13.6581 14.0005C14.0903 13.5724 14.3333 12.9912 14.3333 12.385V6.57723C14.3333 6.08759 14.1147 5.62314 13.7364 5.309L9.29528 1.78285C8.51897 1.16608 7.41021 1.18601 6.65689 1.83026L2.3113 5.309C1.91512 5.61388 1.67833 6.07971 1.66663 6.57723V12.3791C1.66663 13.6425 2.69821 14.6666 3.97074 14.6666H5.24815C5.46607 14.6682 5.67562 14.5834 5.83027 14.431C5.98493 14.2785 6.07191 14.0711 6.0719 13.8547H6.09578Z"
                  fill="#434343"
                />
              </svg>
            </a>
            <div className="div-3">
              <div className="text-wrapper-2">/</div>
              <div className="text-wrapper-2">Customers</div>
            </div>
            <div className="item">
              <div className="text-wrapper-2">/</div>
              <div className="text-wrapper-2">Page</div>
            </div>
            <div className="item">
              <div className="text-wrapper-2">/</div>
              <div className="text-wrapper-2">Page</div>
            </div>
            <div className="item">
              <div className="text-wrapper-2">/</div>
              <div className="text-wrapper-2">Page</div>
            </div>
          </div>
        </div>
      </div>

      <header className="header">
        <div className="text-wrapper-5">Customers Summary</div>
        <Link to="/customer/addcustomer">
          <div className="buttons">
            <div className="label-container">
              <svg
                className="img-4"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12 5V19"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5 12H19"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <div className="text-wrapper-6">Add a New Customer</div>
            </div>
          </div>
        </Link>
      </header>
      <div className="summary-row">
        <div className="dashboard-summary">
          <div className="summary-container">
            <div className="div-2">
              <div className="icon">
                <svg
                  className="img-5"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.99293 12.6724C11.0671 12.6724 13.6946 13.1382 13.6946 14.999C13.6946 16.8599 11.0846 17.339 7.99293 17.339C4.91793 17.339 2.29126 16.8774 2.29126 15.0157C2.29126 13.154 4.90043 12.6724 7.99293 12.6724Z"
                    stroke="#DDDDDD"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.99289 10.0163C5.97456 10.0163 4.33789 8.38051 4.33789 6.36217C4.33789 4.34384 5.97456 2.70801 7.99289 2.70801C10.0104 2.70801 11.6471 4.34384 11.6471 6.36217C11.6546 8.37301 10.0296 10.0088 8.01872 10.0163H7.99289Z"
                    stroke="#DDDDDD"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M13.736 9.0679C15.0701 8.8804 16.0976 7.7354 16.1001 6.34956C16.1001 4.98373 15.1043 3.8504 13.7985 3.63623"
                    stroke="#DDDDDD"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15.4962 12.2769C16.7887 12.4694 17.6912 12.9227 17.6912 13.856C17.6912 14.4985 17.2662 14.9152 16.5795 15.176"
                    stroke="#DDDDDD"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div className="filter">
                <div className="text-wrapper-7"></div>
                <div className="select-container">
                  <select className="dropdown">
                    <option value="thismonth">This Week</option>
                    <option value="ThisMonth">This Month</option>
                    <option value="ThisQuarter">This Quarter</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="frame-2">
              <div className="frame-3">
                <div className="text-wrapper-8">All Customers</div>
                <div className="frame-4">
                  <div className="text-wrapper-9">70</div>
                  <div className="text-wrapper-10">+15.80%</div>
                </div>
              </div>
              <div className="frame-3">
                <div className="text-wrapper-8">Active</div>
                <div className="frame-4">
                  <div className="text-wrapper-9">58</div>
                  <div className="text-wrapper-10">+85%</div>
                </div>
              </div>
              <div className="frame-3">
                <div className="text-wrapper-8">In-Active</div>
                <div className="frame-4">
                  <div className="text-wrapper-9">10</div>
                  <div className="text-wrapper-11">-10%</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dashboard-summary">
          <div className="summary-container">
            <div className="div-2">
              <div className="icon">
                <svg
                  className="img-5"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13.7615 17.9166H6.80495C4.24965 17.9166 2.28931 16.9936 2.84614 13.2789L3.4945 8.24457C3.83775 6.39102 5.02005 5.68164 6.05743 5.68164H14.5395C15.5921 5.68164 16.7058 6.44442 17.1024 8.24457L17.7508 13.2789C18.2237 16.5741 16.3168 17.9166 13.7615 17.9166Z"
                    stroke="#DDDDDD"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M13.8758 5.49877C13.8758 3.51038 12.2639 1.89847 10.2755 1.89847V1.89847C9.31797 1.89441 8.3983 2.27194 7.71981 2.94757C7.04131 3.62319 6.6599 4.54127 6.65991 5.49877H6.65991"
                    stroke="#DDDDDD"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12.7469 9.25151H12.7088"
                    stroke="#DDDDDD"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.88801 9.25151H7.84987"
                    stroke="#DDDDDD"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div className="filter">
                <div className="text-wrapper-7"></div>
                <div className="select-container">
                  <select className="dropdown">
                    <option value="thismonth">This Week</option>
                    <option value="ThisMonth">This Month</option>
                    <option value="ThisQuarter">This Quarter</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="frame-2">
              <div className="frame-3">
                <div className="text-wrapper-8">New Customers</div>
                <div className="frame-4">
                  <div className="text-wrapper-9">20</div>
                  <div className="text-wrapper-11">-20%</div>
                </div>
              </div>
              <div className="frame-3">
                <div className="text-wrapper-8">New Billing</div>
                <div className="frame-4">
                  <div className="text-wrapper-9">657</div>
                  <div className="text-wrapper-12">+0.00%</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="table">
        <div className="page-slider">
          <div className="frame-5">
            <div className="frame-wrapper">
              <div className="frame-6">
                <div className="text-wrapper-13">1</div>
                <svg
                  className="img"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                >
                  <path
                    d="M4 6.5L8 10.5L12 6.5"
                    stroke="#8B8D97"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div className="text-wrapper-14">of 7 pages</div>
          </div>
          <div className="frame-7">
            <svg
              className="img"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M10.4716 11.5289C10.7319 11.7893 10.7319 12.2114 10.4716 12.4717C10.2112 12.7321 9.78911 12.7321 9.52876 12.4717L5.52876 8.47173C5.27637 8.21934 5.26754 7.81295 5.50873 7.54984L9.17539 3.54984C9.42419 3.27843 9.8459 3.26009 10.1173 3.50889C10.3887 3.75768 10.4071 4.1794 10.1583 4.45081L6.92292 7.98027L10.4716 11.5289Z"
                fill="#666666"
              />
            </svg>
            <svg
              className="img"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M5.52843 4.47108C5.26808 4.21073 5.26808 3.78862 5.52843 3.52827C5.78878 3.26792 6.21089 3.26792 6.47124 3.52827L10.4712 7.52827C10.7236 7.78066 10.7325 8.18705 10.4913 8.45016L6.82461 12.4502C6.57581 12.7216 6.1541 12.7399 5.88269 12.4911C5.61127 12.2423 5.59294 11.8206 5.84173 11.5492L9.07708 8.01973L5.52843 4.47108Z"
                fill="#666666"
              />
            </svg>
          </div>
        </div>
        <div className="items-per-page">
          <div className="text-wrapper-15">Items per page</div>
          <div className="text-wrapper-16">1-10 of 70 items</div>
          <div className="div-wrapper">
            <div className="frame-6">
              <div className="text-wrapper-13">10</div>
              <svg
                className="img"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
              >
                <path
                  d="M4 6.5L8 10.5L12 6.5"
                  stroke="#8B8D97"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>

        <div className="customers-title">
          <div class="text-wrapper-28">Customers</div>

          <div className="bar">
            <div className="search">
              <div className="search-icon-bar">
                <svg
                  className="iconly-light-search"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <circle
                    cx="9.80549"
                    cy="10.3055"
                    r="7.49047"
                    stroke="#130F26"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15.0153 15.9043L17.9519 18.8333"
                    stroke="#130F26"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <input
                className="text-wrappers"
                type="text"
                placeholder="Search"
              />
            </div>

            <div className="filters">
              <div className="filter-icons">
                <svg
                  className="icn"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                >
                  <path
                    d="M14.6667 2.5H1.33333L6.66666 8.80667V13.1667L9.33333 14.5V8.80667L14.6667 2.5Z"
                    stroke="#53545C"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div className="label">Filter</div>
            </div>
            <div className="filter-2" id="filterDiv">
              <div className="filter2-icon"></div>
              <input type="date" id="calendar" name="calendar" />
              <div className="label">Filter</div>
            </div>
            <div className="bulk-actions">
              <div className="label">Bulk action</div>
              <div className="blkicon">
                <svg
                  className="icn"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                >
                  <path
                    d="M4 6.5L8 10.5L12 6.5"
                    stroke="#53545C"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div className="dropdown-1">
                <ul>
                  <li>
                    <input type="checkbox" id="selectAll" />
                    <label for="selectAll">Select all customers</label>
                  </li>
                  <li>
                    <input type="checkbox" id="selectActive" />
                    <label for="selectActive">Select active customers</label>
                  </li>
                  <li>
                    <input type="checkbox" id="selectInactive" />
                    <label for="selectInactive">
                      Select inactive customers
                    </label>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <table>
            <tr className="company-name-no-of">
              <th className="table-cell-checkbox2">
                <div className="div-4">
                  <label className="custom-checkbox2">
                    <input className="group" type="checkbox" />
                    <span className="checkmark2"></span>
                  </label>
                </div>
              </th>
              <th className="table-cell">
                <div className="frame-8">
                  <div className="text-wrapper-17">Company Name</div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M14 5.1665H2C1.72667 5.1665 1.5 4.93984 1.5 4.6665C1.5 4.39317 1.72667 4.1665 2 4.1665H14C14.2733 4.1665 14.5 4.39317 14.5 4.6665C14.5 4.93984 14.2733 5.1665 14 5.1665Z"
                      fill="#00092E"
                    />
                    <path
                      d="M12 8.5H4C3.72667 8.5 3.5 8.27333 3.5 8C3.5 7.72667 3.72667 7.5 4 7.5H12C12.2733 7.5 12.5 7.72667 12.5 8C12.5 8.27333 12.2733 8.5 12 8.5Z"
                      fill="#00092E"
                    />
                    <path
                      d="M9.33329 11.8335H6.66663C6.39329 11.8335 6.16663 11.6068 6.16663 11.3335C6.16663 11.0602 6.39329 10.8335 6.66663 10.8335H9.33329C9.60663 10.8335 9.83329 11.0602 9.83329 11.3335C9.83329 11.6068 9.60663 11.8335 9.33329 11.8335Z"
                      fill="#00092E"
                    />
                  </svg>
                </div>
              </th>
              <th className="table-cell-contact-name">
                <div className="frame-9">
                  <div className="text-wrapper-17">No. of Employees</div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M14 5.1665H2C1.72667 5.1665 1.5 4.93984 1.5 4.6665C1.5 4.39317 1.72667 4.1665 2 4.1665H14C14.2733 4.1665 14.5 4.39317 14.5 4.6665C14.5 4.93984 14.2733 5.1665 14 5.1665Z"
                      fill="#00092E"
                    />
                    <path
                      d="M12 8.5H4C3.72667 8.5 3.5 8.27333 3.5 8C3.5 7.72667 3.72667 7.5 4 7.5H12C12.2733 7.5 12.5 7.72667 12.5 8C12.5 8.27333 12.2733 8.5 12 8.5Z"
                      fill="#00092E"
                    />
                    <path
                      d="M9.33329 11.8335H6.66663C6.39329 11.8335 6.16663 11.6068 6.16663 11.3335C6.16663 11.0602 6.39329 10.8335 6.66663 10.8335H9.33329C9.60663 10.8335 9.83329 11.0602 9.83329 11.3335C9.83329 11.6068 9.60663 11.8335 9.33329 11.8335Z"
                      fill="#00092E"
                    />
                  </svg>
                </div>
              </th>
              <th className="table-cell-contact-name">
                <div className="frame-10">
                  <div className="text-wrapper-17">Contact Name</div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M14 5.1665H2C1.72667 5.1665 1.5 4.93984 1.5 4.6665C1.5 4.39317 1.72667 4.1665 2 4.1665H14C14.2733 4.1665 14.5 4.39317 14.5 4.6665C14.5 4.93984 14.2733 5.1665 14 5.1665Z"
                      fill="#00092E"
                    />
                    <path
                      d="M12 8.5H4C3.72667 8.5 3.5 8.27333 3.5 8C3.5 7.72667 3.72667 7.5 4 7.5H12C12.2733 7.5 12.5 7.72667 12.5 8C12.5 8.27333 12.2733 8.5 12 8.5Z"
                      fill="#00092E"
                    />
                    <path
                      d="M9.33329 11.8335H6.66663C6.39329 11.8335 6.16663 11.6068 6.16663 11.3335C6.16663 11.0602 6.39329 10.8335 6.66663 10.8335H9.33329C9.60663 10.8335 9.83329 11.0602 9.83329 11.3335C9.83329 11.6068 9.60663 11.8335 9.33329 11.8335Z"
                      fill="#00092E"
                    />
                  </svg>
                </div>
              </th>
              <th className="table-cell-phone">
                <div className="frame-11">
                  <div className="text-wrapper-17">Phone nos.</div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M14 5.1665H2C1.72667 5.1665 1.5 4.93984 1.5 4.6665C1.5 4.39317 1.72667 4.1665 2 4.1665H14C14.2733 4.1665 14.5 4.39317 14.5 4.6665C14.5 4.93984 14.2733 5.1665 14 5.1665Z"
                      fill="#00092E"
                    />
                    <path
                      d="M12 8.5H4C3.72667 8.5 3.5 8.27333 3.5 8C3.5 7.72667 3.72667 7.5 4 7.5H12C12.2733 7.5 12.5 7.72667 12.5 8C12.5 8.27333 12.2733 8.5 12 8.5Z"
                      fill="#00092E"
                    />
                    <path
                      d="M9.33329 11.8335H6.66663C6.39329 11.8335 6.16663 11.6068 6.16663 11.3335C6.16663 11.0602 6.39329 10.8335 6.66663 10.8335H9.33329C9.60663 10.8335 9.83329 11.0602 9.83329 11.3335C9.83329 11.6068 9.60663 11.8335 9.33329 11.8335Z"
                      fill="#00092E"
                    />
                  </svg>
                </div>
              </th>
              <th className="table-cell">
                <div className="frame-12">
                  <div className="text-wrapper-17">Addresses</div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M14 5.1665H2C1.72667 5.1665 1.5 4.93984 1.5 4.6665C1.5 4.39317 1.72667 4.1665 2 4.1665H14C14.2733 4.1665 14.5 4.39317 14.5 4.6665C14.5 4.93984 14.2733 5.1665 14 5.1665Z"
                      fill="#00092E"
                    />
                    <path
                      d="M12 8.5H4C3.72667 8.5 3.5 8.27333 3.5 8C3.5 7.72667 3.72667 7.5 4 7.5H12C12.2733 7.5 12.5 7.72667 12.5 8C12.5 8.27333 12.2733 8.5 12 8.5Z"
                      fill="#00092E"
                    />
                    <path
                      d="M9.33329 11.8335H6.66663C6.39329 11.8335 6.16663 11.6068 6.16663 11.3335C6.16663 11.0602 6.39329 10.8335 6.66663 10.8335H9.33329C9.60663 10.8335 9.83329 11.0602 9.83329 11.3335C9.83329 11.6068 9.60663 11.8335 9.33329 11.8335Z"
                      fill="#00092E"
                    />
                  </svg>
                </div>
              </th>
              <th className="table-cell-status">
                <div className="frame-14">
                  <div className="text-wrapper-17">Status</div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M14 5.1665H2C1.72667 5.1665 1.5 4.93984 1.5 4.6665C1.5 4.39317 1.72667 4.1665 2 4.1665H14C14.2733 4.1665 14.5 4.39317 14.5 4.6665C14.5 4.93984 14.2733 5.1665 14 5.1665Z"
                      fill="#00092E"
                    />
                    <path
                      d="M12 8.5H4C3.72667 8.5 3.5 8.27333 3.5 8C3.5 7.72667 3.72667 7.5 4 7.5H12C12.2733 7.5 12.5 7.72667 12.5 8C12.5 8.27333 12.2733 8.5 12 8.5Z"
                      fill="#00092E"
                    />
                    <path
                      d="M9.33329 11.8335H6.66663C6.39329 11.8335 6.16663 11.6068 6.16663 11.3335C6.16663 11.0602 6.39329 10.8335 6.66663 10.8335H9.33329C9.60663 10.8335 9.83329 11.0602 9.83329 11.3335C9.83329 11.6068 9.60663 11.8335 9.33329 11.8335Z"
                      fill="#00092E"
                    />
                  </svg>
                </div>
              </th>
            </tr>

            {customersList.map((item) => (
              <tr key={item.company_id} className="list-tile">
                <td className="table-cell-checkbox">
                  <div className="div-4">
                    <label className="custom-checkbox2">
                      <input className="group" type="checkbox" />
                      <span className="checkmark2"></span>
                    </label>
                  </div>
                </td>
                <td className="table-cell">
                  <div className="text-wrapper-18">{item.company_name}</div>
                </td>
                <td className="table-cell-contact-name">
                  <div className="text-wrapper-19">
                    {item.company_employee_count} Workers
                  </div>
                </td>
                <td className="table-cell-contact-name">
                  <div className="text-wrapper-20">
                    {item.company_addresses[0].company_contacts[0].contact_name}
                  </div>
                </td>
                <td className="table-cell-phone">
                  <div className="group-2">
                    <div className="text-wrapper-22">
                      {
                        item.company_addresses[0].company_contacts[0]
                          .contact_phone_prefix
                      }{" "}
                      {
                        item.company_addresses[0].company_contacts[0]
                          .contact_phone_number
                      }
                    </div>
                    <svg
                      className="u-copy-alt-2"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M10.6667 13.3335H5.33333C4.8029 13.3335 4.29419 13.1228 3.91912 12.7477C3.54405 12.3726 3.33333 11.8639 3.33333 11.3335V4.66683C3.33333 4.49002 3.2631 4.32045 3.13807 4.19542C3.01305 4.0704 2.84348 4.00016 2.66667 4.00016C2.48986 4.00016 2.32029 4.0704 2.19526 4.19542C2.07024 4.32045 2 4.49002 2 4.66683V11.3335C2 12.2176 2.35119 13.0654 2.97631 13.6905C3.60143 14.3156 4.44928 14.6668 5.33333 14.6668H10.6667C10.8435 14.6668 11.013 14.5966 11.1381 14.4716C11.2631 14.3465 11.3333 14.177 11.3333 14.0002C11.3333 13.8234 11.2631 13.6538 11.1381 13.5288C11.013 13.4037 10.8435 13.3335 10.6667 13.3335ZM14 5.96016C13.9931 5.89892 13.9796 5.83858 13.96 5.78016V5.72016C13.9279 5.65162 13.8852 5.58861 13.8333 5.5335V5.5335L9.83333 1.5335C9.77822 1.48164 9.71521 1.43888 9.64667 1.40683H9.58667L9.37333 1.3335H6.66667C6.13623 1.3335 5.62753 1.54421 5.25245 1.91928C4.87738 2.29436 4.66667 2.80306 4.66667 3.3335V10.0002C4.66667 10.5306 4.87738 11.0393 5.25245 11.4144C5.62753 11.7894 6.13623 12.0002 6.66667 12.0002H12C12.5304 12.0002 13.0391 11.7894 13.4142 11.4144C13.7893 11.0393 14 10.5306 14 10.0002V6.00016C14 6.00016 14 6.00016 14 5.96016ZM10 3.60683L11.7267 5.3335H10.6667C10.4899 5.3335 10.3203 5.26326 10.1953 5.13823C10.0702 5.01321 10 4.84364 10 4.66683V3.60683ZM12.6667 10.0002C12.6667 10.177 12.5964 10.3465 12.4714 10.4716C12.3464 10.5966 12.1768 10.6668 12 10.6668H6.66667C6.48986 10.6668 6.32029 10.5966 6.19526 10.4716C6.07024 10.3465 6 10.177 6 10.0002V3.3335C6 3.15668 6.07024 2.98712 6.19526 2.86209C6.32029 2.73707 6.48986 2.66683 6.66667 2.66683H8.66667V4.66683C8.66667 5.19726 8.87738 5.70597 9.25245 6.08104C9.62753 6.45612 10.1362 6.66683 10.6667 6.66683H12.6667V10.0002Z"
                        fill="#8B8D97"
                      />
                    </svg>
                    <div className="text-wrapper-21">More</div>
                  </div>
                </td>
                <td className="table-cell">
                  <div className="group-3">
                    <div className="text-wrapper-24">
                      {item.company_addresses[0].company_street_address}
                    </div>
                    <svg
                      className="u-copy-alt-2"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M10.6667 13.3335H5.33333C4.8029 13.3335 4.29419 13.1228 3.91912 12.7477C3.54405 12.3726 3.33333 11.8639 3.33333 11.3335V4.66683C3.33333 4.49002 3.2631 4.32045 3.13807 4.19542C3.01305 4.0704 2.84348 4.00016 2.66667 4.00016C2.48986 4.00016 2.32029 4.0704 2.19526 4.19542C2.07024 4.32045 2 4.49002 2 4.66683V11.3335C2 12.2176 2.35119 13.0654 2.97631 13.6905C3.60143 14.3156 4.44928 14.6668 5.33333 14.6668H10.6667C10.8435 14.6668 11.013 14.5966 11.1381 14.4716C11.2631 14.3465 11.3333 14.177 11.3333 14.0002C11.3333 13.8234 11.2631 13.6538 11.1381 13.5288C11.013 13.4037 10.8435 13.3335 10.6667 13.3335ZM14 5.96016C13.9931 5.89892 13.9796 5.83858 13.96 5.78016V5.72016C13.9279 5.65162 13.8852 5.58861 13.8333 5.5335V5.5335L9.83333 1.5335C9.77822 1.48164 9.71521 1.43888 9.64667 1.40683H9.58667L9.37333 1.3335H6.66667C6.13623 1.3335 5.62753 1.54421 5.25245 1.91928C4.87738 2.29436 4.66667 2.80306 4.66667 3.3335V10.0002C4.66667 10.5306 4.87738 11.0393 5.25245 11.4144C5.62753 11.7894 6.13623 12.0002 6.66667 12.0002H12C12.5304 12.0002 13.0391 11.7894 13.4142 11.4144C13.7893 11.0393 14 10.5306 14 10.0002V6.00016C14 6.00016 14 6.00016 14 5.96016ZM10 3.60683L11.7267 5.3335H10.6667C10.4899 5.3335 10.3203 5.26326 10.1953 5.13823C10.0702 5.01321 10 4.84364 10 4.66683V3.60683ZM12.6667 10.0002C12.6667 10.177 12.5964 10.3465 12.4714 10.4716C12.3464 10.5966 12.1768 10.6668 12 10.6668H6.66667C6.48986 10.6668 6.32029 10.5966 6.19526 10.4716C6.07024 10.3465 6 10.177 6 10.0002V3.3335C6 3.15668 6.07024 2.98712 6.19526 2.86209C6.32029 2.73707 6.48986 2.66683 6.66667 2.66683H8.66667V4.66683C8.66667 5.19726 8.87738 5.70597 9.25245 6.08104C9.62753 6.45612 10.1362 6.66683 10.6667 6.66683H12.6667V10.0002Z"
                        fill="#8B8D97"
                      />
                    </svg>
                    <div className="text-wrapper-23">More</div>
                  </div>
                </td>
                <td className="table-cell-status">
                  <div className="status">
                    <div className="text-wrapper-25">Acive</div>
                  </div>
                </td>
              </tr>
            ))}
          </table>
        </div>
      </div>
    </div>
  );
};
export default Customer;
