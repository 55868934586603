import React, { useEffect, useState } from "react";
import "./globals.css";
import "./style.css";
import "./styleguide.css";

import { useForm, FormProvider } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  pushAddressToList,
  pushToContactList,
  clearContactList,
  clearCompanyData,
} from "../../redux/companyslice";

import ContactTile from "./components/contact-tile";
import AddressTile from "./components/address-tile";
import ApiConstant from "../../constants/api_contants";

const AddCustomerContacts = () => {
  const [addAddressCickCount, setAddAddressCickCount] = useState(0);
  const [contactList, setContactList] = useState([]);
  const navigate = useNavigate();
  const [isPostingData, setPostingData] = useState(false);

  const methods = useForm({
    defaultValues: {
      city: "",
      postalcode: "",
      companyStreetAddress: "",
      companyStateCode: "",
      contactPhonePrefix: "",
      contactPhoneNo: "",
      contactEmail: "",
      contactName: "",
    },
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    reset,
  } = methods;
  const onSubmit = (values) => {
    // alert(values.city + " " + values.password);
    //toast("Final submission not yet implemented");
    //reset();
  };
  const companyData = useSelector((state) => state.company.companyData);
  const contactData = useSelector((state) => state.company.contactData);
  const dispatch = useDispatch();

  // city postalcode companyStreetAddress companyStateCode
  // contactPhonePrefix contactPhoneNo contactEmail contactName

  // const validate = () => {
  //   Object.keys(errors).map((fieldName) => toast("Invalid " + fieldName));
  // };

  const save = () => {
    setAddAddressCickCount(addAddressCickCount + 1);
    console.log(`addAddressCickCount ${addAddressCickCount}`);
    var form1 = document.getElementById("form");
    form1.requestSubmit();
    // handleSubmit(onSubmit);
    // trigger();
    if (
      companyData.company_employee_count === undefined ||
      companyData.company_employee_count === "" ||
      companyData.company_employee_count === null ||
      companyData.company_name === undefined ||
      companyData.company_name === null
    ) {
      toast("Please try again from first, because company name is cleared");
      return;
    }

    if (Object.keys(errors).length <= 0) {
      if (addAddressCickCount > 0) {
        const contacts = {
          contact_phone_prefix: getValues("contactPhonePrefix"),
          contact_phone_number: getValues("contactPhoneNo"),
          contact_email: getValues("contactEmail"),
          contact_name: getValues("contactName"),
        };
        //dispatch(pushToContactList(contacts));
        var tempContactList = [...contactData];

        tempContactList.push(contacts);
        console.log(tempContactList);
        const address = {
          company_city: getValues("city"),
          company_zip_code: getValues("postalcode"),
          company_street_address: getValues("companyStreetAddress"),
          company_state_code: getValues("companyStateCode"),
          company_address_primary: 1,
          company_address_hq: 0,
          company_contacts: tempContactList,
        };
        console.log(address);
        // dispatch(pushAddressToList(address));
        var companyObject = {
          ...companyData,
        };
        console.log("companyObject before push");
        console.log(companyObject);

        //companyObject.company_addresses.push(address);
        companyObject.company_addresses = [
          ...companyObject.company_addresses,
          address,
        ];
        console.log("companyObject");
        console.log(companyObject);
        setPostingData(true);
        fetch(`${ApiConstant.BASE_URL}${ApiConstant.API}/company`, {
          method: "post",
          body: JSON.stringify(companyObject),
          headers: new Headers({
            "content-type": "application/json",
            Accept: "application/json",
          }),
        })
          .then((response) => {
            if (!response.ok) throw new Error(response.status);
            // else return response.json();
            setPostingData(false);
          })
          .then((data) => {
            console.log("DATA STORED");
            toast("Company added successfully");
            setPostingData(false);
            dispatch(clearCompanyData());
            dispatch(clearContactList());
            reset(
              {
                city: "",
                postalcode: "",
                companyStreetAddress: "",
                companyStateCode: "",
                contactPhonePrefix: "",
                contactPhoneNo: "",
                contactEmail: "",
                contactName: "",
              },
              { keepTouched: false }
            );
            navigate("/customer");
          })
          .catch((error) => {
            console.log("error: " + error);
            setPostingData(false);
            toast("Company not added");
          });
      }
    } else {
      Object.keys(errors).map((fieldName) => toast("Invalid " + fieldName));
      console.log(`errors ${errors}`);
      console.log(`errors one two testing`);
    }
  };

  const addContact = () => {
    // trigger([
    //   "contactPhonePrefix",
    //   "contactPhoneNo",
    //   "contactEmail",
    //   "contactName",
    // ]);
    if (
      companyData.company_employee_count === undefined ||
      companyData.company_employee_count === "" ||
      companyData.company_employee_count === null ||
      companyData.company_name === undefined ||
      companyData.company_name === null
    ) {
      toast("Please try again from first, because company name is cleared");
      return;
    }

    if (
      errors["contactPhonePrefix"] === undefined &&
      errors["contactPhoneNo"] === undefined &&
      errors["contactEmail"] === undefined &&
      errors["contactName"] === undefined
    ) {
      const contacts = {
        contact_phone_prefix: getValues("contactPhonePrefix"),
        contact_phone_number: getValues("contactPhoneNo"),
        contact_email: getValues("contactEmail"),
        contact_name: getValues("contactName"),
      };
      dispatch(pushToContactList(contacts));
      console.log(`contactData ${contactData}`);
    } else {
      Object.keys(errors).forEach((fieldName) => {
        if (
          fieldName === "contactPhonePrefix" ||
          fieldName === "contactPhoneNo" ||
          fieldName === "contactEmail" ||
          fieldName === "contactName"
        ) {
          toast("Invalid " + fieldName);
        }
      });
      console.log(errors["contactPhonePrefix"]);
      console.log(`errors ${errors}`);
    }
  };

  const addAddress = () => {
    setAddAddressCickCount(addAddressCickCount + 1);
    console.log(`addAddressCickCount ${addAddressCickCount}`);
    var form1 = document.getElementById("form");
    form1.requestSubmit();
    // handleSubmit(onSubmit);
    // trigger();
    if (
      companyData.company_employee_count === undefined ||
      companyData.company_employee_count === "" ||
      companyData.company_employee_count === null ||
      companyData.company_name === undefined ||
      companyData.company_name === null
    ) {
      toast("Please try again from first, because company name is cleared");
      return;
    }

    if (Object.keys(errors).length <= 0) {
      if (addAddressCickCount > 0) {
        const contacts = {
          contact_phone_prefix: getValues("contactPhonePrefix"),
          contact_phone_number: getValues("contactPhoneNo"),
          contact_email: getValues("contactEmail"),
          contact_name: getValues("contactName"),
        };
        dispatch(pushToContactList(contacts));
        var tempContactList = [...contactData];

        tempContactList.push(contacts);
        console.log(tempContactList);
        const address = {
          company_city: getValues("city"),
          company_zip_code: getValues("postalcode"),
          company_street_address: getValues("companyStreetAddress"),
          company_state_code: getValues("companyStateCode"),
          company_address_primary: 1,
          company_address_hq: 0,
          company_contacts: tempContactList,
        };
        console.log(address);
        dispatch(pushAddressToList(address));
        dispatch(clearContactList());
        reset(
          {
            city: "",
            postalcode: "",
            companyStreetAddress: "",
            companyStateCode: "",
            contactPhonePrefix: "",
            contactPhoneNo: "",
            contactEmail: "",
            contactName: "",
          },
          { keepTouched: false }
        );
      }
    } else {
      Object.keys(errors).map((fieldName) => toast("Invalid " + fieldName));
      console.log(`errors ${errors}`);
      console.log(`errors one two testing`);
    }
  };

  useEffect(() => {
    console.log("running useEffect");
    //faltten contact list
    var tempContactList = [];
    for (var i = 0; i < companyData.company_addresses.length; i++) {
      console.log(companyData.company_addresses[i].company_contacts);
      tempContactList = tempContactList.concat(
        companyData.company_addresses[i].company_contacts
      );
    }
    console.log(tempContactList);
    tempContactList = tempContactList.concat(contactData);
    console.log(tempContactList);
    setContactList(tempContactList);
    console.log(contactList);
    // return () => {
    //   // cleanup

    // }
  }, [companyData, contactData]);

  useEffect(() => {
    const form1 = document.getElementById("form");

    const clicks = document.getElementById("clicks");
    const click2 = document.getElementById("click2");
    // const edit = document.getElementById("edit");

    clicks.addEventListener("click", function () {
      form1.style.visibility = "visible";
    });
    // edit.addEventListener("click", function () {
    //   form1.style.visibility = "visible";
    // });
    click2.addEventListener("click", function () {
      form1.style.visibility = "hidden";
    });
    // setValue("city", "Mumbai");

    return () => {
      clicks.removeEventListener("click", function () {
        form1.style.visibility = "visible";
      });

      // edit.removeEventListener("click", function () {
      //   form1.style.visibility = "visible";
      // });
      click2.removeEventListener("click", function () {
        form1.style.visibility = "hidden";
      });
    };
  }, []);

  return (
    <FormProvider {...methods}>
      <div class="add-customers-contacts">
        <div class="frame-wrapper2">
          <ToastContainer />
          <div class="bar">
            <div class="search">
              <div class="search-icon-bar">
                <svg
                  class="iconly-light-search"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <circle
                    cx="9.80549"
                    cy="10.3055"
                    r="7.49047"
                    stroke="#130F26"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M15.0153 15.9043L17.9519 18.8333"
                    stroke="#130F26"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <input class="text-wrappers" type="text" placeholder="Search" />
            </div>

            <div class="filters">
              <div class="filter-icons">
                <svg
                  class="icn"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                >
                  <path
                    d="M14.6667 2.5H1.33333L6.66666 8.80667V13.1667L9.33333 14.5V8.80667L14.6667 2.5Z"
                    stroke="#53545C"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="label">Filter</div>
            </div>
            <div class="filter-2">
              <div class="filter2-icon"></div>
              <input type="date" id="calendar" name="calendar" />
              <div class="label">Filter</div>
            </div>
            <div class="bulk-actions">
              <div class="label">Bulk action</div>
              <div class="blkicon">
                <svg
                  class="icn"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                >
                  <path
                    d="M4 6.5L8 10.5L12 6.5"
                    stroke="#53545C"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="dropdown-1">
                <ul>
                  <li>
                    <input type="checkbox" id="selectAll" />
                    <label for="selectAll">Select all </label>
                  </li>
                  {/* <!-- <li>
              <input type="checkbox" id="selectActive" />
              <label for="selectActive">Select active customers</label>
            </li>
            <li>
              <input type="checkbox" id="selectInactive" />
              <label for="selectInactive"
                >Select inactive customers</label
              >
            </li> -->*/}
                </ul>
              </div>
            </div>
          </div>
        </div>
        {AddressContactForm({ index: 2 })}
        <div class="list-of-contacts-and">
          <div class="frame-7">
            <div class="text-wrapper-10">List of Contacts</div>

            <svg
              onClick={addAddress}
              class="frame-10"
              id="clicks"
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              height="35"
              viewBox="0 0 35 35"
              fill="none"
            >
              <rect width="35" height="35" rx="8" fill="#434343" />
              <g clip-path="url(#clip0_480_949)">
                <path
                  d="M26.4853 17.9706H9.51472"
                  stroke="#DDDDDD"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M18 9.48535V26.4559"
                  stroke="#DDDDDD"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_480_949">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(18 1) rotate(45)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="contact-list">
            {contactList.length === 0 ? (
              <div class="info-list">No address added</div>
            ) : (
              contactList.map(function (object, i) {
                return <ContactTile index={i} object={object} />;
              })
            )}
          </div>
          <div class="frame-7">
            <div class="text-wrapper-10">List of Address</div>
          </div>
          {companyData.company_addresses.length === 0 ? (
            <div class="info-list">No address added</div>
          ) : (
            <div className="contact-list">
              {companyData.company_addresses.map(function (object, i) {
                return <AddressTile index={i} object={object} />;
              })}
            </div>
          )}
        </div>
        <div class="table">
          <div class="company-list">
            <div class="group-29">
              <div class="text-wrapper-17">More</div>
              <div class="div-2">
                <label class="custom-checkbox2">
                  <input class="group" type="checkbox" />
                </label>
                <span class="checkmark2"></span>
              </div>
              <div class="text-wrapper-18">Vr Logistics</div>
            </div>
            <div class="group-30">
              <div class="text-wrapper-19">More</div>
              <div class="div-2">
                <label class="custom-checkbox2">
                  <input class="group" type="checkbox" />
                </label>
                <span class="checkmark2"></span>
              </div>
              <div class="text-wrapper-18">Tommy Exporters</div>
            </div>
            <div class="group-31">
              <div class="text-wrapper-19">More</div>
              <div class="div-2">
                <label class="custom-checkbox2">
                  <input class="group" type="checkbox" />
                </label>
                <span class="checkmark2"></span>
              </div>
              <div class="text-wrapper-18">Denver Ports</div>
            </div>
            <div class="group-32">
              <div class="text-wrapper-19">More</div>
              <div class="div-2">
                <label class="custom-checkbox2">
                  <input class="group" type="checkbox" />
                </label>
                <span class="checkmark2"></span>
              </div>
              <div class="text-wrapper-18">Tim Washington</div>
            </div>
            <div class="group-33">
              <div class="text-wrapper-19">More</div>
              <div class="div-2">
                <label class="custom-checkbox2">
                  <input class="group" type="checkbox" />
                </label>
                <span class="checkmark2"></span>
              </div>
              <div class="text-wrapper-18">Washington Ltd.</div>
            </div>
            <div class="group-34">
              <div class="text-wrapper-19">More</div>
              <div class="div-2">
                <label class="custom-checkbox2">
                  <input class="group" type="checkbox" />
                </label>
                <span class="checkmark2"></span>
              </div>
              <div class="text-wrapper-18">Dornan Pvt Ltd</div>
            </div>
            <div class="group-35">
              <div class="text-wrapper-19">More</div>
              <div class="div-2">
                <label class="custom-checkbox2">
                  <input class="group" type="checkbox" />
                </label>
                <span class="checkmark2"></span>
              </div>
              <div class="text-wrapper-18">Jackson waves</div>
            </div>
            <div class="group-36">
              <div class="text-wrapper-19">More</div>
              <div class="div-2">
                <label class="custom-checkbox2">
                  <input class="group" type="checkbox" />
                </label>
                <span class="checkmark2"></span>
              </div>
              <div class="text-wrapper-18">Roman D jr.</div>
            </div>
            <div class="group-37">
              <div class="text-wrapper-19">More</div>
              <div class="div-2">
                <label class="custom-checkbox2">
                  <input class="group" type="checkbox" />
                </label>
                <span class="checkmark2"></span>
              </div>
              <div class="text-wrapper-18">John &amp; Stone</div>
            </div>
            <div class="group-38">
              <div class="text-wrapper-19">More</div>
              <div class="div-2">
                <label class="custom-checkbox2">
                  <input class="group" type="checkbox" />
                </label>
                <span class="checkmark2"></span>
              </div>
              <div class="text-wrapper-18">Charlie DiCaprio</div>
            </div>
            <div class="group-39">
              <div class="text-wrapper-20">More</div>
              <div class="div-2">
                <label class="custom-checkbox2">
                  <input class="group" type="checkbox" />
                </label>
                <span class="checkmark2"></span>
              </div>
              <div class="text-wrapper-21">Roman Logistics</div>
            </div>
            <div class="group-40">
              <div class="text-wrapper-20">More</div>
              <div class="div-2">
                <label class="custom-checkbox2">
                  <input class="group" type="checkbox" />
                </label>
                <span class="checkmark2"></span>
              </div>
              <div class="text-wrapper-21">Tom &amp; Will</div>
            </div>
            <div class="group-41">
              <div class="text-wrapper-20">More</div>
              <div class="div-2">
                <label class="custom-checkbox2">
                  <input class="group" type="checkbox" />
                </label>
                <span class="checkmark2"></span>
              </div>
              <div class="text-wrapper-21">DiCaprio’s bar</div>
            </div>
            <div class="group-42">
              <div class="text-wrapper-20">More</div>
              <div class="div-2">
                <label class="custom-checkbox2">
                  <input class="group" type="checkbox" />
                </label>
                <span class="checkmark2"></span>
              </div>
              <div class="text-wrapper-21">Roman D jr.</div>
            </div>
            <div class="group-43">
              <div class="text-wrapper-20">More</div>
              <div class="div-2">
                <label class="custom-checkbox2">
                  <input class="group" type="checkbox" />
                </label>
                <span class="checkmark2"></span>
              </div>
              <div class="text-wrapper-21">Tony Montano</div>
            </div>
            <div class="group-44">
              <div class="text-wrapper-20">More</div>
              <div class="div-2">
                <label class="custom-checkbox2">
                  <input class="group" type="checkbox" />
                </label>
                <span class="checkmark2"></span>
              </div>
              <div class="text-wrapper-21">Charlie DiCaprio</div>
            </div>
          </div>
          <div class="company-name-heading">
            <div class="text-wrapper-22">Company Name</div>
            <svg
              class="img-2"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M14 5.1665H2C1.72667 5.1665 1.5 4.93984 1.5 4.6665C1.5 4.39317 1.72667 4.1665 2 4.1665H14C14.2733 4.1665 14.5 4.39317 14.5 4.6665C14.5 4.93984 14.2733 5.1665 14 5.1665Z"
                fill="#00092E"
              />
              <path
                d="M12 8.5H4C3.72667 8.5 3.5 8.27333 3.5 8C3.5 7.72667 3.72667 7.5 4 7.5H12C12.2733 7.5 12.5 7.72667 12.5 8C12.5 8.27333 12.2733 8.5 12 8.5Z"
                fill="#00092E"
              />
              <path
                d="M9.33317 11.8335H6.6665C6.39317 11.8335 6.1665 11.6068 6.1665 11.3335C6.1665 11.0602 6.39317 10.8335 6.6665 10.8335H9.33317C9.6065 10.8335 9.83317 11.0602 9.83317 11.3335C9.83317 11.6068 9.6065 11.8335 9.33317 11.8335Z"
                fill="#00092E"
              />
            </svg>
          </div>
        </div>

        <div class="top-nav">
          <div class="div-3">
            <div class="text-wrapper-27">Customers</div>
            <div class="profile">
              <svg
                class="iconly-bulk"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M16.4744 9.70428C15.8657 8.99341 15.5891 8.37738 15.5891 7.3308V6.97495C15.5891 5.61112 15.2752 4.73239 14.5927 3.85367C13.5409 2.489 11.7702 1.6665 10.0367 1.6665H9.96298C8.26596 1.6665 6.55072 2.45123 5.48067 3.76051C4.76095 4.65686 4.41061 5.57335 4.41061 6.97495V7.3308C4.41061 8.37738 4.15221 8.99341 3.52524 9.70428C3.06393 10.228 2.9165 10.9011 2.9165 11.6296C2.9165 12.3589 3.15586 13.0497 3.63623 13.6111C4.26319 14.2842 5.14855 14.7139 6.05296 14.7886C7.36238 14.938 8.67179 14.9943 10.0003 14.9943C11.3279 14.9943 12.6373 14.9003 13.9475 14.7886C14.8511 14.7139 15.7365 14.2842 16.3634 13.6111C16.843 13.0497 17.0832 12.3589 17.0832 11.6296C17.0832 10.9011 16.9357 10.228 16.4744 9.70428Z"
                  fill="#DDDDDD"
                />
                <path
                  opacity="0.4"
                  d="M11.674 16.0233C11.2574 15.9343 8.71888 15.9343 8.30229 16.0233C7.94616 16.1055 7.56104 16.2969 7.56104 16.7165C7.58174 17.1168 7.81613 17.4702 8.14079 17.6943L8.13996 17.6951C8.55987 18.0224 9.05266 18.2306 9.56864 18.3053C9.8436 18.343 10.1235 18.3414 10.4084 18.3053C10.9236 18.2306 11.4164 18.0224 11.8363 17.6951L11.8355 17.6943C12.1601 17.4702 12.3945 17.1168 12.4152 16.7165C12.4152 16.2969 12.0301 16.1055 11.674 16.0233Z"
                  fill="#DDDDDD"
                />
              </svg>
              <div class="profile-2"></div>
            </div>
          </div>
        </div>
      </div>
    </FormProvider>
  );

  function AddressContactForm(props) {
    console.log(`index ${props.index}}`);
    return (
      <form onSubmit={handleSubmit(onSubmit)} class="form" id="form">
        <div class="buttons">
          <button type="button" class="div-wrapper">
            <div class="text-wrapper">Cancel</div>
          </button>
          <button disabled={isPostingData} onClick={save} class="label-wrapper">
            <div class="label1">{isPostingData ? "Please Wait.." : "Save"}</div>
          </button>
        </div>
        <div class="input-group">
          <div class="frame ">
            <div class="input">
              <div class="top">
                <div class="input-content">
                  <div class="text-wrapper-2">
                    <input
                      class="text-wrapper-6"
                      type="text"
                      placeholder="City"
                      {...register("city", {
                        required: "Required",
                      })}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="top-wrapper">
            <div class="input-content-wrapper">
              <div class="input-content">
                <div class="text-wrapper-2">
                  <input
                    class="text-wrapper-6"
                    type="text"
                    placeholder="4747444"
                    {...register("postalcode", {
                      required: "Required",
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="input-wrapper">
            <div class="div-wrapper">
              <div class="top-2">
                <div class="input-content">
                  <div class="text-wrapper-2">
                    <input
                      class="text-wrapper-6"
                      type="text"
                      placeholder="LA"
                      {...register("companyStreetAddress", {
                        required: "Required",
                      })}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="select">
            <div class="frame-2">
              <div class="input-content-wrapper">
                <div class="input-content-2">
                  <div class="input-content-3">
                    <div class="text-wrapper-2">
                      <input
                        class="text-wrapper-6"
                        type="text"
                        placeholder="California"
                        {...register("companyStateCode", {
                          required: "Required",
                        })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="frame-3">
          <div class="text-wrapper-5">Add new Address</div>
        </div>
        <div class="group-2">
          <div class="group-3">
            <div class="div-2">
              <label class="custom-checkbox2">
                <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
            </div>
            <div class="checkbox-radio">
              <div class="text-wrapper-6">Primary</div>
            </div>
          </div>
          <div class="group-4">
            <div class="checkbox-radio-wrapper">
              <div class="control-wrapper">
                <div class="control">
                  <svg
                    class="fi-check"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                  >
                    <path
                      d="M10 3L4.5 8.5L2 6"
                      stroke="#DDDDDD"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <label class="custom-checkbox2">
                    <input class="group" type="checkbox" />
                  </label>
                  <span class="checkmark2"></span>
                </div>
              </div>
            </div>
            <div class="checkbox-radio-2">
              <div class="text-wrapper-7">HQ</div>
            </div>
          </div>
        </div>
        <div class="overlap-group">
          <svg
            type="svg-button"
            onClick={addContact}
            class="frame-4"
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="35"
            viewBox="0 0 36 35"
            fill="none"
          >
            <rect x="0.791016" width="35" height="35" rx="8" fill="#434343" />
            <g clip-path="url(#clip0_135_31208)">
              <path
                d="M27.2763 17.9706H10.3057"
                stroke="#DDDDDD"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18.791 9.48535V26.4559"
                stroke="#DDDDDD"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_135_31208">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  transform="translate(18.791 1) rotate(45)"
                />
              </clipPath>
            </defs>
          </svg>

          <div class="element">
            <div class="phone">
              <div class="country-code">
                <div class="input-content-4">
                  <div class="frame-5">
                    <div class="input-content-5">
                      <div class="text-wrapper-2">
                        <input
                          class="text-wrapper-6"
                          type="text"
                          placeholder="+0"
                          {...register("contactPhonePrefix", {
                            required: "Required",
                            pattern: {
                              value: /^0|\+[0-9]{1,5}$/,
                              message: "invalid phone prefix",
                            },
                          })}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="iconly-light-arrow"></div>
                </div>
              </div>
              <div class="input-2">
                <div class="input-content-2">
                  <div class="input-content-3">
                    <div class="text-wrapper-2">
                      <input
                        class="text-wrapper-6"
                        type="text"
                        placeholder="8852366700"
                        {...register("contactPhoneNo", {
                          required: "Required",
                          pattern: {
                            value: /^[0]?[6789]\d{9}$/,
                            message: "invalid email address",
                          },
                        })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="element-input">
          <div class="input-content-wrapper">
            <div class="input-content">
              <div class="text-wrapper-2">
                <input
                  class="text-wrapper-6"
                  type="text"
                  placeholder="Albert@gmail.com"
                  {...register("contactEmail", {
                    required: "Required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "invalid email address",
                    },
                  })}
                />
              </div>
              {errors.contactEmail && errors.contactEmail.message}
            </div>
          </div>
        </div>
        <div class="element-input-2">
          <div class="input-content-wrapper">
            <div class="input-content">
              <div class="text-wrapper-2">
                <input
                  class="text-wrapper-6"
                  type="text"
                  placeholder="Albert "
                  {...register("contactName", {
                    required: "Required",
                  })}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="text-wrapper-8">Add new Contact Information</div>
        <div class="edit-cross">
          <div class="div-3">
            <div class="text-wrapper-9">Edit</div>
            <svg
              class="click2"
              id="click2"
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <rect width="32" height="32" rx="8" fill="#434343" />
              <path
                d="M22 10L10 22"
                stroke="#DDDDDD"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10 10L22 22"
                stroke="#DDDDDD"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </form>
    );
  }
};

export default AddCustomerContacts;
