

 
import React from "react";
import './globals.css';
import './style.css';
import './styleguide.css';
//import {  Link } from "react-router-dom";
  
 const Permissions=()=>{
  return(
    <div class="permissions">
   
     
      <div class="top-nav">
        <div class="nav-container-wrapper">
          <div class="nav-container">
            <div class="text-wrapper">Feature permission</div>
            <div class="profile">
            <svg class="iconly-bulk"  xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M16.4746 9.70453C15.8658 8.99366 15.5892 8.37763 15.5892 7.33104V6.97519C15.5892 5.61136 15.2753 4.73264 14.5928 3.85391C13.541 2.48924 11.7703 1.66675 10.0368 1.66675H9.9631C8.26609 1.66675 6.55085 2.45147 5.48079 3.76075C4.76107 4.6571 4.41073 5.57359 4.41073 6.97519V7.33104C4.41073 8.37763 4.15233 8.99366 3.52537 9.70453C3.06405 10.2282 2.91663 10.9013 2.91663 11.6298C2.91663 12.3592 3.15598 13.0499 3.63635 13.6114C4.26331 14.2845 5.14867 14.7142 6.05309 14.7889C7.3625 14.9383 8.67191 14.9945 10.0004 14.9945C11.328 14.9945 12.6374 14.9005 13.9477 14.7889C14.8512 14.7142 15.7366 14.2845 16.3636 13.6114C16.8431 13.0499 17.0833 12.3592 17.0833 11.6298C17.0833 10.9013 16.9359 10.2282 16.4746 9.70453Z" fill="#DDDDDD"/>
              <path opacity="0.4" d="M11.6738 16.0238C11.2572 15.9348 8.71876 15.9348 8.30217 16.0238C7.94603 16.106 7.56091 16.2974 7.56091 16.717C7.58162 17.1173 7.816 17.4707 8.14067 17.6948L8.13984 17.6956C8.55974 18.0229 9.05253 18.2311 9.56851 18.3058C9.84348 18.3435 10.1234 18.3418 10.4083 18.3058C10.9235 18.2311 11.4163 18.0229 11.8362 17.6956L11.8353 17.6948C12.16 17.4707 12.3944 17.1173 12.4151 16.717C12.4151 16.2974 12.03 16.106 11.6738 16.0238Z" fill="#DDDDDD"/>
              </svg>               
           
              <div class="profile-2"></div>
            </div>
          </div>
        </div>
        <div class="breadcrumbs">
          <div class="div-2">
            <a href="../dashboard/index.html">

              <svg class="img-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M6.0959 13.8547V11.8101C6.09589 11.292 6.51719 10.8711 7.03904 10.8678H8.95515C9.47933 10.8678 9.90426 11.2897 9.90426 11.8101V13.8487C9.90425 14.2981 10.2694 14.6633 10.722 14.6666H12.0293C12.6398 14.6681 13.2259 14.4284 13.6582 14.0004C14.0905 13.5723 14.3334 12.9911 14.3334 12.3849V6.57715C14.3334 6.08751 14.1148 5.62306 13.7365 5.30892L9.2954 1.78276C8.51909 1.166 7.41033 1.18592 6.65701 1.83017L2.31142 5.30892C1.91524 5.6138 1.67845 6.07963 1.66675 6.57715V12.379C1.66675 13.6424 2.69834 14.6666 3.97086 14.6666H5.24827C5.4662 14.6681 5.67574 14.5833 5.8304 14.4309C5.98505 14.2784 6.07203 14.071 6.07203 13.8547H6.0959Z" fill="#434343"/>
                </svg>     
            </a>
                      <div class="div-2">
              <div class="text-wrapper-2">/</div>
              <div class="text-wrapper-2">Feature permission</div>
            </div>
            <div class="item">
              <div class="text-wrapper-2">/</div>
              <div class="text-wrapper-2">Page</div>
            </div>
            <div class="item">
              <div class="text-wrapper-2">/</div>
              <div class="text-wrapper-2">Page</div>
            </div>
            <div class="item">
              <div class="text-wrapper-2">/</div>
              <div class="text-wrapper-2">Page</div>
            </div>
          </div>
        </div>
      </div>
      <div class="table-left">
        <div class="checkbox-companies">
          <div class="group">
            <div class="div-3">
              <label class="custom-checkbox2">
                <input class="group" type="checkbox" />
                </label>
                <span class="checkmark2"></span>
            </div>
            <div class="text-wrapper-3">Vr Logistics</div>
         
<svg class="edit"  xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
<g clip-path="url(#clip0_460_6382)">
<path d="M6.33233 17.4163V18.0101H6.57826L6.75217 17.8362L6.33233 17.4163ZM17.4157 6.33301L17.8355 6.75285C18.0674 6.52098 18.0674 6.14503 17.8355 5.91316L17.4157 6.33301ZM1.58305 12.6663L1.16319 12.2465L0.989304 12.4204V12.6663H1.58305ZM12.6657 1.58301L13.0855 1.16316C12.9742 1.05181 12.8231 0.989258 12.6657 0.989258C12.5081 0.989258 12.3572 1.05182 12.2458 1.16318L12.6657 1.58301ZM1.58305 17.4163H0.989304V18.0101H1.58305V17.4163ZM6.75217 17.8362L17.8355 6.75285L16.9958 5.91316L5.91248 16.9965L6.75217 17.8362ZM2.00291 13.0862L13.0855 2.00284L12.2458 1.16318L1.16319 12.2465L2.00291 13.0862ZM12.2458 2.00285L16.9958 6.75285L17.8355 5.91316L13.0855 1.16316L12.2458 2.00285ZM6.33233 16.8226H1.58305V18.0101H6.33233V16.8226ZM2.1768 17.4163V12.6663H0.989304V17.4163H2.1768Z" fill="#383838"/>
<path d="M9.5 4.75L14.25 9.5" stroke="#383838" stroke-width="0.890625" stroke-linejoin="round"/>
<path d="M10.2919 17.417H17.4169" stroke="#383838" stroke-width="0.890625" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_460_6382">
<rect width="19" height="19" fill="white"/>
</clipPath>
</defs>
</svg>

          </div>
          <div class="group-2">
            <div class="div-3">


          <label class="custom-checkbox2">
              <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
              
            </div>
            <div class="text-wrapper-3">Tommy Exporters</div>
          
<svg class="edit"  xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
<g clip-path="url(#clip0_460_6382)">
<path d="M6.33233 17.4163V18.0101H6.57826L6.75217 17.8362L6.33233 17.4163ZM17.4157 6.33301L17.8355 6.75285C18.0674 6.52098 18.0674 6.14503 17.8355 5.91316L17.4157 6.33301ZM1.58305 12.6663L1.16319 12.2465L0.989304 12.4204V12.6663H1.58305ZM12.6657 1.58301L13.0855 1.16316C12.9742 1.05181 12.8231 0.989258 12.6657 0.989258C12.5081 0.989258 12.3572 1.05182 12.2458 1.16318L12.6657 1.58301ZM1.58305 17.4163H0.989304V18.0101H1.58305V17.4163ZM6.75217 17.8362L17.8355 6.75285L16.9958 5.91316L5.91248 16.9965L6.75217 17.8362ZM2.00291 13.0862L13.0855 2.00284L12.2458 1.16318L1.16319 12.2465L2.00291 13.0862ZM12.2458 2.00285L16.9958 6.75285L17.8355 5.91316L13.0855 1.16316L12.2458 2.00285ZM6.33233 16.8226H1.58305V18.0101H6.33233V16.8226ZM2.1768 17.4163V12.6663H0.989304V17.4163H2.1768Z" fill="#383838"/>
<path d="M9.5 4.75L14.25 9.5" stroke="#383838" stroke-width="0.890625" stroke-linejoin="round"/>
<path d="M10.2919 17.417H17.4169" stroke="#383838" stroke-width="0.890625" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_460_6382">
<rect width="19" height="19" fill="white"/>
</clipPath>
</defs>
</svg>

          </div>
          <div class="group-3">
            <div class="div-3">


          <label class="custom-checkbox2">
              <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
              
            </div>
            <div class="text-wrapper-3">Denver Ports</div>
           
<svg class="edit"  xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
<g clip-path="url(#clip0_460_6382)">
<path d="M6.33233 17.4163V18.0101H6.57826L6.75217 17.8362L6.33233 17.4163ZM17.4157 6.33301L17.8355 6.75285C18.0674 6.52098 18.0674 6.14503 17.8355 5.91316L17.4157 6.33301ZM1.58305 12.6663L1.16319 12.2465L0.989304 12.4204V12.6663H1.58305ZM12.6657 1.58301L13.0855 1.16316C12.9742 1.05181 12.8231 0.989258 12.6657 0.989258C12.5081 0.989258 12.3572 1.05182 12.2458 1.16318L12.6657 1.58301ZM1.58305 17.4163H0.989304V18.0101H1.58305V17.4163ZM6.75217 17.8362L17.8355 6.75285L16.9958 5.91316L5.91248 16.9965L6.75217 17.8362ZM2.00291 13.0862L13.0855 2.00284L12.2458 1.16318L1.16319 12.2465L2.00291 13.0862ZM12.2458 2.00285L16.9958 6.75285L17.8355 5.91316L13.0855 1.16316L12.2458 2.00285ZM6.33233 16.8226H1.58305V18.0101H6.33233V16.8226ZM2.1768 17.4163V12.6663H0.989304V17.4163H2.1768Z" fill="#383838"/>
<path d="M9.5 4.75L14.25 9.5" stroke="#383838" stroke-width="0.890625" stroke-linejoin="round"/>
<path d="M10.2919 17.417H17.4169" stroke="#383838" stroke-width="0.890625" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_460_6382">
<rect width="19" height="19" fill="white"/>
</clipPath>
</defs>
</svg>

          </div>
          <div class="group-4">
            <div class="div-3">


          <label class="custom-checkbox2">
              <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
              
            </div>
            <div class="text-wrapper-3">Washington Ltd.</div>
          
<svg class="edit"  xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
<g clip-path="url(#clip0_460_6382)">
<path d="M6.33233 17.4163V18.0101H6.57826L6.75217 17.8362L6.33233 17.4163ZM17.4157 6.33301L17.8355 6.75285C18.0674 6.52098 18.0674 6.14503 17.8355 5.91316L17.4157 6.33301ZM1.58305 12.6663L1.16319 12.2465L0.989304 12.4204V12.6663H1.58305ZM12.6657 1.58301L13.0855 1.16316C12.9742 1.05181 12.8231 0.989258 12.6657 0.989258C12.5081 0.989258 12.3572 1.05182 12.2458 1.16318L12.6657 1.58301ZM1.58305 17.4163H0.989304V18.0101H1.58305V17.4163ZM6.75217 17.8362L17.8355 6.75285L16.9958 5.91316L5.91248 16.9965L6.75217 17.8362ZM2.00291 13.0862L13.0855 2.00284L12.2458 1.16318L1.16319 12.2465L2.00291 13.0862ZM12.2458 2.00285L16.9958 6.75285L17.8355 5.91316L13.0855 1.16316L12.2458 2.00285ZM6.33233 16.8226H1.58305V18.0101H6.33233V16.8226ZM2.1768 17.4163V12.6663H0.989304V17.4163H2.1768Z" fill="#383838"/>
<path d="M9.5 4.75L14.25 9.5" stroke="#383838" stroke-width="0.890625" stroke-linejoin="round"/>
<path d="M10.2919 17.417H17.4169" stroke="#383838" stroke-width="0.890625" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_460_6382">
<rect width="19" height="19" fill="white"/>
</clipPath>
</defs>
</svg>

          </div>
          <div class="group-5">
            <div class="div-3">


          <label class="custom-checkbox2">
              <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
              
            </div>
            <div class="text-wrapper-3">Dornan Pvt Ltd</div>
           
<svg class="edit"  xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
<g clip-path="url(#clip0_460_6382)">
<path d="M6.33233 17.4163V18.0101H6.57826L6.75217 17.8362L6.33233 17.4163ZM17.4157 6.33301L17.8355 6.75285C18.0674 6.52098 18.0674 6.14503 17.8355 5.91316L17.4157 6.33301ZM1.58305 12.6663L1.16319 12.2465L0.989304 12.4204V12.6663H1.58305ZM12.6657 1.58301L13.0855 1.16316C12.9742 1.05181 12.8231 0.989258 12.6657 0.989258C12.5081 0.989258 12.3572 1.05182 12.2458 1.16318L12.6657 1.58301ZM1.58305 17.4163H0.989304V18.0101H1.58305V17.4163ZM6.75217 17.8362L17.8355 6.75285L16.9958 5.91316L5.91248 16.9965L6.75217 17.8362ZM2.00291 13.0862L13.0855 2.00284L12.2458 1.16318L1.16319 12.2465L2.00291 13.0862ZM12.2458 2.00285L16.9958 6.75285L17.8355 5.91316L13.0855 1.16316L12.2458 2.00285ZM6.33233 16.8226H1.58305V18.0101H6.33233V16.8226ZM2.1768 17.4163V12.6663H0.989304V17.4163H2.1768Z" fill="#383838"/>
<path d="M9.5 4.75L14.25 9.5" stroke="#383838" stroke-width="0.890625" stroke-linejoin="round"/>
<path d="M10.2919 17.417H17.4169" stroke="#383838" stroke-width="0.890625" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_460_6382">
<rect width="19" height="19" fill="white"/>
</clipPath>
</defs>
</svg>

          </div>
          <div class="group-6">
            <div class="div-3">


          <label class="custom-checkbox2">
              <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
              
            </div>
            <div class="text-wrapper-3">Jackson waves</div>
         
<svg class="edit"  xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
<g clip-path="url(#clip0_460_6382)">
<path d="M6.33233 17.4163V18.0101H6.57826L6.75217 17.8362L6.33233 17.4163ZM17.4157 6.33301L17.8355 6.75285C18.0674 6.52098 18.0674 6.14503 17.8355 5.91316L17.4157 6.33301ZM1.58305 12.6663L1.16319 12.2465L0.989304 12.4204V12.6663H1.58305ZM12.6657 1.58301L13.0855 1.16316C12.9742 1.05181 12.8231 0.989258 12.6657 0.989258C12.5081 0.989258 12.3572 1.05182 12.2458 1.16318L12.6657 1.58301ZM1.58305 17.4163H0.989304V18.0101H1.58305V17.4163ZM6.75217 17.8362L17.8355 6.75285L16.9958 5.91316L5.91248 16.9965L6.75217 17.8362ZM2.00291 13.0862L13.0855 2.00284L12.2458 1.16318L1.16319 12.2465L2.00291 13.0862ZM12.2458 2.00285L16.9958 6.75285L17.8355 5.91316L13.0855 1.16316L12.2458 2.00285ZM6.33233 16.8226H1.58305V18.0101H6.33233V16.8226ZM2.1768 17.4163V12.6663H0.989304V17.4163H2.1768Z" fill="#383838"/>
<path d="M9.5 4.75L14.25 9.5" stroke="#383838" stroke-width="0.890625" stroke-linejoin="round"/>
<path d="M10.2919 17.417H17.4169" stroke="#383838" stroke-width="0.890625" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_460_6382">
<rect width="19" height="19" fill="white"/>
</clipPath>
</defs>
</svg>

          </div>
          <div class="group-7">
            <div class="div-3">


          <label class="custom-checkbox2">
              <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
              
            </div>
            <div class="text-wrapper-3">John &amp; Stone</div>
         
<svg class="edit"  xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
<g clip-path="url(#clip0_460_6382)">
<path d="M6.33233 17.4163V18.0101H6.57826L6.75217 17.8362L6.33233 17.4163ZM17.4157 6.33301L17.8355 6.75285C18.0674 6.52098 18.0674 6.14503 17.8355 5.91316L17.4157 6.33301ZM1.58305 12.6663L1.16319 12.2465L0.989304 12.4204V12.6663H1.58305ZM12.6657 1.58301L13.0855 1.16316C12.9742 1.05181 12.8231 0.989258 12.6657 0.989258C12.5081 0.989258 12.3572 1.05182 12.2458 1.16318L12.6657 1.58301ZM1.58305 17.4163H0.989304V18.0101H1.58305V17.4163ZM6.75217 17.8362L17.8355 6.75285L16.9958 5.91316L5.91248 16.9965L6.75217 17.8362ZM2.00291 13.0862L13.0855 2.00284L12.2458 1.16318L1.16319 12.2465L2.00291 13.0862ZM12.2458 2.00285L16.9958 6.75285L17.8355 5.91316L13.0855 1.16316L12.2458 2.00285ZM6.33233 16.8226H1.58305V18.0101H6.33233V16.8226ZM2.1768 17.4163V12.6663H0.989304V17.4163H2.1768Z" fill="#383838"/>
<path d="M9.5 4.75L14.25 9.5" stroke="#383838" stroke-width="0.890625" stroke-linejoin="round"/>
<path d="M10.2919 17.417H17.4169" stroke="#383838" stroke-width="0.890625" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_460_6382">
<rect width="19" height="19" fill="white"/>
</clipPath>
</defs>
</svg>

          </div>
          <div class="group-8">
            <div class="div-3">


          <label class="custom-checkbox2">
              <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
              
            </div>
            <div class="text-wrapper-3">Roman Logistics</div>
        
<svg class="edit"  xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
<g clip-path="url(#clip0_460_6382)">
<path d="M6.33233 17.4163V18.0101H6.57826L6.75217 17.8362L6.33233 17.4163ZM17.4157 6.33301L17.8355 6.75285C18.0674 6.52098 18.0674 6.14503 17.8355 5.91316L17.4157 6.33301ZM1.58305 12.6663L1.16319 12.2465L0.989304 12.4204V12.6663H1.58305ZM12.6657 1.58301L13.0855 1.16316C12.9742 1.05181 12.8231 0.989258 12.6657 0.989258C12.5081 0.989258 12.3572 1.05182 12.2458 1.16318L12.6657 1.58301ZM1.58305 17.4163H0.989304V18.0101H1.58305V17.4163ZM6.75217 17.8362L17.8355 6.75285L16.9958 5.91316L5.91248 16.9965L6.75217 17.8362ZM2.00291 13.0862L13.0855 2.00284L12.2458 1.16318L1.16319 12.2465L2.00291 13.0862ZM12.2458 2.00285L16.9958 6.75285L17.8355 5.91316L13.0855 1.16316L12.2458 2.00285ZM6.33233 16.8226H1.58305V18.0101H6.33233V16.8226ZM2.1768 17.4163V12.6663H0.989304V17.4163H2.1768Z" fill="#383838"/>
<path d="M9.5 4.75L14.25 9.5" stroke="#383838" stroke-width="0.890625" stroke-linejoin="round"/>
<path d="M10.2919 17.417H17.4169" stroke="#383838" stroke-width="0.890625" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_460_6382">
<rect width="19" height="19" fill="white"/>
</clipPath>
</defs>
</svg>

          </div>
          <div class="group-9">
            <div class="div-3">


          <label class="custom-checkbox2">
              <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
              
            </div>
            <div class="text-wrapper-3">Tom &amp; Will</div>
         
<svg class="edit"  xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
<g clip-path="url(#clip0_460_6382)">
<path d="M6.33233 17.4163V18.0101H6.57826L6.75217 17.8362L6.33233 17.4163ZM17.4157 6.33301L17.8355 6.75285C18.0674 6.52098 18.0674 6.14503 17.8355 5.91316L17.4157 6.33301ZM1.58305 12.6663L1.16319 12.2465L0.989304 12.4204V12.6663H1.58305ZM12.6657 1.58301L13.0855 1.16316C12.9742 1.05181 12.8231 0.989258 12.6657 0.989258C12.5081 0.989258 12.3572 1.05182 12.2458 1.16318L12.6657 1.58301ZM1.58305 17.4163H0.989304V18.0101H1.58305V17.4163ZM6.75217 17.8362L17.8355 6.75285L16.9958 5.91316L5.91248 16.9965L6.75217 17.8362ZM2.00291 13.0862L13.0855 2.00284L12.2458 1.16318L1.16319 12.2465L2.00291 13.0862ZM12.2458 2.00285L16.9958 6.75285L17.8355 5.91316L13.0855 1.16316L12.2458 2.00285ZM6.33233 16.8226H1.58305V18.0101H6.33233V16.8226ZM2.1768 17.4163V12.6663H0.989304V17.4163H2.1768Z" fill="#383838"/>
<path d="M9.5 4.75L14.25 9.5" stroke="#383838" stroke-width="0.890625" stroke-linejoin="round"/>
<path d="M10.2919 17.417H17.4169" stroke="#383838" stroke-width="0.890625" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_460_6382">
<rect width="19" height="19" fill="white"/>
</clipPath>
</defs>
</svg>

          </div>
          <div class="group-10">
            <div class="div-3">


          <label class="custom-checkbox2">
              <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
              
            </div>
            <div class="text-wrapper-3">DiCaprio’s bar</div>
       
<svg class="edit"  xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
<g clip-path="url(#clip0_460_6382)">
<path d="M6.33233 17.4163V18.0101H6.57826L6.75217 17.8362L6.33233 17.4163ZM17.4157 6.33301L17.8355 6.75285C18.0674 6.52098 18.0674 6.14503 17.8355 5.91316L17.4157 6.33301ZM1.58305 12.6663L1.16319 12.2465L0.989304 12.4204V12.6663H1.58305ZM12.6657 1.58301L13.0855 1.16316C12.9742 1.05181 12.8231 0.989258 12.6657 0.989258C12.5081 0.989258 12.3572 1.05182 12.2458 1.16318L12.6657 1.58301ZM1.58305 17.4163H0.989304V18.0101H1.58305V17.4163ZM6.75217 17.8362L17.8355 6.75285L16.9958 5.91316L5.91248 16.9965L6.75217 17.8362ZM2.00291 13.0862L13.0855 2.00284L12.2458 1.16318L1.16319 12.2465L2.00291 13.0862ZM12.2458 2.00285L16.9958 6.75285L17.8355 5.91316L13.0855 1.16316L12.2458 2.00285ZM6.33233 16.8226H1.58305V18.0101H6.33233V16.8226ZM2.1768 17.4163V12.6663H0.989304V17.4163H2.1768Z" fill="#383838"/>
<path d="M9.5 4.75L14.25 9.5" stroke="#383838" stroke-width="0.890625" stroke-linejoin="round"/>
<path d="M10.2919 17.417H17.4169" stroke="#383838" stroke-width="0.890625" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_460_6382">
<rect width="19" height="19" fill="white"/>
</clipPath>
</defs>
</svg>

          </div>
        </div>
        <div class="checkbox-users">
          <div class="div-3">


          <label class="custom-checkbox2">
              <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
            
          </div>
          <div class="users">
            <div class="text-wrapper-4">Users</div>
            <svg class="img-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M14 5.16663H2C1.72667 5.16663 1.5 4.93996 1.5 4.66663C1.5 4.39329 1.72667 4.16663 2 4.16663H14C14.2733 4.16663 14.5 4.39329 14.5 4.66663C14.5 4.93996 14.2733 5.16663 14 5.16663Z" fill="#00092E"/>
              <path d="M12 8.5H4C3.72667 8.5 3.5 8.27333 3.5 8C3.5 7.72667 3.72667 7.5 4 7.5H12C12.2733 7.5 12.5 7.72667 12.5 8C12.5 8.27333 12.2733 8.5 12 8.5Z" fill="#00092E"/>
              <path d="M9.33332 11.8334H6.66666C6.39332 11.8334 6.16666 11.6067 6.16666 11.3334C6.16666 11.06 6.39332 10.8334 6.66666 10.8334H9.33332C9.60666 10.8334 9.83332 11.06 9.83332 11.3334C9.83332 11.6067 9.60666 11.8334 9.33332 11.8334Z" fill="#00092E"/>
              </svg>
          </div>
        </div>

      
              <div class="bar">
                 
                <div class="search">
                  <div class="search-icon-bar">
                    <svg
                    class="icn"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                  >
                    <circle
                      cx="9.80549"
                      cy="10.3055"
                      r="7.49047"
                      stroke="#130F26"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M15.0153 15.9043L17.9519 18.8333"
                      stroke="#130F26"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  </div>
                  <input
                  class="text-wrappers"
                  type="text"
                  placeholder="Search"
                />

                </div>

                <div class="filters">

                    <div class="filter-icons">
                      <svg
                      class="icn"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                    >
                      <path
                        d="M14.6667 2.5H1.33333L6.66666 8.80667V13.1667L9.33333 14.5V8.80667L14.6667 2.5Z"
                        stroke="#53545C"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                      
                      

                    </div>
                    <div class="label">
                                Filter
                    </div>

                </div>
        
                <div class="bulk-actions">
                   
                  <div class="label">
                    Bulk action
                  </div>
                  <div class="blkicon">
                    <svg
                    class="icn"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                  >
                    <path
                      d="M4 6.5L8 10.5L12 6.5"
                      stroke="#53545C"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                    
                  </div>
                  <div class="dropdown-1">
                    <ul>
                      <li>
                        <input type="checkbox" id="selectAll" />
                        <label for="selectAll">Select all </label>
                      </li>
                     {/* 
                     <!-- <li>
                        <input type="checkbox" id="selectActive" />
                        <label for="selectActive">Select active customers</label>
                      </li>
                      <li>
                        <input type="checkbox" id="selectInactive" />
                        <label for="selectInactive"
                          >Select inactive customers</label
                        >
                      </li> -->
                     */} 
                    </ul>
                  </div>

                </div>
             
                

              </div>




      </div>
      <div class="table">
        <div class="buttons">
          <button class="button"><div class="text-wrapper-7">Cancel</div></button>
          <button class="buttons-2"><div class="text-wrapper-8">Save</div></button>
        </div>
        <div class="reports">
          <div class="title-checkbox-and">
           {/* <!-- <p class="p">List of customers, each amount due, total days due</p> --> */} 
            <div class="checkbox-read">

              


                <div class="group-11">
                  <div class="div-3">
                    


            <label class="custom-checkbox2">
                <input class="group" type="checkbox" />
                </label>
                <span class="checkmark2"></span>
                    
                  </div>
                  <a href="../delayed_payment/index.html">
                  <div class="text-wrapper-9">
                          More
                        </div>
                      </a>
                </div>
              
            </div>
            <div class="frame"><p class="text-wrapper-10">Aging report or delayed payment</p></div>
            <svg class="line" xmlns="http://www.w3.org/2000/svg" width="819" height="1" viewBox="0 0 819 1" fill="none">
              <line y1="0.5" x2="819" y2="0.5" stroke="#B1B1B1"/>
              </svg>
          </div>
          <div class="title-checkbox-and-2">
           {/* <!-- <div class="text-wrapper-11">as above</div> -->*/} 
            <div class="checkbox-read">
              <div class="group-11">
                <div class="div-3">


          <label class="custom-checkbox2">
              <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
                  
                </div>
                <a href="../deactivated accounts/index.html">
                <div class="text-wrapper-9">
                  
                  More
                  </div>
              </a>
              </div>
            </div>
            <div class="frame"><div class="text-wrapper-10">Deactivated accounts</div></div>
            <svg class="line" xmlns="http://www.w3.org/2000/svg" width="819" height="1" viewBox="0 0 819 1" fill="none">
              <line y1="0.5" x2="819" y2="0.5" stroke="#B1B1B1"/>
              </svg>
          </div>
          <div class="title-checkbox-and-3">
           {/* <!-- <p class="text-wrapper-12">List of customers grouped per month for 3 years</p> -->*/} 
            <div class="checkbox-read">
              <div class="group-11">
                <div class="div-3">


          <label class="custom-checkbox2">
              <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
                  <a href="../new_accounts/index.html">
                    <div class="text-wrapper-9">   More</div>
                </a>
                </div>
              </div>
            </div>
            <div class="frame"><div class="text-wrapper-10">New accounts</div></div>
            <svg class="line" xmlns="http://www.w3.org/2000/svg" width="819" height="1" viewBox="0 0 819 1" fill="none">
              <line y1="0.5" x2="819" y2="0.5" stroke="#B1B1B1"/>
              </svg>
          </div>
          <div class="title-checkbox-and-4">
         {/* <!-- <p class="text-wrapper-11">Same as first 2 reports</p> -->*/} 
            <div class="checkbox-read">
              <div class="group-11">
                <div class="div-3">


          <label class="custom-checkbox2">
              <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
                  
                </div>
                <a href="../upcoming billing/index.html">

                  <div class="text-wrapper-9">   More</div>
                </a>
              </div>
            </div>
            <div class="frame"><div class="text-wrapper-10">Upcoming billing</div></div>
            <img class="line-2" src="https://c.animaapp.com/H7xbbxe0/img/line-5-13.svg" alt="" />
            <svg class="line-2" xmlns="http://www.w3.org/2000/svg" width="819" height="1" viewBox="0 0 819 1" fill="none">
              <line y1="0.5" x2="819" y2="0.5" stroke="#B1B1B1"/>
              </svg>
          </div>
          <div class="title-checkbox-and-5">
          {/*  <!-- <p class="p">Same as above, expect avg column added</p> -->*/} 
            <div class="checkbox-read">
              <div class="group-11">
                <div class="div-3">


          <label class="custom-checkbox2">
              <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
                  
                </div>
                <a href="../average_biiling_per_customer/index.html">

                  <div class="text-wrapper-9">   More</div>
                </a>
              </div>
            </div>
            <div class="frame"><div class="text-wrapper-10">Average billing per customer</div></div>
            <svg class="line-2" xmlns="http://www.w3.org/2000/svg" width="819" height="1" viewBox="0 0 819 1" fill="none">
              <line y1="0.5" x2="819" y2="0.5" stroke="#B1B1B1"/>
              </svg>
          </div>
          <div class="title-checkbox-and-6">
           {/* <!-- <div class="text-wrapper-13">Same as above</div> -->*/} 
            <div class="checkbox-read">
              <div class="group-11">
                <div class="div-3">


          <label class="custom-checkbox2">
              <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
                  
                </div>
                <a href="../New Billing/index.html">
                  
                  <div class="text-wrapper-9">   More</div>
                </a>
              </div>
            </div>
            <div class="frame"><div class="text-wrapper-10">Monthly new billing</div></div>
            <svg class="line-2" xmlns="http://www.w3.org/2000/svg" width="819" height="1" viewBox="0 0 819 1" fill="none">
              <line y1="0.5" x2="819" y2="0.5" stroke="#B1B1B1"/>
              </svg>
          </div>
          <div class="title-checkbox-and-7">
            <div class="group-wrapper">
              <div class="group-11">
                <div class="div-3">


          <label class="custom-checkbox2">
              <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
                  
                </div>
              <a href="../mrr_report/index.html">

                <div class="text-wrapper-9">   More</div>
              </a>  
              </div>
            </div>
            <div class="text-wrapper-14">Monthly recurring revenue</div>
            <div class="customer-service-wrapper">
             {/* <!-- <p class="text-wrapper-10">
                Customer, service, amount, date expected. Total per month<br />with trend chart showing next 3 months
              </p> -->*/} 
            </div>
            <svg class="line-3" xmlns="http://www.w3.org/2000/svg" width="819" height="1" viewBox="0 0 819 1" fill="none">
              <line y1="0.5" x2="819" y2="0.5" stroke="#B1B1B1"/>
              </svg>
          </div>
          <div class="text-wrapper-15">Reports</div>
        </div>
        <div class="div-4">
          <div class="audit-log-title-and">
            <svg class="line-4" xmlns="http://www.w3.org/2000/svg" width="819" height="1" viewBox="0 0 819 1" fill="none">
              <line y1="0.5" x2="819" y2="0.5" stroke="#B1B1B1"/>
              </svg>
            <div class="div-5">
              <div class="frame-2"><div class="text-wrapper-10">Audit log</div></div>
              <div class="read-create-update">
                <div class="checkbox-read-2">
                  <div class="div-3">


          <label class="custom-checkbox2">
              <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
                    
                  </div>
                  <div class="text-wrapper-3">Read</div>
                </div>
                <div class="checkbox-create">
                  <div class="div-3">


          <label class="custom-checkbox2">
              <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
                    
                  </div>
                  <div class="text-wrapper-3">Create</div>
                </div>
                <div class="checkbox-update">
                  <div class="div-3">


          <label class="custom-checkbox2">
              <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
                    
                  </div>
                  <div class="text-wrapper-3">Update</div>
                </div>
                <div class="checkbox-delete">
                  <div class="div-3">


          <label class="custom-checkbox2">
              <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
                    
                  </div>
                  <div class="text-wrapper-3">Delete</div>
                </div>
              </div>
            </div>
          </div>
          <div class="credit-note-title">
            <img class="line" src="https://c.animaapp.com/H7xbbxe0/img/line-5-13.svg" alt="" />
            <div class="div-5">
              <div class="frame-3"><div class="text-wrapper-10">Credit Note</div></div>
              <div class="read-create-update">
                <div class="checkbox-read-2">
                  <div class="controls">


                                <label class="custom-checkbox2">
              <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
                  </div>
                  <div class="text-wrapper-3">Read</div>
                </div>
                <div class="checkbox-create">
                  <div class="controls">


                                <label class="custom-checkbox2">
              <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
                  </div>
                  <div class="text-wrapper-3">Create</div>
                </div>
                <div class="checkbox-update">
                  <div class="controls">


                                <label class="custom-checkbox2">
              <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
                  </div>
                  <div class="text-wrapper-3">Update</div>
                </div>
                <div class="checkbox-delete">
                  <div class="controls">


                                <label class="custom-checkbox2">
              <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
                  </div>
                  <div class="text-wrapper-3">Delete</div>
                </div>
              </div>
            </div>
          </div>
          <div class="invoice-title-and">
            <svg class="line-5" xmlns="http://www.w3.org/2000/svg" width="819" height="1" viewBox="0 0 819 1" fill="none">
              <line y1="0.5" x2="819" y2="0.5" stroke="#B1B1B1"/>
              </svg>
            <div class="div-5">
              <div class="frame-4"><div class="text-wrapper-10">Invoice</div></div>
              <div class="read-create-update">
                <div class="checkbox-read-2">
                  <div class="div-3">


          <label class="custom-checkbox2">
              <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
                    
                  </div>
                  <div class="text-wrapper-3">Read</div>
                </div>
                <div class="checkbox-create">
                  <div class="div-3">


          <label class="custom-checkbox2">
              <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
                    
                  </div>
                  <div class="text-wrapper-3">Create</div>
                </div>
                <div class="checkbox-update">
                  <div class="div-3">


          <label class="custom-checkbox2">
              <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
                    
                  </div>
                  <div class="text-wrapper-3">Update</div>
                </div>
                <div class="checkbox-delete">
                  <div class="div-3">


          <label class="custom-checkbox2">
              <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
                    
                  </div>
                  <div class="text-wrapper-3">Delete</div>
                </div>
              </div>
            </div>
          </div>
          <div class="services-title-and">
            <svg class="line-6" xmlns="http://www.w3.org/2000/svg" width="819" height="1" viewBox="0 0 819 1" fill="none">
              <line y1="0.5" x2="819" y2="0.5" stroke="#B1B1B1"/>
              </svg>
            <div class="div-5">
              <div class="frame-5"><div class="text-wrapper-10">Service</div></div>
              <div class="read-create-update">
                <div class="checkbox-read-2">
                  <div class="div-3">


          <label class="custom-checkbox2">
              <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
                    
                  </div>
                  <div class="text-wrapper-3">Read</div>
                </div>
                <div class="checkbox-create">
                  <div class="div-3">


          <label class="custom-checkbox2">
              <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
                    
                  </div>
                  <div class="text-wrapper-3">Create</div>
                </div>
                <div class="checkbox-update">
                  <div class="div-3">


          <label class="custom-checkbox2">
              <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
                    
                  </div>
                  <div class="text-wrapper-3">Update</div>
                </div>
                <div class="checkbox-delete">
                  <div class="div-3">


          <label class="custom-checkbox2">
              <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
                    
                  </div>
                  <div class="text-wrapper-3">Delete</div>
                </div>
              </div>
            </div>
          </div>
          <div class="customers-title-and">
            <svg class="line-7" xmlns="http://www.w3.org/2000/svg" width="819" height="1" viewBox="0 0 819 1" fill="none">
              <line y1="0.5" x2="819" y2="0.5" stroke="#B1B1B1"/>
              </svg>
            <div class="customer-title-and">
              <div class="text-wrapper-16">Customer</div>
              <div class="read-create-update">
                <div class="checkbox-read-2">
                  <div class="controls">


                                <label class="custom-checkbox2">
              <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
                  </div>
                  <div class="text-wrapper-3">Read</div>
                </div>
                <div class="checkbox-create">
                  <div class="controls">


                                <label class="custom-checkbox2">
              <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
                  </div>
                  <div class="text-wrapper-3">Create</div>
                </div>
                <div class="checkbox-update">
                  <div class="controls">


                                <label class="custom-checkbox2">
              <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
                  </div>
                  <div class="text-wrapper-3">Update</div>
                </div>
                <div class="checkbox-delete">
                  <div class="controls">


                                <label class="custom-checkbox2">
              <input class="group" type="checkbox" />
              </label>
              <span class="checkmark2"></span>
                  </div>
                  <div class="text-wrapper-3">Delete</div>
                </div>
              </div>
            </div>
          </div>
          <div class="permissions-heading"><div class="text-wrapper-17">Permissions</div></div>
        </div>
        <div class="heading-title-and">
          <div class="text-wrapper-18">Vr Logistics</div>
          <svg class="edit-vecter"  xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
<g clip-path="url(#clip0_460_6382)">
<path d="M6.33233 17.4163V18.0101H6.57826L6.75217 17.8362L6.33233 17.4163ZM17.4157 6.33301L17.8355 6.75285C18.0674 6.52098 18.0674 6.14503 17.8355 5.91316L17.4157 6.33301ZM1.58305 12.6663L1.16319 12.2465L0.989304 12.4204V12.6663H1.58305ZM12.6657 1.58301L13.0855 1.16316C12.9742 1.05181 12.8231 0.989258 12.6657 0.989258C12.5081 0.989258 12.3572 1.05182 12.2458 1.16318L12.6657 1.58301ZM1.58305 17.4163H0.989304V18.0101H1.58305V17.4163ZM6.75217 17.8362L17.8355 6.75285L16.9958 5.91316L5.91248 16.9965L6.75217 17.8362ZM2.00291 13.0862L13.0855 2.00284L12.2458 1.16318L1.16319 12.2465L2.00291 13.0862ZM12.2458 2.00285L16.9958 6.75285L17.8355 5.91316L13.0855 1.16316L12.2458 2.00285ZM6.33233 16.8226H1.58305V18.0101H6.33233V16.8226ZM2.1768 17.4163V12.6663H0.989304V17.4163H2.1768Z" fill="#383838"/>
<path d="M9.5 4.75L14.25 9.5" stroke="#383838" stroke-width="0.890625" stroke-linejoin="round"/>
<path d="M10.2919 17.417H17.4169" stroke="#383838" stroke-width="0.890625" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_460_6382">
<rect width="19" height="19" fill="white"/>
</clipPath>
</defs>
</svg>
        </div>
      </div>
   
  </div>

  )
 }

 export default Permissions;
   
 

